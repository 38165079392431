/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";
import { useMenuData } from "./menu-provider/MenuDataProvider";
import { useJsApiLoader } from "@react-google-maps/api";

const GOOGLE_MAPS_API_KEY = "AIzaSyAcHeOmTGvAYq6XuK7QVuUy5t5UoV8OQ78";
export const INVALID_DISTANCE = "--.--";
const distanceService = { current: null };
const autocompleteService = { current: null };

const LIBRARIES = ["places"] as (
  | "drawing"
  | "geometry"
  | "localContext"
  | "places"
  | "visualization"
)[];

const NONCE = "372372plnmztr7u4803127lmsmw891016412091vcashjd##541381312";

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
}

export default function AddressAutoComplete({
  onAddressChange,
  address,
  variant,
}: {
  onAddressChange: (value: string) => void;
  address: string;
  variant: "standard" | "filled" | "outlined" | undefined;
}) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: LIBRARIES,
    nonce: NONCE,
  });

  return (
    <>
      {isLoaded && (
        <GoogleMapAutoComplete
          address={address}
          onAddressChange={onAddressChange}
          variant={variant}
        />
      )}
    </>
  );
}

function GoogleMapAutoComplete({
  onAddressChange,
  address,
  variant,
}: {
  onAddressChange: (value: string) => void;
  address: string;
  variant: "standard" | "filled" | "outlined" | undefined;
}) {
  const [value, setValue] = React.useState<PlaceType | null | string>(address);
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);

  const fetch = React.useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (results?: readonly PlaceType[]) => void
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            request,
            callback
          );
        },
        400
      ),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (address === "") {
      setOptions([]);
      return undefined;
    }

    fetch({ input: address }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (results) {
          newOptions = [...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, address, fetch]);

  return (
    <Autocomplete
      sx={{ flexBasis: 120, marginRight: 1, flexGrow: 1 }}
      size={"small"}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      clearOnBlur={false}
      freeSolo
      noOptionsText="No locations"
      inputValue={address}
      clearOnEscape={true}
      onChange={(event: any, newValue: PlaceType | string | null) => {
        setOptions([]);
        setValue(newValue);
      }}
      onInputChange={(_, newInputValue) => {
        onAddressChange(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          placeholder="Delivery address"
          variant={variant}
          label="Delivery address"
        />
      )}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [
            match.offset,
            match.offset + match.length,
          ])
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: "flex", width: 44 }}>
                <LocationOnIcon sx={{ color: "text.secondary" }} />
              </Grid>
              <Grid
                item
                sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
              >
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}

type Element = {
  distance: { text: string };
  status: string;
};
type DistanceResponse = {
  rows: { elements: Element[] }[];
};

export const DeliveryAddressDistance = ({ address }: { address: string }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: LIBRARIES,
    nonce: NONCE,
  });
  const [distance, setDistance] = React.useState(INVALID_DISTANCE);
  const { address: restaurantAddress } = useMenuData();

  React.useEffect(() => {
    if (!isLoaded) return;

    if (address == "") {
      setDistance(INVALID_DISTANCE);
      return;
    }

    if (!restaurantAddress) {
      setDistance(INVALID_DISTANCE);
      return;
    }

    if (!distanceService.current && (window as any).google) {
      distanceService.current = new (
        window as any
      ).google.maps.DistanceMatrixService();
    }

    if (!distanceService.current) return;

    (distanceService.current as any).getDistanceMatrix(
      {
        origins: [restaurantAddress],
        destinations: [address],
        travelMode: "DRIVING",
        unitSystem: (window as any).google.maps.UnitSystem.IMPERIAL,
      },
      (result: DistanceResponse, status: string) => {
        if (status == "OK") {
          if (result.rows[0].elements[0].status == "OK") {
            setDistance(result.rows[0].elements[0].distance.text);
            return;
          }
        }
        setDistance(INVALID_DISTANCE);
      }
    );
  }, [address, isLoaded, restaurantAddress]);

  return <Typography>{distance}</Typography>;
};

// This method copied from mui example https://mui.com/material-ui/react-autocomplete/
// We do not have Unit test for auto complete suggestions, only have test on changing address input
// and rendering correct value
// will test the autocomplete feature manually and also other features for address will remain same
