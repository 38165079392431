import ReactTimeAgo from "react-time-ago";
import en from "javascript-time-ago/locale/en.json";
import ru from "javascript-time-ago/locale/ru.json";
import TimeAgo from "javascript-time-ago";

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);

export const TimePassed = ({ dateTimeISO }: { dateTimeISO: string }) => {
  return (
    <ReactTimeAgo
      date={new Date(dateTimeISO)}
      locale="en-US"
      timeStyle="mini"
      style={{ fontSize: "13px", color: "#555" }}
    />
  );
};
