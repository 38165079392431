import { useDataPollingClient } from "../../../client/dataPollingClient";

import { usePostResolveHelpRequestClient } from "./postResolveHelpRequestClient";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Status } from "../../../client/status";
import { TimePassed } from "../../TimePassed";
import { TableName, TableNameWithTimeRow } from "../DashboardDesign";
import { HandPaperIconBlack } from "../../../design-system/Icons";
import helpRequestNotificationAudio from "../../audio/help-request-notification-sound.mp3";
import { SoundNotification } from "../../sound-notification/SoundNotification";
import { ConfirmByDoubleClickButton } from "../../ConfirmByDoubleClickButton";
import { HelpRequestNotificationSnooze } from "./HelpRequestNotificationSnooze";
import { HelpRequestRow, HelpRequestContent } from "./HelpRequestDesigns";

export const HelpRequests = ({ menuId }: { menuId: string }) => {
  const { data: helpRequests, status: helpRequestStatus } =
    useDataPollingClient<HelpRequestType[]>(
      `/api/menu/${menuId}/help-request/`
    );

  const [updatedHelpRequests, setUpdatedHelpRequests] = useState<
    HelpRequestType[]
  >([]);
  const [selectedHelpRequestId, setSelectedHelpRequestId] = useState<number>();

  const { status, resolveHelpRequest } = usePostResolveHelpRequestClient();
  const alert = useAlert();

  const handleHelpRequestResolveClick = (helpRequestId: number) => {
    setSelectedHelpRequestId(helpRequestId);
    resolveHelpRequest(helpRequestId);
  };

  useEffect(() => {
    if (helpRequests == undefined) return;
    setUpdatedHelpRequests(helpRequests);
  }, [helpRequests]);

  useEffect(() => {
    if (selectedHelpRequestId === undefined) {
      return;
    }
    if (status === Status.Success) {
      const filteredHelpRequests = updatedHelpRequests.filter(
        (updatedHelpRequest) => {
          return updatedHelpRequest.id != selectedHelpRequestId;
        }
      );

      setSelectedHelpRequestId(undefined);
      setUpdatedHelpRequests(filteredHelpRequests);
    }

    status === Status.Error && alert.error("Something went wrong!");
  }, [status, alert, updatedHelpRequests, selectedHelpRequestId]);

  return (
    <>
      <HelpRequestRow>
        {updatedHelpRequests.map((helpRequest) => (
          <HelpRequestContent key={helpRequest.id}>
            <ConfirmByDoubleClickButton
              onConfirmAction={() =>
                handleHelpRequestResolveClick(helpRequest.id)
              }
            >
              <TableNameWithTimeRow data-testid="test-id-help-request-resolve-button">
                <TableName>
                  <HandPaperIconBlack />
                  {helpRequest.tableName}
                </TableName>
                <TimePassed dateTimeISO={helpRequest.createdAt} />
              </TableNameWithTimeRow>
            </ConfirmByDoubleClickButton>
          </HelpRequestContent>
        ))}
      </HelpRequestRow>

      <SoundNotification
        data={helpRequests}
        status={helpRequestStatus}
        soundFile={helpRequestNotificationAudio}
      />
      <HelpRequestNotificationSnooze
        dataSize={!!helpRequests ? helpRequests?.length : 0}
        audioFile={helpRequestNotificationAudio}
      />
    </>
  );
};

export type HelpRequestType = {
  id: number;
  createdAt: string;
  tableName: string;
};
