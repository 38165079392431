import React, { useMemo } from "react";
import { ItemType } from "../../../client/menuClient";
import { ItemWithoutDetails } from "./ItemWithoutDetails";
import { Box } from "@mui/material";
import { PaperScroller } from "../../../design-system/Container";

export const CategoryTabItems = ({
  items,
  currency,
  onIncreaseItemQuantity,
}: {
  items: ItemType[];
  currency: string;
  onIncreaseItemQuantity: (itemId: number) => void;
}) => {
  const sortedItems = useMemo(() => {
    return items.sort((a, b) => a.name.localeCompare(b.name));
  }, [items]);

  return (
    <Box width={"70%"} data-testid="test-id-category-items">
      <PaperScroller variant={"outlined"}>
        <Box height={"100%"}>
          {sortedItems.map(
            (item) =>
              item.isEnabled && (
                <React.Fragment key={item.id}>
                  <ItemWithoutDetails
                    item={item}
                    currency={currency}
                    onIncreaseItemQuantity={onIncreaseItemQuantity}
                  />
                </React.Fragment>
              )
          )}
        </Box>
      </PaperScroller>
    </Box>
  );
};
