import { OrderType } from "../../../types/OrderType";
import { PaperScroller } from "../../../design-system/Container";
import { EditOrderMenu } from "./EditOrderMenu";
import { Box, Chip, Dialog, Grid, IconButton } from "@mui/material";
import { EditOrderDetails } from "./EditOrderDetails";
import { CustomerDetailsForm } from "./CustomerDetailsForm";
import { ConfirmEdit } from "./ConfirmEdit";
import { EditTableName } from "./EditTableName";
import { OrderReceiptPrintButton } from "./OrderReceiptPrintButton";
import { useCallback, useState } from "react";
import { CancelOrder } from "./CancelOrder";
import { AddItemToOrderSearch } from "./AddItemToOrderSearch";
import { Add, Cancel, Save } from "@mui/icons-material";
import { useOrderDataManager } from "./orderDataManager";
import { QuickOrderButton } from "./QuickOrderButton";
import { DeliveryAddressDistance } from "../../../AddressAutoComplete";
import { orderTotalWithTaxAndDiscount } from "./orderTotalUtils";
import { useMenuData } from "../../../menu-provider/MenuDataProvider";

type EditOrderPopupProps = {
  open: boolean;
  isNewOrder?: boolean;
  order: OrderType;
  onCompleteUpdate: () => void;
  onCompleteOrder: () => void;
  onCancelEdit: () => void;
  onCancelOrder: () => void;
  onCloseCompleteOrderDialog: (aOrder: OrderType) => void;
  setOrder: (aOrder: OrderType) => void;
  increaseItemQuantity: (itemId: number) => void;
  decreaseItemQuantity: (itemId: number) => void;
  updateNote: (itemId: number, note: string) => void;
};

export const EditOrderPopup = ({
  open,
  isNewOrder,
  onCompleteUpdate,
  onCompleteOrder,
  onCancelEdit,
  onCancelOrder,
  onCloseCompleteOrderDialog,
  order,
  setOrder,
  increaseItemQuantity,
  decreaseItemQuantity,
  updateNote,
}: EditOrderPopupProps) => {
  const [isConfirmDisable, setIsConfirmDisable] = useState(false);
  const { reloadOrder } = useOrderDataManager();

  const completeUpdate = () => {
    reloadOrder();
    onCompleteUpdate();
  };

  const onCancelOrderSuccess = useCallback(() => {
    setIsConfirmDisable(false);
    onCancelOrder();
  }, [onCancelOrder]);

  return (
    <Dialog open={open} fullScreen>
      <Box
        p={1}
        height="100%"
        overflow="hidden"
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        sx={{
          backgroundColor: "rgb(245, 245, 245)",
        }}
      >
        <Grid
          container
          data-testid="edit-order-popup"
          height={"calc(100% - 50px)"}
        >
          <Grid xs={5.55} item height={"calc(100% - 110px)"}>
            <CustomerDetailsForm order={order} setOrder={setOrder} />
            <AddItemToOrder addItemToOrder={increaseItemQuantity} />
          </Grid>
          <Grid xs={0.2} item></Grid>
          <Grid xs={6.25} height={"calc(100% - 52px)"} item>
            <Box display={"flex"} justifyContent="space-between">
              <EditTableName
                order={order}
                onTableNameChange={(value) =>
                  setOrder({ ...order, tableName: value })
                }
                onAddressChange={(value) =>
                  setOrder({ ...order, customerAddress: value })
                }
              />

              <IconButton
                onClick={onCancelEdit}
                data-testid="test-id-edit-order-close-button"
              >
                <Cancel fontSize="large" />
              </IconButton>
            </Box>
            <Box height={"100%"}>
              <PaperScroller variant={"outlined"} sx={{ position: "relative" }}>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <>
                    {isNewOrder || (
                      <Box>
                        <OrderReceiptPrintButton order={order} />
                      </Box>
                    )}
                  </>
                </Box>
                <EditOrderDetails
                  order={order}
                  increaseItemQuantity={increaseItemQuantity}
                  decreaseItemQuantity={decreaseItemQuantity}
                  updateNote={updateNote}
                />
              </PaperScroller>
            </Box>
          </Grid>
        </Grid>

        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box>
            <>
              {isNewOrder || (
                <CancelOrder
                  onResolveSuccess={onCancelOrderSuccess}
                  onResolveStarted={() => setIsConfirmDisable(true)}
                  orderId={order.id}
                />
              )}
            </>
          </Box>
          {order.tableName === "Delivery" && (
            <DeliveryAddressDistance address={order.customerAddress} />
          )}

          <OrderTotalDisplay order={order}></OrderTotalDisplay>

          <Box>
            <>
              <ConfirmEdit
                isConfirmDisable={isConfirmDisable}
                order={order}
                isNewOrder={!!isNewOrder}
                onUpdateSuccess={completeUpdate}
                buttonText={!!isNewOrder ? "Create" : "Update"}
                startIcon={<ConfirmEditIcon isNewOrder={isNewOrder} />}
              />

              <QuickOrderButton
                isConfirmDisable={isConfirmDisable}
                order={order}
                onClose={onCloseCompleteOrderDialog}
                isNewOrder={isNewOrder}
                onCompleteOrder={onCompleteOrder}
              />
            </>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

const AddItemToOrder = ({
  addItemToOrder,
}: {
  addItemToOrder: (id: number) => void;
}) => {
  return (
    <>
      <AddItemToOrderSearch addItemToOrder={addItemToOrder} />
      <Box height={"100%"}>
        <EditOrderMenu increaseItemQuantity={addItemToOrder} />
      </Box>
    </>
  );
};

const ConfirmEditIcon = ({ isNewOrder }: { isNewOrder?: boolean }) => {
  return !!isNewOrder ? <Add /> : <Save />;
};

function OrderTotalDisplay({ order }: { order: OrderType }) {
  const { currency } = useMenuData();
  return (
    <Chip
      label={`Total: ${currency}${orderTotalWithTaxAndDiscount(order)}`}
      sx={{ fontSize: "22px", fontWeight: "bold" }}
    />
  );
}
