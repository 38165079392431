import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { OrderType } from "../../../types/OrderType";
import {
  orderDiscount,
  orderTax,
  orderTotal,
  orderTotalWithTaxAndDiscount,
} from "./orderTotalUtils";

const ItemDetailsTableCell = styled(TableCell)({
  color: "black",
  borderBottom: "none",
  fontSize: "16px",
});

const HeaderTableCell = styled(TableCell)({
  color: "black",
  borderBottom: "none",
  fontSize: "16px",
});

const ItemDetailsTableRow = styled(TableRow)({
  color: "black",
});

export const OrderDetailsTable = ({
  order,
  currency,
}: {
  order: OrderType;
  currency: string;
}) => {
  const orderServiceCharge =
    (order.serviceCharge / 100) *
    (parseFloat(orderTotal(order)) - parseFloat(orderDiscount(order)));

  return (
    <Paper elevation={0}>
      <TableContainer data-testid="test-id-order-details-table">
        <Table size="small">
          <TableHead>
            <ItemDetailsTableRow>
              <HeaderTableCell>Items</HeaderTableCell>
              <HeaderTableCell align="right">Qt</HeaderTableCell>
              <HeaderTableCell align="right">Price</HeaderTableCell>
            </ItemDetailsTableRow>
          </TableHead>

          <TableBody>
            {order.orderDetails.map((orderDetail, index) => {
              return (
                <ItemDetailsTableRow key={index}>
                  <ItemDetailsTableCell>
                    {orderDetail.itemName}
                  </ItemDetailsTableCell>
                  <ItemDetailsTableCell align="right">
                    {orderDetail.quantity}
                  </ItemDetailsTableCell>
                  <ItemDetailsTableCell align="right">
                    {currency}
                    {orderDetail.quantity * orderDetail.itemPrice}
                  </ItemDetailsTableCell>
                </ItemDetailsTableRow>
              );
            })}

            <ItemDetailsTableRow>
              <ItemDetailsTableCell
                align="right"
                colSpan={2}
                sx={{ borderTop: "1px black dashed" }}
              >
                Subtotal
              </ItemDetailsTableCell>
              <ItemDetailsTableCell
                align="right"
                sx={{ borderTop: "1px black dashed" }}
              >
                {currency}
                {orderTotal(order)}
              </ItemDetailsTableCell>
            </ItemDetailsTableRow>

            <ItemDetailsTableRow>
              <ItemDetailsTableCell
                align="right"
                colSpan={2}
              >{`Discount(${order.discount}%)`}</ItemDetailsTableCell>
              <ItemDetailsTableCell align="right">
                {currency}
                {orderDiscount(order)}
              </ItemDetailsTableCell>
            </ItemDetailsTableRow>

            <ItemDetailsTableRow>
              <ItemDetailsTableCell align="right" colSpan={2}>
                {`Service charge(${order.serviceCharge}%)`}
              </ItemDetailsTableCell>
              <ItemDetailsTableCell align="right">
                {`${currency}${orderServiceCharge.toFixed(2)}`}
              </ItemDetailsTableCell>
            </ItemDetailsTableRow>

            <ItemDetailsTableRow>
              <ItemDetailsTableCell align="right" colSpan={2}>
                {order.isItemWiseTax ? "Tax:" : `Tax(${order.taxPercentage}%)`}
              </ItemDetailsTableCell>
              <ItemDetailsTableCell align="right">
                {currency}
                {orderTax(order)}
              </ItemDetailsTableCell>
            </ItemDetailsTableRow>

            <ItemDetailsTableRow sx={{ borderBottom: 0 }}>
              <ItemDetailsTableCell align="right" colSpan={2}>
                Grand Total
              </ItemDetailsTableCell>
              <ItemDetailsTableCell
                align="right"
                data-testid="test-id-order-total-in-order-details-table"
              >
                {currency}
                {orderTotalWithTaxAndDiscount(order)}
              </ItemDetailsTableCell>
            </ItemDetailsTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
