import { useAuthentication } from "../../../auth-provider/AuthProvider";
import { useFetch } from "../../../client/useFetch";

export const usePostResolveHelpRequestClient = () => {
  const { token } = useAuthentication();

  const { status, trigger } = useFetch(
    () => ({
      init: {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + token,
        },
      },
    }),
    [token],
    { autoTrigger: false, acceptedStatus: 200, parseJson: false }
  );

  return {
    resolveHelpRequest: (helpRequestId: number) =>
      trigger({
        input: `/api/menu/help-request/${helpRequestId}/action/resolve-request/`,
      }),
    status,
  };
};
