import { HyperLink } from "./DashboardDesign";

export const ContactUsHyperlink = ({ linkText }: { linkText: string }) => {
  return (
    <HyperLink
      onClick={() => {
        window.open("https://www.orderit.today/#contact", "new");
      }}
    >
      {linkText}
    </HyperLink>
  );
};
