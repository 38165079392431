import { useAuthentication } from "../auth-provider/AuthProvider";
import { MenuForListType } from "./MenuNameList";
import { useFetch } from "../client/useFetch";

export const useMenuListGet = () => {
  const { token } = useAuthentication();

  const { response } = useFetch<MenuForListType[]>(
    () => ({
      input: "/api/menu/",
      init: {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + token,
        },
      },
    }),
    [token],
    {
      preCondition: () => !!token,
      acceptedStatus: 200,
    }
  );

  return { menuList: response };
};
