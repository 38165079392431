import { Box, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { OrderType } from "../../../types/OrderType";
import AddressAutoComplete from "../../../AddressAutoComplete";

export const EditTableName = ({
  order,
  onTableNameChange,
  onAddressChange,
}: {
  order: OrderType;
  onTableNameChange: (value: string) => void;
  onAddressChange: (value: string) => void;
}) => {
  const selectTableOption = (
    _: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment == "Pick-up") {
      onTableNameChange("Pick-up");
    }
    if (newAlignment == "Dine-in") {
      onTableNameChange("");
    }
    if (newAlignment == "Delivery") {
      onTableNameChange("Delivery");
    }
  };

  const selectedTableOption = selectedOptionFromTableName(order.tableName);

  return (
    <Box display={"flex"} alignItems={"center"} width="100%" flexWrap="wrap">
      <>
        <ToggleButtonGroup
          data-testid="test-id-table-name-selection-toggle"
          value={selectedTableOption}
          exclusive
          onChange={selectTableOption}
          sx={{ marginRight: 1 }}
          size={"small"}
        >
          <ToggleButton value="Pick-up" size="small">
            Pick-up
          </ToggleButton>
          <ToggleButton value="Delivery" size="small">
            Delivery
          </ToggleButton>
          <ToggleButton value="Dine-in" size="small">
            Dine-in
          </ToggleButton>
        </ToggleButtonGroup>

        {selectedTableOption == "Dine-in" && (
          <TextField
            placeholder="table name"
            value={order.tableName}
            onChange={(event) => onTableNameChange(event.target.value)}
            size="small"
            sx={{ flexBasis: 120, marginRight: 1, flexGrow: 1 }}
          ></TextField>
        )}

        {selectedTableOption == "Delivery" && (
          <AddressAutoComplete
            address={order.customerAddress}
            onAddressChange={onAddressChange}
            variant={"outlined"}
          />
        )}
      </>
    </Box>
  );
};

function selectedOptionFromTableName(
  tableName: string
): string | (() => string) {
  return tableName == "Pick-up" || tableName == "Delivery"
    ? tableName
    : "Dine-in";
}
