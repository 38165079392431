import { ItemType } from "../../client/menuClient";

import React, { createContext, ReactNode, useContext, useState } from "react";
import { useMenuIdFromURL } from "../../hooks/menuIdFromURL";

const ITEM_CART_KEY = "item-cart-v3";
const MENU_ID_KEY = "menu-id";

const getItemCartForMenuIdFromLocalStorage = (menuId: string) => {
  const menuIdFromStorage = localStorage.getItem(MENU_ID_KEY);
  if (menuIdFromStorage !== menuId) {
    localStorage.setItem(MENU_ID_KEY, menuId);
    localStorage.setItem(ITEM_CART_KEY, "[]");
    return [];
  }

  const itemCartFromStorage = localStorage.getItem(ITEM_CART_KEY);
  return itemCartFromStorage == null ? [] : JSON.parse(itemCartFromStorage);
};

const Context = createContext<CartState>({
  itemIdAndQuantity: [],
  increaseItemQuantity: () => {},
  removeFromTCart: () => {},
  updateNoteInCart: () => {},
  clearCart: () => {},
});

export const CartProvider = ({ children }: { children: ReactNode }) => {
  const menuId = useMenuIdFromURL();

  const [itemIdAndQuantity, setItemIdAndQuantity] = useState<
    QuantityAndIdForCartItem[]
  >(getItemCartForMenuIdFromLocalStorage(menuId));

  const increaseItemQuantity = (itemId: number) => {
    const itemIndexInCart = findItemIndex(itemIdAndQuantity, itemId);

    const tempItemIdAndQuantity = [...itemIdAndQuantity];

    if (itemIndexInCart === -1) {
      tempItemIdAndQuantity.push({ id: itemId, quantity: 1, note: "" });
    } else {
      tempItemIdAndQuantity[itemIndexInCart].quantity += 1;
    }

    setItemIdAndQuantity(tempItemIdAndQuantity);

    localStorage.setItem(ITEM_CART_KEY, JSON.stringify(tempItemIdAndQuantity));
  };

  const clearCart = () => {
    setItemIdAndQuantity([]);
    localStorage.setItem(ITEM_CART_KEY, JSON.stringify([]));
  };

  const updateNoteInCart = (itemId: number, note: string) => {
    const itemIndexInCart = findItemIndex(itemIdAndQuantity, itemId);

    const tempItemIdAndQuantity = [...itemIdAndQuantity];

    tempItemIdAndQuantity[itemIndexInCart].note = note;

    setItemIdAndQuantity(tempItemIdAndQuantity);

    localStorage.setItem(ITEM_CART_KEY, JSON.stringify(tempItemIdAndQuantity));
  };

  const decreaseItemQuantity = (itemId: number) => {
    const itemIndexInCart = findItemIndex(itemIdAndQuantity, itemId);

    let tempItemIdAndQuantity = [...itemIdAndQuantity];

    if (tempItemIdAndQuantity[itemIndexInCart].quantity === 1) {
      tempItemIdAndQuantity = tempItemIdAndQuantity.filter(
        (value) => value.id !== itemId
      );
    } else {
      tempItemIdAndQuantity[itemIndexInCart].quantity -= 1;
    }

    setItemIdAndQuantity(tempItemIdAndQuantity);
    localStorage.setItem(ITEM_CART_KEY, JSON.stringify(tempItemIdAndQuantity));
  };

  return (
    <Context.Provider
      value={{
        itemIdAndQuantity: itemIdAndQuantity,
        increaseItemQuantity: increaseItemQuantity,
        removeFromTCart: decreaseItemQuantity,
        updateNoteInCart: updateNoteInCart,
        clearCart: clearCart,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export type CartState = {
  itemIdAndQuantity: QuantityAndIdForCartItem[];
  increaseItemQuantity: (itemId: number) => void;
  removeFromTCart: (itemId: number) => void;
  updateNoteInCart: (itemId: number, note: string) => void;
  clearCart: () => void;
};

export const useCart = () => {
  const {
    itemIdAndQuantity,
    increaseItemQuantity,
    removeFromTCart,
    updateNoteInCart,
    clearCart,
  } = useContext(Context);

  return {
    itemIdAndQuantity: itemIdAndQuantity,
    increaseItemQuantity: increaseItemQuantity,
    removeFromCart: removeFromTCart,
    updateNoteInCart: updateNoteInCart,
    clearCart: clearCart,
  };
};

export type CartItem = {
  item: ItemType;
  quantity: number;
  note: string;
};
export type QuantityAndIdForCartItem = {
  id: number;
  quantity: number;
  note: string;
};
function findItemIndex(
  itemIdAndQuantity: QuantityAndIdForCartItem[],
  itemId: number
) {
  return itemIdAndQuantity.findIndex((value) => value.id === itemId);
}
