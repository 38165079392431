import { useState } from "react";

const KDS_WIP_STATUS_KEY = "kds-wip-status";

const kdsStatusFromLocalStorage = () => {
  const kdsStatus = localStorage.getItem(KDS_WIP_STATUS_KEY);
  return kdsStatus == null ? {} : JSON.parse(kdsStatus);
};

export const useKdsWipManager = () => {
  const [kdsStatusRecord, setKdsStatusRecord] = useState(
    kdsStatusFromLocalStorage
  );

  return {
    orderDetailStatus: (orderDetailId: number) => {
      return kdsStatusRecord[orderDetailId];
    },
    shiftKdsStatus: (orderDetailId: number) => {
      const kdsStatusRecordTemp = { ...kdsStatusRecord };
      if (kdsStatusRecord[orderDetailId] === KdsStatus.Cooking) {
        kdsStatusRecordTemp[orderDetailId] = KdsStatus.Cooked;
      } else if (kdsStatusRecord[orderDetailId] === KdsStatus.Cooked) {
        delete kdsStatusRecordTemp[orderDetailId];
      } else {
        kdsStatusRecordTemp[orderDetailId] = KdsStatus.Cooking;
      }

      setKdsStatusRecord(kdsStatusRecordTemp);
      localStorage.setItem(
        KDS_WIP_STATUS_KEY,
        JSON.stringify(kdsStatusRecordTemp)
      );
    },
    clearKdsStatus: (orderDetailIds: number[]) => {
      const kdsStatusRecordTemp: Record<number, KdsStatus> = {};

      for (const orderDetailId of orderDetailIds) {
        if (kdsStatusRecord[orderDetailId] !== undefined) {
          kdsStatusRecordTemp[orderDetailId] = kdsStatusRecord[orderDetailId];
        }
      }

      setKdsStatusRecord(kdsStatusRecordTemp);
      localStorage.setItem(
        KDS_WIP_STATUS_KEY,
        JSON.stringify(kdsStatusRecordTemp)
      );
    },
  };
};

export enum KdsStatus {
  Cooking = "Cooking",
  Cooked = "Cooked",
}
