import { Button } from "../design-system/Button";
import React, { useCallback, useEffect, useState } from "react";
import { useMenuPost } from "./client";
import { useAlert } from "react-alert";

import { Status } from "../client/status";
import Swal from "sweetalert2";
import { UploadMenu } from "./UploadMenu";
import { Box, styled, TextField, Typography } from "@mui/material";

const ConfirmAlertArgument = {
  title: "The menu has been created! Now update your menu.",
  confirmButtonColor: "rgba(75, 181, 67, 0.72)",
  confirmButtonText: "Okay",
};

const CreateMenuTypography = styled(Typography)({
  fontSize: "1.3em",
  marginTop: "16px",
  marginBottom: "16px",
});

export const CreateMenu = () => {
  const [restaurantName, setRestaurantName] = useState("");
  const { status, setMenu, menuId } = useMenuPost();
  const alert = useAlert();

  const showSuccessPopUp = useCallback(() => {
    Swal.fire(ConfirmAlertArgument).then((result) => {
      if (result.isConfirmed) {
        window.open(`/dashboard/menu/${menuId}/update`, "_self");
      }
    });
  }, [menuId]);

  useEffect(() => {
    status == Status.Success && showSuccessPopUp();
    status == Status.Error && alert.error("Request Failed");
  }, [status, alert, showSuccessPopUp]);

  const submitMenu = () => {
    restaurantName || alert.error("Please enter restaurant name.");
    restaurantName &&
      setMenu({
        restaurantName,
      });
  };

  return (
    <Box
      display="flex"
      flexDirection={"column"}
      alignItems={"center"}
      width={"100%"}
    >
      <CreateMenuTypography>
        Start your journey by creating a new menu.
      </CreateMenuTypography>
      <Box display="flex" marginBottom={3}>
        <TextField
          variant="standard"
          type="text"
          placeholder="Restaurant Name*"
          onChange={(event) => setRestaurantName(event.target.value)}
          sx={{ width: "250px" }}
        />
        <Button variant="contained" onClick={submitMenu}>
          Create menu
        </Button>
      </Box>

      <CreateMenuTypography>Or</CreateMenuTypography>
      <CreateMenuTypography>
        Upload your menu, we will create the digital menu for you.
      </CreateMenuTypography>

      <Box>
        <UploadMenu />
      </Box>
    </Box>
  );
};
