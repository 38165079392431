import { Status } from "../../../client/status";
import { Button } from "@mui/material";
import { DeleteForever, Done } from "@mui/icons-material";
import { ConfirmByDoubleClickButton } from "../../ConfirmByDoubleClickButton";
import { usePostResolveOrderClient } from "./postResolveOrderClient";
import { CANCEL_ORDER_PAYMENT_TYPE } from "./cancel_order_payment_type";
import { useEffect } from "react";

export const CancelOrder = ({
  onResolveSuccess,
  onResolveStarted,
  orderId,
}: CancelOrderProps) => {
  const { resolveOrder, requestStatus: cancelStatus } =
    usePostResolveOrderClient();

  const resolveConfirmed = () => {
    onResolveStarted();
    resolveOrder(orderId, {
      paymentType: CANCEL_ORDER_PAYMENT_TYPE,
      discount: 0,
      bucket: "OVERALL",
    });
  };

  useEffect(() => {
    cancelStatus === Status.Success && onResolveSuccess();
  }, [cancelStatus, onResolveSuccess]);

  return (
    <>
      {cancelStatus !== Status.Success && (
        <ConfirmByDoubleClickButton
          onConfirmAction={resolveConfirmed}
          confirmText={
            <Button variant="contained" color="warning">
              ARE YOU SURE?
            </Button>
          }
        >
          <Button
            startIcon={<DeleteForever />}
            variant="contained"
            color="error"
            data-testid="test-id-order-cancel-button"
          >
            CANCEL ORDER
          </Button>
        </ConfirmByDoubleClickButton>
      )}
      {cancelStatus === Status.Success && (
        <Button variant="contained" color="error" startIcon={<Done />}>
          CANCELLED
        </Button>
      )}
    </>
  );
};

type CancelOrderProps = {
  onResolveSuccess: () => void;
  onResolveStarted: () => void;
  orderId: number;
};
