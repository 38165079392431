import { Box, FormControlLabel, FormGroup, Switch } from "@mui/material";
import { useEffect } from "react";
import { usePostClientWithAuth } from "../../../client/postClientWithAuth";
import { Status } from "../../../client/status";
import { useMenuIdFromURL } from "../../../hooks/menuIdFromURL";
import { useMenuData } from "../../../menu-provider/MenuDataProvider";
import { HelpRequestSnoozeSwitch } from "./HelpRequestSnoozeSwitch";

export const Settings = () => {
  const menuId = useMenuIdFromURL();
  const { isOrderingEnabled, isRequestForHelpEnabled, reloadMenuData } =
    useMenuData();
  const { status: orderUpdateStatus, postData: updateOrderEnablingStatus } =
    usePostClientWithAuth(`/api/menu/${menuId}/update/order-enabling/`);

  const {
    status: helpRequestUpdateStatus,
    postData: updateHelpRequestEnablingStatus,
  } = usePostClientWithAuth(
    `/api/menu/${menuId}/update/help-request-enabling/`
  );

  useEffect(() => {
    orderUpdateStatus === Status.Success && reloadMenuData();
  }, [orderUpdateStatus, reloadMenuData]);

  useEffect(() => {
    helpRequestUpdateStatus === Status.Success && reloadMenuData();
  }, [helpRequestUpdateStatus, reloadMenuData]);

  return (
    <Box
      padding={3}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              disabled={orderUpdateStatus === Status.Pending}
              data-testid="test-id-order-switch"
              checked={isOrderingEnabled}
              onChange={(event) =>
                updateOrderEnablingStatus({
                  isOrderingEnabled: event.target.checked,
                })
              }
            />
          }
          label="Ordering"
        />
        <FormControlLabel
          control={
            <Switch
              disabled={helpRequestUpdateStatus === Status.Pending}
              data-testid="test-id-help-request-switch"
              checked={isRequestForHelpEnabled}
              onChange={(event) => {
                updateHelpRequestEnablingStatus({
                  isRequestForHelpEnabled: event.target.checked,
                });
              }}
            />
          }
          label="Ask for host"
        />

        <HelpRequestSnoozeSwitch />
      </FormGroup>
    </Box>
  );
};
