import {
  createContext,
  useEffect,
  useState,
  ReactNode,
  useContext,
} from "react";
import { useGetClientWithAuth } from "../../../client/getClientWithAuth";
import { useMenuIdFromURL } from "../../../hooks/menuIdFromURL";
import { usePostStationCreateClient } from "./postStationCreateClient";
import { Status } from "../../../client/status";
import { usePostClientWithAuth } from "../../../client/postClientWithAuth";
import { useDeleteStationClient } from "./deleteStationClient";

const Context = createContext<KdsStationState>({
  stations: [],
  addStation: () => {},
  addStatus: Status.NotStarted,
  selectedStationId: null,
  setSelectedStationId: () => {},
  updateStation: () => {},
  updateStatus: Status.NotStarted,
  deleteStation: () => {},
  deleteStatus: Status.NotStarted,
});

const SELECTED_STATION_ID_KEY = "selected-kds-station-id";

const getStationIdFromStorage = () => {
  const stationId = localStorage.getItem(SELECTED_STATION_ID_KEY);
  return stationId === null ? null : parseInt(stationId);
};

export const KdsStationProvider = ({ children }: { children: ReactNode }) => {
  const [stations, setStations] = useState<StationsType>([]);
  const menuId = useMenuIdFromURL();
  const { data: kdsResponse, reloadData } = useGetClientWithAuth<StationsType>(
    `/api/menu/${menuId}/kds/stations/`
  );
  const { createStation, status: createStatus } = usePostStationCreateClient();
  const [selectedStationId, setSelectedStationId] = useState<number | null>(
    getStationIdFromStorage
  );

  const { postData: stationUpdate, status: stationUpdateStatus } =
    usePostClientWithAuth(`/api/station/${selectedStationId}/update/`);

  const { deleteStation, status: deleteStatus } = useDeleteStationClient();

  const updateStation = (categoryData: StationCategoriesType) => {
    stationUpdate(categoryData);
  };

  const selectStationId = (stationId: number | null) => {
    if (stationId === null) {
      localStorage.removeItem(SELECTED_STATION_ID_KEY);
    } else localStorage.setItem(SELECTED_STATION_ID_KEY, stationId.toString());

    setSelectedStationId(stationId);
  };

  useEffect(() => {
    if (kdsResponse === undefined) return;
    setStations(kdsResponse);
  }, [kdsResponse]);

  useEffect(() => {
    createStatus === Status.Success && reloadData();
  }, [reloadData, createStatus]);

  useEffect(() => {
    stationUpdateStatus === Status.Success && reloadData();
  }, [reloadData, stationUpdateStatus]);

  useEffect(() => {
    deleteStatus === Status.Success && reloadData();
  }, [deleteStatus, reloadData]);

  return (
    <Context.Provider
      value={{
        stations: stations,
        addStation: createStation,
        addStatus: createStatus,
        selectedStationId: selectedStationId,
        setSelectedStationId: selectStationId,
        updateStation: updateStation,
        updateStatus: stationUpdateStatus,
        deleteStation: deleteStation,
        deleteStatus: deleteStatus,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useKdsStations = () => {
  const {
    stations,
    addStation,
    addStatus,
    selectedStationId,
    setSelectedStationId,
    updateStation,
    updateStatus,
    deleteStation,
    deleteStatus,
  } = useContext(Context);

  return {
    stations: stations,
    addStation: addStation,
    addStatus: addStatus,
    selectedStationId: selectedStationId,
    setSelectedStationId: setSelectedStationId,
    updateStation: updateStation,
    updateStatus: updateStatus,
    deleteStation: deleteStation,
    deleteStatus: deleteStatus,
  };
};

export type StationCategoriesType = {
  id: number;
  name: string;
  isInThisStation: boolean;
}[];

type StationsType = {
  id: number;
  name: string;
  categories: StationCategoriesType;
}[];

export type KdsStationState = {
  stations: StationsType;
  addStation: (name: string) => void;
  addStatus: Status;
  selectedStationId: number | null;
  setSelectedStationId: (id: number | null) => void;
  updateStation: (categoryData: StationCategoriesType) => void;
  updateStatus: Status;
  deleteStation: (stationId: number) => void;
  deleteStatus: Status;
};
