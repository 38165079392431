import { TransparentButton } from "../../../design-system/Button";
import { SimpleInputField } from "../../../design-system/InputField";
import { Status } from "../../../client/status";
import { useKdsStations } from "./kdsStationsProvider";
import React, { useEffect, useState } from "react";

export const AddStation = () => {
  const { addStation, addStatus } = useKdsStations();

  const [stationName, setStationName] = useState<string>("");

  const createStation = () => {
    addStation(stationName);
  };

  useEffect(() => {
    addStatus == Status.Success && setStationName("");
  }, [addStatus]);

  const isEmptyOrSpacesOnly = (categoryName: string) => {
    return !categoryName.replace(/\s/g, "").length;
  };

  return (
    <>
      <SimpleInputField
        placeholder="Station name"
        onChange={(event) => {
          setStationName(event.target.value);
        }}
        value={stationName}
      />
      <TransparentButton
        onClick={createStation}
        disabled={isEmptyOrSpacesOnly(stationName)}
      >
        Done
      </TransparentButton>
    </>
  );
};
