import React, { ReactNode, useState } from "react";
import styled from "styled-components";
import { DoubleClickComponent } from "./DoubleClickComponent";

const CompleteTextWrap = styled.div`
  width: 100%;

  text-align: center;
  font-size: 22px;
  background-color: rgba(242, 228, 233, 1);
  line-height: 50px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const ConfirmByDoubleClickButton = ({
  confirmText,
  onConfirmAction,
  children,
}: {
  confirmText?: ReactNode;
  onConfirmAction: () => void;
  children: ReactNode;
}) => {
  const [willShowComplete, setWillShowComplete] = useState<boolean>(false);

  return (
    <DoubleClickComponent
      onSingleClick={() => setWillShowComplete(true)}
      onDoubleClick={onConfirmAction}
      onDoubleClickTimeOut={() => setWillShowComplete(false)}
    >
      {willShowComplete ? (
        confirmText ? (
          confirmText
        ) : (
          <CompleteText />
        )
      ) : (
        children
      )}
    </DoubleClickComponent>
  );
};

const CompleteText = () => <CompleteTextWrap> Complete?</CompleteTextWrap>;
