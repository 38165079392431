import React, { useEffect, useState } from "react";

import { DashboardCategory } from "./DashboardCategory";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DroppableProvided,
  DraggableProvided,
  DropResult,
  DraggableStateSnapshot,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";
import { CategoryType } from "../../../../client/menuClient";
import { usePostClientWithAuth } from "../../../../client/postClientWithAuth";
import { useAlert } from "react-alert";
import { Status } from "../../../../client/status";
import { Box, Divider } from "@mui/material";
import { DragIndicator } from "@mui/icons-material";
import { reorder } from "./reorder";
import { getListItemStyle, getListStyle } from "./getListStyle";

export const DashboardCategories = ({
  onSelectedCategoryChange,
  categories,
  selectedCategory,
}: {
  onSelectedCategoryChange: (aCategory: CategoryType) => void;
  categories: CategoryType[];
  selectedCategory: CategoryType | undefined;
}) => {
  const [updatedCategories, setUpdatedCategories] = useState<CategoryType[]>(
    []
  );
  const { postData: updateCategoryOrdering, status } = usePostClientWithAuth(
    "/api/categories/update-position/"
  );

  const alert = useAlert();

  useEffect(() => {
    setUpdatedCategories(categories);
  }, [categories]);

  useEffect(() => {
    status == Status.Error && alert.error("Request failed");
  }, [alert, categories, status]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    if (result.type === "CATEGORIES") {
      const reorderedCategoryList = reorder(
        [...updatedCategories],
        result.source.index,
        result.destination.index
      ) as CategoryType[];

      setUpdatedCategories(reorderedCategoryList);

      const categoriesWithId = reorderedCategoryList.map((category) => ({
        id: category.id,
      }));
      updateCategoryOrdering({ categories: categoriesWithId });
    }
  };

  return (
    <Box display={"flex"} width={"100%"}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="category-droppable" type="CATEGORIES">
          {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              <Divider light={true} />
              {updatedCategories.map((category, index) => (
                <Draggable
                  key={category.id}
                  draggableId={`category-${category.id}`}
                  index={index}
                >
                  {(
                    providedDraggable: DraggableProvided,
                    snapshotDraggable: DraggableStateSnapshot
                  ) => (
                    <div
                      ref={providedDraggable.innerRef}
                      {...providedDraggable.draggableProps}
                      style={getListItemStyle(
                        snapshotDraggable.isDragging,
                        providedDraggable.draggableProps.style
                      )}
                    >
                      <Box
                        display={"flex"}
                        boxSizing={"border-box"}
                        alignItems={"center"}
                        width={"100%"}
                        bgcolor={
                          selectedCategory?.id == category.id
                            ? "#7bb8e96b"
                            : "transparent"
                        }
                      >
                        <Box
                          data-testid="test-id-category-drag-btn"
                          {...providedDraggable.dragHandleProps}
                          width={"30px"}
                          paddingTop={"2px"}
                        >
                          <DragIndicator />
                        </Box>

                        <Box
                          data-testid="category"
                          display={"flex"}
                          width="calc(100% - 50px)"
                        >
                          <DashboardCategory
                            category={category}
                            onCategoryNameClick={() =>
                              onSelectedCategoryChange(category)
                            }
                          />
                        </Box>
                      </Box>
                      <Divider light={true} />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
};
