import { Button } from "@mui/material";
import { useCallback, useState } from "react";

import { EditOrderPopup } from "./EditOrderPopup";
import { PICK_UP_ORDER_TABLE_NAME } from "../../../ORDER_TABLE_NAME_TYPES";
import { useOrderEditManager } from "./orderEditManager";
import { OrderType } from "../../../types/OrderType";
import { useItemInventory } from "./ItemInventoryProvider";

const aNewOrder = {
  id: 0,
  orderNumber: -1,
  createdAt: "",
  tableName: PICK_UP_ORDER_TABLE_NAME,
  orderDetails: [],
  customerName: "",
  customerPhone: "",
  paymentType: "",
  isSentToKitchen: false,
  taxPercentage: 0,
  discount: 0,
  serviceCharge: 0,
  customerAddress: "",
} as unknown as OrderType;

export const NewOrder = () => {
  const [open, setOpen] = useState(false);
  const [isNewOrder, setIsNewOrder] = useState(true);
  const {
    order,
    resetOrder,
    setOrder,
    increaseItemQuantity,
    decreaseItemQuantity,
    updateNote,
  } = useOrderEditManager(aNewOrder);

  const { resetItemsQuantity } = useItemInventory();

  const completeOrder = useCallback(() => {
    resetOrder();
    setIsNewOrder(true);
  }, [resetOrder]);

  const cancelEdit = useCallback(() => {
    resetOrder();
    setOpen(false);
    setIsNewOrder(true);
    resetItemsQuantity();
  }, [resetItemsQuantity, resetOrder]);

  const cancelOrder = useCallback(() => {
    resetOrder();
    setIsNewOrder(true);
  }, [resetOrder]);

  const closeCompleteDialog = useCallback(
    (latestOrder: OrderType): void => {
      setOrder(latestOrder);
      setIsNewOrder(false);
    },
    [setOrder]
  );

  const completeCreateOrder = useCallback(() => {
    resetOrder();
    setOpen(false);
    setIsNewOrder(true);
  }, [resetOrder]);

  return (
    <>
      <EditOrderPopup
        open={open}
        isNewOrder={isNewOrder}
        order={order}
        onCompleteUpdate={completeCreateOrder}
        onCompleteOrder={completeOrder}
        onCancelEdit={cancelEdit}
        onCancelOrder={cancelOrder}
        onCloseCompleteOrderDialog={closeCompleteDialog}
        setOrder={setOrder}
        increaseItemQuantity={increaseItemQuantity}
        decreaseItemQuantity={decreaseItemQuantity}
        updateNote={updateNote}
      />
      <Button
        size="small"
        variant="contained"
        onClick={() => setOpen(true)}
        sx={{ marginLeft: 1 }}
      >
        New Order
      </Button>
    </>
  );
};
