import { useEffect, useRef, useState } from "react";
import { useAuthentication } from "../../../auth-provider/AuthProvider";
import { Status } from "../../../client/status";
import { useMenuIdFromURL } from "../../../hooks/menuIdFromURL";

export const usePostStationCreateClient = () => {
  const [status, setStatus] = useState<Status>(Status.NotStarted);
  const { token } = useAuthentication();
  const mounted = useRef(true);
  const menuId = useMenuIdFromURL();

  useEffect(() => {
    return function cleanup() {
      mounted.current = false;
    };
  }, []);

  return {
    createStation: async (stationName: string) => {
      setStatus(Status.Pending);
      const response = await fetch(`/api/menu/${menuId}/kds/station/create/`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + token,
        },
        body: JSON.stringify({ name: stationName }),
      });

      if (response.status == 201) {
        mounted.current && setStatus(Status.Success);
        return;
      }
      mounted.current && setStatus(Status.Error);
    },
    status: status,
  };
};
