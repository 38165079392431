import React, { useEffect, useState } from "react";
import { MenuForListType, MenuNameList } from "./MenuNameList";
import { useMenuListGet } from "./menuListClient";
import styled from "styled-components";
import { Button } from "../design-system/Button";
import { VerticalScrolls } from "../design-system/Container";
import { Link } from "react-router-dom";
import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import { AddBox } from "@mui/icons-material";

const HeadingText = styled.div`
  background-color: transparent;
  font-size: 2em;
  display: flex;
  justify-content: center;
  margin: 24px auto;
`;

export const UserMenuList = () => {
  const { menuList } = useMenuListGet();
  const [menus, setMenus] = useState<MenuForListType[] | undefined>();

  const userHasMenu = menus?.length == 0;
  useEffect(() => {
    setMenus(menuList);
  }, [menuList]);

  return (
    <VerticalScrolls height="calc(100vh - (50px));">
      <Container maxWidth={"sm"}>
        <Typography
          variant={"h5"}
          align={"center"}
          marginTop={"20px"}
          marginBottom={"20px"}
        >
          Menu List
        </Typography>
        {userHasMenu && <HeadingText>You don't have any menus!</HeadingText>}
        <MenuNameList menus={menus} />

        <Box mt={2} />
        <Divider />
        <Box mb={2} />

        <Grid container justifyContent={"center"}>
          <Button
            component={Link}
            to="/dashboard/create-menu"
            size={"large"}
            startIcon={<AddBox />}
          >
            Create New Menu
          </Button>
        </Grid>
      </Container>
    </VerticalScrolls>
  );
};
