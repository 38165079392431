import { AskForHost } from "./AskForHost";
import { CartPopUp } from "./CartPopUp";
import { useMenuData } from "../menu-provider/MenuDataProvider";
import { useTableNameFromURL } from "./tableNameFromURL";
import { Box, Button } from "@mui/material";
import styled from "styled-components";
import {
  DELIVERY_ORDER_TABLE_NAME,
  PICK_UP_ORDER_TABLE_NAME,
} from "../ORDER_TABLE_NAME_TYPES";
import { LastOrderButton } from "./LastOrderButton";

const FooterBar = styled(Box)({
  height: "70px",
  paddingBottom: "4px",
  paddingTop: "8px",
  display: "flex",
  justifyContent: "space-evenly",
  width: "100%",
});

export const MenuFooter = () => {
  const tableName = useTableNameFromURL();
  const {
    isRequestForHelpEnabled,
    isOrderingEnabled,
    themeColor,
    externalLink,
  } = useMenuData();

  const willFooterRender =
    tableName && (isRequestForHelpEnabled || isOrderingEnabled);

  const renderAskForHostButton =
    isRequestForHelpEnabled &&
    tableName !== PICK_UP_ORDER_TABLE_NAME &&
    tableName !== DELIVERY_ORDER_TABLE_NAME;

  return (
    <>
      <Box
        position="fixed"
        bottom={0}
        left={0}
        zIndex={100}
        width={"100%"}
        bgcolor={"white"}
        display={"flex"}
        flexDirection={"column"}
      >
        <Box
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {!!externalLink && (
            <Button onClick={() => window.open(externalLink?.link, "_self")}>
              {externalLink?.label}
            </Button>
          )}
          <Button
            sx={{
              cursor: "pointer",
              textTransform: "none",
              marginLeft: "auto",
              textAlign: "right",
              color: "black",
              padding: "0px 8px 0px 8px",
            }}
            onClick={() => window.open("https://www.orderit.today", "_self")}
            data-testid="test-id-orderit-hyperlink"
          >
            <small>Powered by</small>&nbsp;<u>Orderit</u>
          </Button>
        </Box>

        {willFooterRender && (
          <>
            <FooterBar
              bgcolor={themeColor}
              role="menu-footer"
              aria-label="menu-footer"
            >
              {renderAskForHostButton && <AskForHost />}
              {isOrderingEnabled && <CartPopUp />}
              {isOrderingEnabled && <LastOrderButton />}
            </FooterBar>
          </>
        )}
      </Box>
    </>
  );
};
