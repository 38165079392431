import { MenuFooter } from "./MenuFooter";
import { useMenuData } from "../menu-provider/MenuDataProvider";
import styled from "styled-components";
import { useTableNameFromURL } from "./tableNameFromURL";
import { useCart } from "./cart-provider/CartProvider";
import React from "react";
import { Category } from "./Category";
import { ScrollSpyTabs } from "../scrollspy/ScrollSpyTabs";
import { Helmet } from "react-helmet-async";
import { useMenuIdFromURL } from "../hooks/menuIdFromURL";
import { Avatar, Box, Divider, Skeleton, Typography } from "@mui/material";

const CategoryWrapper = styled.div`
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
`;

const RestaurantName = styled(Box)`
  max-width: 600px;
  margin-left: 16px;

  margin-bottom: 16px;
  margin-top: 40px;
`;

const BgImg = styled.img`
  width: 100%;
  height: 100%;
`;

export const CustomerFacingMenuSkeleton = () => {
  return (
    <CategoryWrapper>
      <Box margin={1} data-testid="test-id-menu-loading-skeleton">
        <Skeleton variant="rectangular" width={"100%"} height={"60px"} />

        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={"120px"}
          sx={{ marginTop: "16px" }}
        />

        <Divider sx={{ marginTop: "16px", marginBottom: "16px" }} />

        <Skeleton variant="rectangular" width={"100%"} height={"120px"} />
        <Divider sx={{ marginTop: "16px", marginBottom: "16px" }} />

        <Skeleton variant="rectangular" width={"100%"} height={"120px"} />
        <Divider sx={{ marginTop: "16px", marginBottom: "16px" }} />

        <Skeleton variant="rectangular" width={"100%"} height={"120px"} />
        <Divider sx={{ marginTop: "16px", marginBottom: "16px" }} />

        <Skeleton variant="rectangular" width={"100%"} height={"120px"} />
        <Divider sx={{ marginTop: "16px", marginBottom: "16px" }} />
      </Box>
    </CategoryWrapper>
  );
};

export const CustomerFacingMenu = () => {
  const menu = useMenuData();
  const { increaseItemQuantity, itemIdAndQuantity } = useCart();
  const tableName = useTableNameFromURL();
  const menuId = useMenuIdFromURL();

  return (
    <div style={{ paddingBottom: "120px" }}>
      <Helmet>
        <title>{menu.restaurantName}</title>
        <meta property="og:title" content={menu.restaurantName} />

        <meta
          property="og:url"
          content={`https://m.orderit.today/menu/${menuId}?t=${tableName}`}
        />

        <meta property="og:description" content="View our menu" />
      </Helmet>
      <MenuCover
        logo={menu.logo}
        themeColor={menu.themeColor}
        restaurantName={menu.restaurantName}
        coverImage={menu.coverImage}
      />
      <RestaurantName>
        <Typography variant="h4"> {menu.restaurantName}</Typography>

        <Typography color={"#666"}>{menu.phone}</Typography>
      </RestaurantName>

      {menu.restaurantName === "" ? (
        <CustomerFacingMenuSkeleton />
      ) : (
        <CategoryWrapper>
          <ScrollSpyTabs
            tabsInScroll={menu.categories
              .filter(
                (category) =>
                  category.items.filter((item) => item.isEnabled).length !== 0
              )
              .map((category, index) => ({
                text: category.name,
                component: (
                  <React.Fragment key={`${category.name}${index}`}>
                    <Category
                      name={category.name}
                      description={category.description}
                      items={category.items}
                      key={index}
                      isCartButtonRendered={
                        menu.isOrderingEnabled && !!tableName
                      }
                      currency={menu.currency}
                      onIncreaseItemQuantity={increaseItemQuantity}
                      itemIdAndQuantity={itemIdAndQuantity}
                    />
                  </React.Fragment>
                ),
              }))}
          />
        </CategoryWrapper>
      )}
      <MenuFooter />
    </div>
  );
};

function MenuCover({
  themeColor,
  logo,
  restaurantName,
  coverImage,
}: {
  themeColor: string;
  logo?: string;
  restaurantName: string;
  coverImage?: string;
}) {
  return (
    <Box height={"200px"} bgcolor={themeColor} position="relative">
      {!!coverImage && <BgImg src={coverImage} />}
      <Box
        bgcolor="white"
        position="absolute"
        padding={1}
        bottom={-45}
        left={8}
        borderRadius={"50%"}
      >
        <Avatar
          src={logo}
          sx={{
            width: 90,
            height: 90,
            fontSize: "40px",
          }}
        >
          {restaurantName[0]}
        </Avatar>
      </Box>
    </Box>
  );
}
