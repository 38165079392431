import { useAuthentication } from "../../../../../auth-provider/AuthProvider";
import { useFetch } from "../../../../../client/useFetch";
import { useMenuIdFromURL } from "../../../../../hooks/menuIdFromURL";

export const useRemoveCategoryClient = () => {
  const { token } = useAuthentication();
  const menuId = useMenuIdFromURL();

  const { status, trigger } = useFetch(
    () => ({
      init: {
        method: "delete",
        headers: {
          Authorization: "Token " + token,
        },
      },
    }),
    [token],
    { autoTrigger: false, acceptedStatus: 200, parseJson: false }
  );
  return {
    removeCategory: (categoryId: number) =>
      trigger({
        input: `/api/menu/${menuId}/category/${categoryId}/remove/`,
      }),
    status: status,
  };
};
