import { Add } from "@mui/icons-material";
import { IconButton } from "@mui/material";

export const AddToCartButton = ({
  itemId,
  onIncreaseItemQuantity,
}: {
  onIncreaseItemQuantity: (itemId: number) => void;
  itemId: number;
}) => {
  return (
    <IconButton
      size="large"
      aria-label="add-to-cart-button"
      role="button"
      data-testid="test-id-add-to-cart-button"
      onClick={() => onIncreaseItemQuantity(itemId)}
    >
      <Add
        sx={{
          backgroundColor: "white",
          border: "1px solid black",
          borderRadius: "50%",
          color: "black",
          padding: 0,
        }}
      />
    </IconButton>
  );
};
