import styled from "styled-components";

export const InputField = styled.input`
  background-color: transparent;
  width:  ${(props) => (props.width ? props.width : "47%;")}
  color: rgba(86, 86, 86, 1);
  outline: none;
  outline-style: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: solid rgba(86, 86, 86, 1) 2px;
  padding: 3px;
  font-size: 2em;
  margin-bottom: 20px;
  margin-left: 2%;
`;

export const InlineFormInput = styled.input`
  display: inline;
  width: ${(props) => (props.width ? props.width : "100%;")};
  margin-top: 16px;
  border: none;
  background: rgba(226, 226, 226, 1);
  border-radius: 2px;
  height: 32px;
  font-size: 18px;
  padding-left: 8px;
  font-family: Roboto;
  box-sizing: border-box;
  outline: none;
  outline-style: none;
  color: black;
`;

export const SimpleInputField = styled.input`
  width: 90%;
  font-size: 16px;
  padding: 8px;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 24px;
  outline: none;
`;
