import { History } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";

import { useMenuIdFromURL } from "../../../hooks/menuIdFromURL";
import { DrawerOpenButton } from "./DrawerOpenButton";
import { NewOrder } from "./NewOrder";
import { NotificationSoundCheck } from "./NotificationSoundCheck";

export const OrderPageRightBar = () => {
  const menuId = useMenuIdFromURL();
  return (
    <>
      <NotificationSoundCheck />
      <IconButton
        color={"primary"}
        component={Link}
        to={`/dashboard/menu/${menuId}/orders/history`}
        data-testid="test-id-order-history-link-button"
        sx={{ marginRight: 1 }}
      >
        <History />
      </IconButton>

      <DrawerOpenButton />
      <NewOrder />
    </>
  );
};
