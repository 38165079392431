import React, { useEffect, useState } from "react";
import { Status } from "../../../client/status";
import { PopUpComponent } from "../../../shared-components/PopUpComponent";
import { AddStation } from "./AddStation";

import {
  ADD_KDS_STATION_SELECT_OPTION,
  KdsSelect,
  SelectInputType,
} from "./KdsSelect";
import { useKdsStations } from "./kdsStationsProvider";

const StationNameContentStyle = {
  maxWidth: "600px",
  width: "600px",
  height: "auto",
  overflow: "hidden",
  backgroundColor: "#f9f9f9",
  border: "1px solid black",
  padding: "24px",
};

export const KdsStation = () => {
  const [open, setOpen] = useState<boolean>(false);

  const { addStatus } = useKdsStations();

  const stationOptionSelected = (selectedOption: SelectInputType | null) => {
    if (selectedOption == null) return;

    selectedOption.value == ADD_KDS_STATION_SELECT_OPTION && setOpen(true);
  };

  useEffect(() => {
    addStatus == Status.Success && setOpen(false);
  }, [addStatus]);

  return (
    <>
      <PopUpComponent
        open={open}
        setOpen={setOpen}
        contentStyle={StationNameContentStyle}
      >
        <AddStation />
      </PopUpComponent>

      <KdsSelect onChange={stationOptionSelected} />
    </>
  );
};
