import React from "react";
import { useReactToPrint } from "react-to-print";
import { usePostClientWithAuth } from "../../../client/postClientWithAuth";
import {
  MenuDataType,
  useMenuData,
} from "../../../menu-provider/MenuDataProvider";
import { OrderType } from "../../../types/OrderType";
import {
  orderDiscount,
  orderTax,
  orderTotal,
  orderTotalWithTaxAndDiscount,
} from "./orderTotalUtils";

const BOLD_TEXT = "\x1b\x45\x01";
const CENTER_ALIGN = "\x1Ba1";
const LEFT_ALIGN = "\x1Ba0";

const END_BOLD_TEXT = "\x1bE0";
const RIGHT_ALIGN = "\x1Ba2";
const PAPER_CUT = "\x1B\x6D";
const FEED = "\x1b\x64\x04";
const MAX_CHARACTER = 48;
const MAX_LENGTH_OF_PRICE = 8;
const SPACES_BETWEEN_NAME_AND_PRICE = "    ";
const PADDING_FOR_SUMMARY_COSTS = 12;
export const OPEN_CASH_DRAWER = "\x1b\x70\x00\x1b\x70\x01";

const maxItemNameLength =
  MAX_CHARACTER - MAX_LENGTH_OF_PRICE - SPACES_BETWEEN_NAME_AND_PRICE.length;

export function usePrinter({
  printRef,
  order,
  openCashDrawer,
  willNotPrintReceipt,
  paidAmount,
  changeAmount,
}: {
  printRef: React.MutableRefObject<null>;
  order: OrderType;
  openCashDrawer?: true;
  willNotPrintReceipt?: true;
  paidAmount?: string;
  changeAmount?: string;
}) {
  const menuData = useMenuData();
  const { postData: savePrintJob, status } = usePostClientWithAuth("/api/job");

  const printViaBrowser = useReactToPrint({
    content: () => printRef.current,
  });

  const printStringPOS = () => {
    let orderPrintString = "";
    if (openCashDrawer) {
      orderPrintString += OPEN_CASH_DRAWER;
    }

    if (willNotPrintReceipt === true) return orderPrintString;

    orderPrintString += generatePrintString(
      menuData,
      order,
      paidAmount,
      changeAmount
    );

    return orderPrintString;
  };

  return {
    createPrintJob: () => {
      if (menuData.deviceId) {
        savePrintJob({
          deviceId: menuData.deviceId,
          printData: printStringPOS(),
        });
      } else {
        willNotPrintReceipt || printViaBrowser();
      }
    },
    printStatus: status,
  };
}

function generatePrintString(
  menuData: MenuDataType,
  order: OrderType,
  paidAmount?: string,
  changeAmount?: string
) {
  let orderPrintString = "";
  orderPrintString += `${BOLD_TEXT}${CENTER_ALIGN}${menuData.restaurantName}`;

  if (!!menuData.isAllTextBold) {
    orderPrintString += "\n";
  } else {
    orderPrintString += `${END_BOLD_TEXT}\n`;
  }

  if (menuData.address) {
    orderPrintString += `${menuData.address}\n`;
  }
  if (menuData.phone) {
    orderPrintString += `${menuData.phone}\n`;
  }

  if (!!menuData.bin) {
    orderPrintString += `BIN: ${menuData.bin}\n`;
  }

  orderPrintString += "------------------------------------------------\n\n";
  orderPrintString += LEFT_ALIGN;
  orderPrintString += `Order#${order.orderNumber}\n`;
  orderPrintString += `Table: ${order.tableName}\n`;
  orderPrintString += new Date(order.createdAt).toLocaleString("en-US", {
    timeStyle: "short",
    dateStyle: "medium",
  });
  orderPrintString += "\n\n";

  orderPrintString += CENTER_ALIGN;

  orderPrintString += orderDetailsPrintString(order) + "\n";

  orderPrintString += RIGHT_ALIGN;
  orderPrintString += "------------------------------\n";

  const total = `${menuData.currency}${orderTotal(order)}`.padStart(
    PADDING_FOR_SUMMARY_COSTS
  );
  orderPrintString += `Total:${total}\n`;

  const discount = `${menuData.currency}${orderDiscount(order)}`.padStart(
    PADDING_FOR_SUMMARY_COSTS
  );

  if (order.discount)
    orderPrintString += `Discount(${order.discount}%):${discount}\n`;

  if (!!order.serviceCharge) {
    orderPrintString = addServiceChargeInReceipt(
      order,
      menuData,
      orderPrintString
    );
  }

  if (parseFloat(orderTax(order)) !== 0) {
    orderPrintString = addTaxInOrderReceipt(
      menuData.currency,
      order,
      orderPrintString,
      menuData.taxLabel
    );
  }

  const grandTotal = `${menuData.currency}${orderTotalWithTaxAndDiscount(
    order
  )}`.padStart(PADDING_FOR_SUMMARY_COSTS);

  orderPrintString += `Grand Total:${grandTotal}\n`;
  orderPrintString += "===============================\n";
  orderPrintString += `Payment method: ${order.paymentType}\n`;

  if (order.paymentType === "cash") {
    if (!!paidAmount && parseInt(paidAmount) !== 0)
      orderPrintString += `Paid amount: ${menuData.currency}${paidAmount}\n`;
    if (!!changeAmount && parseInt(changeAmount) !== 0)
      orderPrintString += `Change: ${menuData.currency}${changeAmount}\n`;
  }

  orderPrintString += "\n\n\n";

  orderPrintString += CENTER_ALIGN;

  if (!!menuData.receiptNote) {
    orderPrintString += menuData.receiptNote + "\n";
  }

  orderPrintString += menuData.thanksNote;

  orderPrintString += FEED;
  orderPrintString += PAPER_CUT;
  return orderPrintString;
}

function addServiceChargeInReceipt(
  order: OrderType,
  menuData: MenuDataType,
  orderPrintString: string
) {
  const orderServiceCharge =
    (order.serviceCharge / 100) *
    (parseFloat(orderTotal(order)) - parseFloat(orderDiscount(order)));

  const serviceChargeAmountString = (
    menuData.currency + orderServiceCharge.toFixed(2)
  ).padStart(PADDING_FOR_SUMMARY_COSTS);

  orderPrintString += `Service charge(${order.serviceCharge}%):${serviceChargeAmountString}\n`;
  return orderPrintString;
}

function addTaxInOrderReceipt(
  currency: string,
  order: OrderType,
  orderPrintString: string,
  taxLabel: string
) {
  const tax = `${currency}${orderTax(order)}`.padStart(
    PADDING_FOR_SUMMARY_COSTS
  );

  if (order.isItemWiseTax) orderPrintString += `${taxLabel}:${tax}\n`;
  else orderPrintString += `${taxLabel}(${order.taxPercentage}%):${tax}\n`;
  return orderPrintString;
}

function orderDetailsPrintString(order: OrderType) {
  return order.orderDetails
    .map(({ quantity, itemName, itemPrice }) => {
      const subtotal = (quantity * itemPrice)
        .toFixed(2)
        .padStart(MAX_LENGTH_OF_PRICE);

      const itemNameAndQuantity = `${quantity}x ${itemName}`;

      const alignedItemNameAndQuantity =
        itemNameAndQuantity.length < maxItemNameLength
          ? itemNameAndQuantity.padEnd(maxItemNameLength)
          : itemNameAndQuantity.substring(0, maxItemNameLength);

      return `${alignedItemNameAndQuantity}${SPACES_BETWEEN_NAME_AND_PRICE}${subtotal}`;
    })
    .join("\n");
}
