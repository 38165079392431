import React, { useEffect } from "react";
import { Status } from "../../../client/status";
import { usePostResolveOrderClient } from "./postResolveOrderClient";
import { useAlert } from "react-alert";
import { Print, PrintDisabled } from "@mui/icons-material";
import { OrderReceiptPrintButton } from "./OrderReceiptPrintButton";
import { OrderType } from "../../../types/OrderType";
import { LoadingButton } from "@mui/lab";
import { Box, IconButton, Typography } from "@mui/material";

export type ActionButtonPropsType = {
  order: OrderType;
  paymentType: string;
  isSelectedBucketPaid: boolean;
  onOrderComplete: () => void;
  paidAmount?: string;
  changeAmount?: string;
};

const MARK_PAID_LAST_PATH = "mark-paid";

export function CompleteOrderButtons({
  order,
  paymentType,
  isSelectedBucketPaid,
  onOrderComplete,
  paidAmount,
  changeAmount,
}: ActionButtonPropsType) {
  const alert = useAlert();
  const { requestStatus: resolveStatus, resolveOrder } =
    usePostResolveOrderClient();

  useEffect(() => {
    resolveStatus === Status.Success && onOrderComplete();
    resolveStatus === Status.Error && alert.error("Something went wrong!");
  }, [alert, onOrderComplete, resolveStatus]);

  const callOrderResolveClient = () => {
    resolveOrder(order.id, {
      paymentType,
      discount: order.discount,
      bucket: "OVERALL",
    });
  };

  const markOrderPaid = () => {
    resolveOrder(
      order.id,
      {
        paymentType,
        discount: order.discount,
      },
      MARK_PAID_LAST_PATH
    );
  };

  return (
    <Box>
      <OrderReceiptPrintButton
        order={{ ...order, paymentType }}
        paidAmount={paidAmount}
        changeAmount={changeAmount}
        render={(printFunction) => (
          <IconButton
            color="primary"
            onClick={() => {
              printFunction();
            }}
            data-testid="test-id-receipt-print-button"
            sx={{ marginRight: "4px" }}
          >
            <Print fontSize="large" />
          </IconButton>
        )}
      ></OrderReceiptPrintButton>

      <OrderReceiptPrintButton
        order={{ ...order, paymentType }}
        paidAmount={paidAmount}
        changeAmount={changeAmount}
        openCashDrawer={true}
        willNotPrintReceipt={true}
        render={(printFunction) => (
          <LoadingButton
            onClick={() => {
              markOrderPaid();
              printFunction();
            }}
            sx={{ marginRight: "8px" }}
            variant="contained"
            color="primary"
            disabled={!!order.isPaid}
            loading={resolveStatus == Status.Pending}
            startIcon={<PrintDisabled />}
            data-testid="test-id-mark-paid-without-receipt-button"
          >
            <Box display={"flex"} flexDirection={"column"}>
              <Typography textAlign={"center"}>Mark Paid</Typography>
              <Typography fontSize={"10px"}>without receipt</Typography>
            </Box>
          </LoadingButton>
        )}
      ></OrderReceiptPrintButton>

      <OrderReceiptPrintButton
        order={{ ...order, paymentType }}
        openCashDrawer={true}
        paidAmount={paidAmount}
        changeAmount={changeAmount}
        render={(printFunction) => (
          <LoadingButton
            onClick={() => {
              markOrderPaid();
              printFunction();
            }}
            sx={{ marginRight: "8px" }}
            variant="contained"
            color="primary"
            disabled={!!order.isPaid}
            loading={resolveStatus == Status.Pending}
            startIcon={<Print />}
            data-testid="test-id-with-receipt-mark-paid-button"
          >
            <Box display={"flex"} flexDirection={"column"}>
              <Typography textAlign={"center"}>Mark Paid</Typography>
              <Typography fontSize={"10px"}>with receipt</Typography>
            </Box>
          </LoadingButton>
        )}
      ></OrderReceiptPrintButton>

      <OrderReceiptPrintButton
        order={{ ...order, paymentType }}
        paidAmount={paidAmount}
        changeAmount={changeAmount}
        openCashDrawer={true}
        willNotPrintReceipt={true}
        render={(printFunction) => (
          <LoadingButton
            onClick={() => {
              callOrderResolveClient();
              printFunction();
            }}
            sx={{ marginRight: "8px" }}
            variant="contained"
            color="primary"
            disabled={isSelectedBucketPaid}
            loading={resolveStatus == Status.Pending}
            startIcon={<PrintDisabled />}
            data-testid="test-id-without-receipt-complete-order-button"
          >
            <Box display={"flex"} flexDirection={"column"}>
              <Typography textAlign={"center"}>Complete</Typography>
              <Typography fontSize={"10px"}>without receipt</Typography>
            </Box>
          </LoadingButton>
        )}
      ></OrderReceiptPrintButton>

      <OrderReceiptPrintButton
        order={{ ...order, paymentType }}
        paidAmount={paidAmount}
        changeAmount={changeAmount}
        openCashDrawer={true}
        render={(printFunction) => (
          <LoadingButton
            onClick={() => {
              callOrderResolveClient();
              printFunction();
            }}
            variant="contained"
            color="primary"
            disabled={isSelectedBucketPaid}
            loading={resolveStatus == Status.Pending}
            startIcon={<Print />}
            data-testid="test-id-with-receipt-complete-order-button"
          >
            <Box display={"flex"} flexDirection={"column"}>
              <Typography textAlign={"center"}>Complete</Typography>
              <Typography fontSize={"10px"}>with receipt</Typography>
            </Box>
          </LoadingButton>
        )}
      ></OrderReceiptPrintButton>
    </Box>
  );
}
