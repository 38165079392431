import { ListItem, ListItemButton, ListItemText } from "@mui/material";
import React, { useMemo } from "react";
import { Link, useRouteMatch } from "react-router-dom";

export const NavLink = ({
  to,
  dataTestId,
  title,
}: {
  to: string;
  dataTestId: string;
  title: string;
}) => {
  const { url } = useRouteMatch();
  const isCurrent = useMemo(() => url == to, [url, to]);

  return (
    <ListItem disablePadding>
      <ListItemButton
        component={Link}
        to={to}
        data-testid={dataTestId}
        disabled={isCurrent}
        selected={isCurrent}
      >
        <ListItemText primary={title} />
      </ListItemButton>
    </ListItem>
  );
};
