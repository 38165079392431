import React from "react";

import { KdsOrderDetail } from "./KdsOrderDetails";

import { KdsStation } from "./KdsStation";
import { useKdsStations } from "./kdsStationsProvider";

import { JustifyCenterContainer } from "../../../design-system/Container";
import { KdsStationOrders } from "./KdsStationOrders";
import { KdsFilterCategory } from "./KdsFilterCategory";

export const Kds = () => {
  const { selectedStationId } = useKdsStations();

  return (
    <>
      {selectedStationId && <KdsFilterCategory />}
      <KdsStation />

      {selectedStationId == null ? <SelectStationText /> : <KdsStationOrders />}
    </>
  );
};

const SelectStationText = () => {
  return (
    <JustifyCenterContainer width="100%;">
      <h1>Please select a station</h1>
    </JustifyCenterContainer>
  );
};

export type KDSOrderType = {
  id: number;
  orderNumber: number;
  createdAt: string;
  tableName: string;
  orderDetails: KdsOrderDetail[];
};
