import { Print } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import { ReactNode, useRef } from "react";
import { Status } from "../../../client/status";
import { OrderType } from "../../../types/OrderType";
import { Receipt } from "./Receipt";
import { usePrinter } from "./usePrinter";

export const OrderReceiptPrintButton = ({
  order,
  openCashDrawer,
  willNotPrintReceipt,
  render,
  paidAmount,
  changeAmount,
}: {
  order: OrderType;
  openCashDrawer?: true;
  willNotPrintReceipt?: true;
  paidAmount?: string;
  changeAmount?: string;
  render?: (aFunction: () => void) => ReactNode;
}) => {
  const printRef = useRef(null);
  const { createPrintJob, printStatus } = usePrinter({
    printRef,
    order,
    openCashDrawer,
    willNotPrintReceipt,
    paidAmount,
    changeAmount,
  });

  return (
    <>
      {printStatus == Status.Pending ? (
        <Button disabled>
          <CircularProgress size={25} data-testid="test-id-print-loading" />
        </Button>
      ) : !render ? (
        <Button
          data-testid="test-id-default-print-icon-button"
          onClick={createPrintJob}
        >
          <Print />
        </Button>
      ) : (
        render(createPrintJob)
      )}

      <div style={{ display: "none" }}>
        <div ref={printRef}>
          <Receipt order={order} />
        </div>
      </div>
    </>
  );
};
