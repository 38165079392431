import { Box, Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { usePostClientWithAuth } from "../../../../client/postClientWithAuth";
import { Status } from "../../../../client/status";
import { useMenuIdFromURL } from "../../../../hooks/menuIdFromURL";
import { useMenuData } from "../../../../menu-provider/MenuDataProvider";

export const UpdateCurrency = () => {
  const { reloadMenuData, currency: menuCurrency } = useMenuData();
  const [currency, setCurrency] = useState<string>(menuCurrency);

  const menuId = useMenuIdFromURL();
  const { postData: updateCurrency, status } = usePostClientWithAuth(
    `/api/menu/${menuId}/update-currency/`
  );
  const alert = useAlert();

  useEffect(() => {
    if (status == Status.Success) {
      alert.success("The currency has been updated");
      reloadMenuData();
      return;
    }
    status == Status.Error && alert.error("Request failed");
  }, [alert, reloadMenuData, status]);

  useEffect(() => {
    menuCurrency !== undefined && setCurrency(menuCurrency);
  }, [menuCurrency]);

  return (
    <Box marginRight={1} display="flex" justifyContent={"space-between"}>
      <TextField
        onChange={(e) => setCurrency(e.target.value)}
        placeholder={"Currency"}
        value={currency}
        size="small"
        label={"Currency"}
        sx={{ width: "100px" }}
        variant="standard"
      />
      <Button
        onClick={() => updateCurrency({ currency: currency })}
        variant="contained"
        size="small"
      >
        Update
      </Button>
    </Box>
  );
};
