import styled from "styled-components";
import { ItemType } from "../client/menuClient";
import { ItemImageComponent } from "./ItemImageComponent";
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  Typography,
  styled as muiStyled,
} from "@mui/material";
import { QuantityAndIdForCartItem } from "./cart-provider/CartProvider";
import { ItemNameAndPrice } from "./ItemNameAndPrice";
import { AddToCartButton } from "./AddToCartButton";
import { itemElementStyle } from "./menuStyle";
import { useState } from "react";
import { IconButtonWrap } from "./IconButtonWrap";
import { ExpandCircleDown } from "@mui/icons-material";

const ItemImageColumn = styled.div`
  position: relative;
  display: block;
  height: 60px;
  width: 90px;
  margin-left: 12px;
`;

const ItemBox = muiStyled(Box)({
  marginTop: "8px",
  marginBottom: "8px",
  display: "flex",
  alignItems: "center",
});

export type ItemPropType = {
  items: ItemType[];
  isCartButtonRendered: boolean;
  currency: string;
  onIncreaseItemQuantity: (itemId: number) => void;
  itemCart?: QuantityAndIdForCartItem[];
};

export const Item = ({
  items,
  isCartButtonRendered,
  currency,
  onIncreaseItemQuantity,
  itemCart,
}: ItemPropType) => {
  const [willShowVariations, setWillShowVariations] = useState(false);

  const itemQuantityInCart = items.reduce((acc, item) => {
    const itemQtyInCart = itemCart?.find((value) => value.id == item.id);
    let tempQty = 0;
    if (itemQtyInCart !== undefined) tempQty = itemQtyInCart?.quantity;

    return acc + tempQty;
  }, 0);

  const itemHasVariation = items.length > 1;

  return (
    <Box marginLeft={2} marginRight={2}>
      <ItemBox data-testid="menu-item">
        {itemQuantityInCart > 0 && (
          <QuantityBadge itemQuantityInCart={itemQuantityInCart} />
        )}
        <Box display={"flex"} justifyContent={"space-between"} width="100%">
          <ItemNameAndPrice
            item={items[0]}
            currency={currency}
            willRenderPrice={!itemHasVariation}
          />

          <ItemImageColumn>
            <ItemImageComponent imageUrl={items[0].imageUrl} />
            <IconButtonWrap>
              {itemHasVariation ? (
                <ExpandCollapseButton
                  willShowVariations={willShowVariations}
                  setWillShowVariations={setWillShowVariations}
                />
              ) : (
                isAddToCartButtonShowing(isCartButtonRendered, items[0]) && (
                  <AddToCartButton
                    itemId={items[0].id}
                    onIncreaseItemQuantity={onIncreaseItemQuantity}
                  />
                )
              )}
            </IconButtonWrap>
          </ItemImageColumn>
        </Box>
      </ItemBox>

      <Typography marginBottom={1} sx={itemElementStyle}>
        {items[0].description}
      </Typography>

      <Box paddingLeft={2}>
        <Collapse in={willShowVariations} timeout="auto" unmountOnExit>
          <ItemVariations
            items={items}
            currency={currency}
            onIncreaseItemQuantity={onIncreaseItemQuantity}
            isCartButtonRendered={isCartButtonRendered}
          ></ItemVariations>
        </Collapse>
      </Box>

      <Divider />
    </Box>
  );
};

function isAddToCartButtonShowing(
  isCartButtonRendered: boolean,
  item: ItemType
) {
  if (!item.isInventoryEnabled) return isCartButtonRendered;

  const availableQty = item.availableQuantity;
  if (availableQty === undefined || availableQty === null)
    return isCartButtonRendered;

  return isCartButtonRendered && availableQty > 0;
}

function ItemVariations({
  items,
  currency,
  isCartButtonRendered,
  onIncreaseItemQuantity,
}: {
  items: ItemType[];
  currency: string;
  isCartButtonRendered: boolean;
  onIncreaseItemQuantity: (itemId: number) => void;
}) {
  return (
    <>
      {items.map((value, index) => {
        return (
          <Box
            display="flex"
            width="100%"
            justifyContent={"space-between"}
            key={index}
            alignItems={"center"}
            data-testid="test-id-item-variation-detail"
          >
            <Typography>{value.variation}</Typography>
            <Box display={"flex"} alignItems={"center"}>
              <Typography>
                {currency}
                {value.price}
              </Typography>
              {isAddToCartButtonShowing(isCartButtonRendered, value) && (
                <AddToCartButton
                  itemId={value.id}
                  onIncreaseItemQuantity={onIncreaseItemQuantity}
                ></AddToCartButton>
              )}
            </Box>
          </Box>
        );
      })}
    </>
  );
}

function QuantityBadge({ itemQuantityInCart }: { itemQuantityInCart: number }) {
  return (
    <Typography
      data-testid="test-id-item-quantity-in-cart"
      bgcolor={"#1e88e5"}
      textAlign={"center"}
      color={"white"}
      lineHeight={2}
      width={32}
      height={29}
      marginRight={1}
      borderRadius={50}
    >
      {itemQuantityInCart}
    </Typography>
  );
}

function ExpandCollapseButton({
  willShowVariations,
  setWillShowVariations,
}: {
  willShowVariations: boolean;
  setWillShowVariations: (flag: boolean) => void;
}) {
  return (
    <IconButton
      onClick={() => setWillShowVariations(!willShowVariations)}
      data-testid="test-id-expand-variation-button"
    >
      <ExpandCircleDown
        fontSize="large"
        sx={{
          borderRadius: "50%",
          backgroundColor: "white",
          color: "black",
          transform: `rotate(${willShowVariations ? 180 : 0}deg)`,
        }}
      />
    </IconButton>
  );
}
