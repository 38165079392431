import { RestoreFromTrash } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useEffect } from "react";
import { usePostClientWithAuth } from "../../../client/postClientWithAuth";
import { Status } from "../../../client/status";

export function SoftDeleteUndoButton({
  orderId,
  onSuccess,
}: {
  orderId: number;
  onSuccess: () => void;
}) {
  const { postData: softDelete, status: softDeleteUndoStatus } =
    usePostClientWithAuth(
      `/api/order/${orderId}/action/undo-soft-delete/`,
      200
    );

  useEffect(() => {
    softDeleteUndoStatus === Status.Success && onSuccess();
  }, [onSuccess, softDeleteUndoStatus]);

  return (
    <IconButton
      data-testid="test-id-undo-soft-delete-button"
      disabled={softDeleteUndoStatus === Status.Pending}
      onClick={() => {
        softDelete(undefined);
      }}
      color="primary"
    >
      <RestoreFromTrash />
    </IconButton>
  );
}
