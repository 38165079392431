import React, { useMemo } from "react";
import { Item } from "./Item";
import { ItemType } from "../client/menuClient";
import { QuantityAndIdForCartItem } from "./cart-provider/CartProvider";
import { groupBy } from "lodash";
import { styled, Typography } from "@mui/material";

type CategoryPropType = {
  name: string;
  description: string;
  items: ItemType[];
  dataTestId?: string;
  currency: string;
  isCartButtonRendered: boolean;
  itemIdAndQuantity?: QuantityAndIdForCartItem[];
  onIncreaseItemQuantity: (itemId: number) => void;
};

const CategoryDescription = styled(Typography)({
  fontSize: "14px",
  textAlign: "justify",
  margin: "0px 16px 16px 16px",
  fontWeight: "bold",
});

export const Category = ({
  name,
  description,
  items,
  currency,
  isCartButtonRendered,
  onIncreaseItemQuantity,
  itemIdAndQuantity,
}: CategoryPropType) => {
  const itemsWithVariationArray = useMemo(() => {
    const groupedByNameItems = groupBy(items, "name");
    return Object.values(groupedByNameItems);
  }, [items]);

  return (
    <div data-testid="category">
      <Typography variant="h5" sx={{ margin: 2 }}>
        {name}
      </Typography>
      <CategoryDescription>{description}</CategoryDescription>
      {itemsWithVariationArray.map(
        (itemWithVariationArray, index) =>
          itemWithVariationArray[0].isEnabled && (
            <React.Fragment key={itemWithVariationArray[0].id}>
              <Item
                key={index}
                items={itemWithVariationArray}
                isCartButtonRendered={isCartButtonRendered}
                currency={currency}
                onIncreaseItemQuantity={onIncreaseItemQuantity}
                itemCart={itemIdAndQuantity}
              />
            </React.Fragment>
          )
      )}
    </div>
  );
};
