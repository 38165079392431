import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, Typography } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { usePostClientWithAuth } from "../../../client/postClientWithAuth";
import { Status } from "../../../client/status";
import { useMenuIdFromURL } from "../../../hooks/menuIdFromURL";
import { isEmptyOrSpacesOnly } from "../stringUtils";
import { OrderType } from "../../../types/OrderType";
import { ErrorOutline } from "@mui/icons-material";

export const ConfirmEdit = ({
  isConfirmDisable,
  order,
  onUpdateSuccess,
  buttonText,
  startIcon,
  isNewOrder,
}: {
  isConfirmDisable: boolean;
  order: OrderType;

  onUpdateSuccess: (order: OrderType) => void;
  buttonText: string;
  startIcon?: ReactNode;
  isNewOrder?: boolean;
}) => {
  const alert = useAlert();
  const menuId = useMenuIdFromURL();

  const [showAlert, setShowAlert] = useState<
    false | "NoTableAlert" | "NoAddressAlert"
  >(false);

  const [isExecutedOnce, setIsExecutedOnce] = useState(false);

  const postRequestUrl = !!isNewOrder
    ? `/api/menu/${menuId}/order/`
    : `/api/order/${order.id}/update/`;
  const {
    postData: saveData,
    status: updateStatus,
    responseData,
  } = usePostClientWithAuth<OrderType>(postRequestUrl, undefined, true);

  const updateOrder = () => {
    if (isEmptyOrSpacesOnly(order.tableName)) {
      setShowAlert("NoTableAlert");
      return;
    }

    if (
      isEmptyOrSpacesOnly(order.customerAddress) &&
      order.tableName == "Delivery"
    ) {
      setShowAlert("NoAddressAlert");
      return;
    }

    setIsExecutedOnce(false);
    saveData({ ...order, createdBy: "owner" });
  };

  useEffect(() => {
    if (isExecutedOnce) return;
    if (updateStatus === Status.Success && responseData !== undefined) {
      setIsExecutedOnce(true);
      onUpdateSuccess(responseData);
      return;
    }
  }, [isExecutedOnce, onUpdateSuccess, responseData, updateStatus]);

  useEffect(() => {
    updateStatus === Status.Error && alert.error("Request failed");
  }, [alert, updateStatus]);

  return (
    <>
      <AcknowledgeableAlert
        showAlert={showAlert}
        onCloseButtonClick={() => setShowAlert(false)}
      />

      <LoadingButton
        variant={"contained"}
        onClick={updateOrder}
        startIcon={startIcon}
        loadingPosition="start"
        disabled={isConfirmDisable}
        loading={updateStatus === Status.Pending}
        sx={{ marginLeft: 1 }}
        size={"large"}
      >
        {buttonText}
      </LoadingButton>
    </>
  );
};

const AcknowledgeableAlert = ({
  showAlert,
  onCloseButtonClick,
}: {
  showAlert: false | "NoTableAlert" | "NoAddressAlert";
  onCloseButtonClick: () => void;
}) => {
  return (
    <Dialog open={showAlert !== false} maxWidth="lg">
      <Box
        display={"flex"}
        alignItems="center"
        flexDirection={"column"}
        padding={2}
      >
        <Typography color="error">
          <ErrorOutline fontSize="large" />
        </Typography>
        <Typography variant="h4" textAlign={"center"} mb={1}>
          {showAlert === "NoTableAlert"
            ? "Table name is required for dine-in orders."
            : "Address is required for delivery order."}
        </Typography>

        <Button variant="contained" onClick={onCloseButtonClick} size="large">
          Close
        </Button>
      </Box>
    </Dialog>
  );
};
