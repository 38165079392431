import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDataPollingClient } from "../../../client/dataPollingClient";
import { Status } from "../../../client/status";
import { useMenuIdFromURL } from "../../../hooks/menuIdFromURL";
import { OrderType } from "../../../types/OrderType";

const OrderDataContext = createContext<{
  orders: OrderType[] | undefined;
  reloadOrder: () => void;
  orderStatus: Status;
}>({
  orders: undefined,
  reloadOrder: () => {},
  orderStatus: Status.NotStarted,
});

export const OrderDataProvider = ({ children }: { children: ReactNode }) => {
  const menuId = useMenuIdFromURL();
  const {
    data: orderData,
    reloadData,
    status,
  } = useDataPollingClient<OrderType[]>(`/api/menu/${menuId}/orders/`);

  const [updatedOrders, setUpdatedOrders] = useState<OrderType[] | undefined>(
    undefined
  );

  useEffect(() => {
    setUpdatedOrders(orderData);
  }, [orderData]);

  return (
    <OrderDataContext.Provider
      value={{
        orders: updatedOrders,
        reloadOrder: reloadData,
        orderStatus: status,
      }}
    >
      {children}
    </OrderDataContext.Provider>
  );
};

export const useOrderDataManager = () => {
  const { orders, reloadOrder, orderStatus } = useContext(OrderDataContext);
  return {
    orders,
    reloadOrder,
    orderStatus,
  };
};
