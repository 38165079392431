import { Box, Button, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  PaperScroller,
  VerticalScrolls,
} from "../../../design-system/Container";
import { useMenuData } from "../../../menu-provider/MenuDataProvider";
import { InventoryItem } from "./InventoryItem";

export const Inventory = () => {
  const { categories } = useMenuData();
  const [selectedCategoryId, setSelectedCategoryId] = useState<
    number | undefined
  >();

  useEffect(() => {
    if (selectedCategoryId == undefined && categories.length >= 1)
      setSelectedCategoryId(categories[0].id);
  }, [categories, selectedCategoryId]);

  return (
    <Box
      bgcolor={"rgb(245, 245, 245)"}
      height={"calc(100vh - 60px)"}
      padding={1}
      boxSizing={"border-box"}
      borderRadius={2}
      display={"flex"}
    >
      <Box key={1} width={"30%"}>
        <VerticalScrolls>
          {categories.map((category) => {
            return (
              <React.Fragment key={category.id}>
                <Box
                  bgcolor={
                    selectedCategoryId == category.id
                      ? "#7bb8e96b"
                      : "transparent"
                  }
                >
                  <Button
                    onClick={() => setSelectedCategoryId(category.id)}
                    sx={{
                      color: "rgba(0, 0, 0, 0.6)",
                    }}
                    fullWidth
                  >
                    {category.name}
                  </Button>
                </Box>
                <Divider light={true} />
              </React.Fragment>
            );
          })}
        </VerticalScrolls>
      </Box>

      <Box key={2} width="70%">
        <PaperScroller variant={"outlined"}>
          <Box padding={1}>
            {categories
              .find((category) => selectedCategoryId === category.id)
              ?.items.map((item) => {
                return (
                  <React.Fragment key={item.id}>
                    <InventoryItem item={item} />
                    <Divider sx={{ marginTop: "8px", marginBottom: "8px" }} />
                  </React.Fragment>
                );
              })}
          </Box>
        </PaperScroller>
      </Box>
    </Box>
  );
};
