import { CategoryType } from "../../../../client/menuClient";
import { EditCategory } from "./EditCategory";
import { RemoveCategory } from "./RemoveCategory";

export type CategoryPropType = {
  category: CategoryType;
  onCategoryNameClick: () => void;
};

export const DashboardCategory = ({
  category,
  onCategoryNameClick,
}: CategoryPropType) => {
  return (
    <>
      <RemoveCategory
        numberOfItems={category.items.length}
        categoryId={category.id}
      />
      <EditCategory
        categoryName={category.name}
        categoryId={category.id}
        onCategoryNameClick={onCategoryNameClick}
      />
    </>
  );
};
