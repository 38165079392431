import { OrderType } from "../../../types/OrderType";
import { CompleteOrder } from "./CompleteOrder";

export const QuickOrder = ({
  order,
  onClose,
  onCompleteOrder,
}: {
  order: OrderType;
  onClose: (aOrder: OrderType) => void;
  onCompleteOrder: () => void;
}) => {
  return (
    <CompleteOrder
      order={order}
      onGoBack={() => onClose(order)}
      onCompleteOrder={onCompleteOrder}
    />
  );
};
