import { Button } from "@mui/material";
import { useMenuIdFromURL } from "../../hooks/menuIdFromURL";
import { Link } from "react-router-dom";

export const OrderHistoryRightBar = () => {
  const menuId = useMenuIdFromURL();

  return (
    <Button
      variant="contained"
      size={"small"}
      component={Link}
      to={`/dashboard/menu/${menuId}/orders`}
      data-testid="test-id-orders-link-button"
    >
      Active Orders
    </Button>
  );
};
