import { ListItemButton, Typography } from "@mui/material";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuthentication } from "../auth-provider/AuthProvider";

export function LoginLogoutButton() {
  const { isLoggedIn, logout } = useAuthentication();
  const history = useHistory();

  const handleLogoutClick = () => {
    logout();
    history.push("/login");
  };

  return (
    <>
      {isLoggedIn || (
        <ListItemButton component={Link} to="/login">
          <Typography fontSize={"16px"}>Login</Typography>
        </ListItemButton>
      )}

      {isLoggedIn && (
        <ListItemButton onClick={handleLogoutClick}>
          <Typography fontSize={"16px"}>Logout</Typography>
        </ListItemButton>
      )}
    </>
  );
}
