import React, { ReactNode, useCallback, useEffect, useRef } from "react";
import { Tab, Tabs } from "@mui/material";
import { DebouncedFunc, throttle } from "lodash";

const tabHeight = 50;

/******* This is the scrscrolloll spy magic */
/*
Credits: Material UI
Source: 
https://github.com/mui-org/material-ui/blob/404c2ba16816f5c7ab7d8b2caf6bbc3d2218b820/docs/src/modules/utils/textToHash.js
*/
const makeUnique = (
  hash: string,
  unique: Record<string, boolean>,
  i = 1
): string => {
  const uniqueHash = i === 1 ? hash : `${hash}-${i}`;

  if (!unique[uniqueHash]) {
    unique[uniqueHash] = true;
    return uniqueHash;
  }

  return makeUnique(hash, unique, i + 1);
};

const textToHash = (text: string, unique = {}) => {
  return makeUnique(
    encodeURI(
      text
        .toLowerCase()
        .replace(/=&gt;|&lt;| \/&gt;|<code>|<\/code>|&#39;/g, "")
        // eslint-disable-next-line no-useless-escape
        .replace(/[!@#\$%\^&\*\(\)=_\+\[\]{}`~;:'"\|,\.<>\/\?\s]+/g, "-")
        .replace(/-+/g, "-")
        .replace(/^-|-$/g, "")
    ),
    unique
  );
};

const THROTTLE_DELAY = 166;

export function ScrollSpyTabs({
  tabsInScroll,
}: {
  tabsInScroll: { text: string; component: ReactNode }[];
}) {
  const [activeState, setActiveState] = React.useState<string | undefined>();

  const itemsClientRef = React.useRef<
    {
      text: string;
      component: ReactNode;
      hash: string | undefined;
      node: HTMLElement | null;
    }[]
  >([]);

  const itemsServer = tabsInScroll.map((tab) => {
    const hash = textToHash(tab.text);

    return {
      text: tab.text,
      component: tab.component,
      hash: hash,
      node: document.getElementById(hash),
    };
  });

  useEffect(() => {
    itemsClientRef.current = itemsServer;
  }, [itemsServer]);

  const clickedRef = useRef(false);
  const unsetClickedRef = useRef<ReturnType<typeof setTimeout> | undefined>();

  const findActiveIndex = useCallback(() => {
    // set default if activeState is null
    if (activeState === undefined) setActiveState(itemsServer[0].hash);

    // Don't set the active index based on scroll if a link was just clicked
    if (clickedRef.current) return;

    let active;
    for (let i = itemsClientRef.current.length - 1; i >= 0; i -= 1) {
      // No hash if we're near the top of the page
      if (document.documentElement.scrollTop < 0) {
        active = { hash: undefined };
        break;
      }

      const item = itemsClientRef.current[i];

      if (
        item.node &&
        item.node.offsetTop <
          document.documentElement.scrollTop +
            document.documentElement.clientHeight / 8 +
            tabHeight
      ) {
        active = item;

        break;
      }
    }

    if (active && activeState !== active.hash) {
      setActiveState(active.hash);
    }
  }, [activeState, itemsServer]);

  // Corresponds to 10 frames at 60 Hz
  useThrottledOnScroll(
    itemsServer.length > 0 ? findActiveIndex : undefined,
    THROTTLE_DELAY
  );

  const handleClick = (hash: string | undefined) => () => {
    if (hash == undefined) return;
    // Used to disable findActiveIndex if the page scrolls due to a click
    clickedRef.current = true;
    unsetClickedRef.current = setTimeout(() => {
      clickedRef.current = false;
    }, 1000);

    if (activeState !== hash) {
      setActiveState(hash);

      const element = document.getElementById(hash);

      if (element === null) return;

      if (window) {
        window.scrollTo({
          top:
            element.getBoundingClientRect().top +
            window.pageYOffset -
            tabHeight,
          behavior: "smooth",
        });
      }
    }
  };

  useEffect(
    () => () => {
      if (unsetClickedRef.current !== undefined)
        clearTimeout(unsetClickedRef.current);
    },
    []
  );

  return (
    <>
      <Tabs
        value={activeState === undefined ? false : activeState}
        variant={"scrollable"}
        sx={{
          position: "sticky",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 500,
          backgroundColor: "#fff",
          paddingBottom: 1,
        }}
      >
        {itemsServer.map((item2) => (
          <Tab
            key={item2.hash}
            label={item2.text}
            onClick={handleClick(item2.hash)}
            value={item2.hash}
            sx={{
              bgcolor: "grey",
              border: "1px solid #81626240",
              borderRight: "none",
            }}
          />
        ))}
      </Tabs>

      <div>
        {itemsServer.map((item1) => (
          <article id={item1.hash} key={item1.text}>
            {item1.component}
          </article>
        ))}
      </div>
    </>
  );
}

export function useThrottledOnScroll(
  callback: (() => void) | undefined,
  delay: number
) {
  const throttledCallback = React.useMemo<
    DebouncedFunc<() => void> | undefined
  >(
    () => (callback ? throttle(callback, delay) : undefined),
    [callback, delay]
  );

  React.useEffect(() => {
    if (!throttledCallback) return undefined;

    window.addEventListener("scroll", throttledCallback);

    return () => {
      window.removeEventListener("scroll", throttledCallback);
      throttledCallback.cancel();
    };
  }, [throttledCallback]);
}
