import { CartItemEditForm } from "../../../menu/CartItemEditForm";
import { Backdrop, Box, Divider } from "@mui/material";
import { useMenuData } from "../../../menu-provider/MenuDataProvider";
import { OrderType } from "../../../types/OrderType";

type EditOrderDetailsProps = {
  order: OrderType;
  increaseItemQuantity: (itemId: number) => void;
  decreaseItemQuantity: (itemId: number) => void;
  updateNote: (itemId: number, note: string) => void;
};

export const EditOrderDetails = ({
  order,
  increaseItemQuantity,
  decreaseItemQuantity,
  updateNote,
}: EditOrderDetailsProps) => {
  const { currency } = useMenuData();

  return (
    <Box
      paddingBottom={6}
      paddingLeft={2}
      paddingRight={2}
      position={"relative"}
      data-testid="test-id-order-details"
      boxSizing={"border-box"}
    >
      <Backdrop
        open={!!order.isPaid}
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: 10,
          borderRadius: 1,
        }}
        data-testid="test-id-paid-order-overlay"
      ></Backdrop>

      {order.orderDetails.map((orderDetail) => (
        <div
          key={orderDetail.itemId}
          data-testid="test-id-order-item-edit-form"
        >
          <CartItemEditForm
            id={orderDetail.itemId}
            itemName={orderDetail.itemName}
            note={orderDetail.note}
            quantity={orderDetail.quantity}
            price={orderDetail.itemPrice}
            currency={currency}
            variation={orderDetail?.variation}
            onDecreaseItemQuantity={decreaseItemQuantity}
            onIncreaseItemQuantity={increaseItemQuantity}
            onUpdateNoteInCart={updateNote}
          />
          <Divider />
        </div>
      ))}
    </Box>
  );
};
