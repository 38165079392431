import styled from "styled-components";
import { RowContainer } from "../../../design-system/Container";

export const HelpRequestRow = styled("div")`
  width: 96%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 16px;
  margin-top: 16px;
  box-sizing: border-box;
  overflow-x: auto;
  overflow-y: hidden;
  box-sizing: border-box;
`;
export const HelpRequestContent = styled(RowContainer)`
  margin-right: 28px;
  min-width: 270px;
  max-width: 270px;
  cursor: pointer;
`;
