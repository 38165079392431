import { ItemType } from "../client/menuClient";
import { Box, Typography } from "@mui/material";
import { itemElementStyle } from "./menuStyle";

export const ItemNameAndPrice = ({
  item,
  willRenderPrice,
  currency,
}: {
  item: ItemType;
  willRenderPrice: boolean;
  currency: string;
}) => (
  <Box>
    <Typography>{item.name}</Typography>
    {willRenderPrice && (
      <Typography sx={itemElementStyle}>
        {currency}
        {parseFloat(item.price.toString()).toFixed(2)}
      </Typography>
    )}
  </Box>
);
