import { Box, Divider, Paper, Typography } from "@mui/material";
import { useDataPollingClient } from "../../../client/dataPollingClient";
import { useMenuIdFromURL } from "../../../hooks/menuIdFromURL";
import { OrderType } from "../../../types/OrderType";

export const QueueingDisplay = () => {
  const menuId = useMenuIdFromURL();
  const { data: orders } = useDataPollingClient<OrderType[]>(
    `/api/menu/${menuId}/orders/`
  );
  return (
    <Box
      display={"flex"}
      justifyContent={"space-evenly"}
      height={"calc(100vh - 60px)"}
    >
      <Paper data-testid="test-id-processing-section" sx={{ width: "49%" }}>
        <Typography variant="h3" margin={1} textAlign={"center"}>
          Preparing
        </Typography>
        <Divider />
        <Box
          display={"flex"}
          flexWrap={"wrap"}
          justifyContent={"space-evenly"}
          marginTop={1}
          padding={1}
        >
          {orders
            ?.filter((order) => !order.isReady)
            .map((value) => {
              return (
                <Paper
                  elevation={0}
                  sx={{
                    flexBasis: "220px",
                    marginBottom: 2,
                    backgroundColor: "#c0e9bc",
                  }}
                  key={value.orderNumber}
                >
                  <Typography
                    key={value.orderNumber}
                    variant="h2"
                    textAlign={"center"}
                  >
                    {value.orderNumber}
                  </Typography>
                </Paper>
              );
            })}
        </Box>
      </Paper>
      <Divider />

      <Paper data-testid="test-id-ready-section" sx={{ width: "49%" }}>
        <Typography variant="h3" margin={1} textAlign={"center"}>
          Ready
        </Typography>
        <Divider />

        <Box
          display={"flex"}
          flexWrap={"wrap"}
          justifyContent={"space-evenly"}
          marginTop={1}
          padding={1}
        >
          {orders
            ?.filter((order) => order.isReady)
            .map((value) => {
              return (
                <Paper
                  elevation={0}
                  key={value.orderNumber}
                  sx={{
                    flexBasis: "220px",
                    marginBottom: 2,
                    backgroundColor: "hwb(139deg 0% 2% / 27%)",
                  }}
                >
                  <Typography
                    key={value.orderNumber}
                    variant="h2"
                    textAlign={"center"}
                  >
                    {value.orderNumber}
                  </Typography>
                </Paper>
              );
            })}
        </Box>
      </Paper>
    </Box>
  );
};
