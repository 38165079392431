import { Redirect } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Status, useLoginPost } from "./authClient";
import { useAlert } from "react-alert";
import { JustifyCenterContainer } from "../../design-system/Container";
import { useAuthentication } from "../../auth-provider/AuthProvider";
import { HyperLink } from "../menuSpecificDashboard/DashboardDesign";
import { Button, Container, Grid, TextField, Typography } from "@mui/material";
import GoogleSignInImg from "../img/google-btn.png";
import { CSRFToken } from "./CSRFToken";

export const LoginForm = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const { token, status, setLogin } = useLoginPost();
  const alert = useAlert();
  const isEmailAndPassValid = email && password;
  const { setToken } = useAuthentication();

  const handleEmailChange = (emailFromInput: string) => {
    setEmail(emailFromInput);
  };

  const handlePasswordChange = (passwordFromInput: string) => {
    setPassword(passwordFromInput);
  };

  useEffect(() => {
    status === Status.Error && alert.error("Request Failed");
    status === Status.AuthError && alert.error("Incorrect Email or Password");
  }, [status, alert]);

  useEffect(() => {
    token && setToken(token);
  }, [token, setToken]);

  const handleSubmit = () => {
    isEmailAndPassValid || alert.error("Incorrect email or password.");
    isEmailAndPassValid && setLogin({ email: email, password: password });
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <Container maxWidth={"sm"} sx={{ mt: 5 }}>
      {status === Status.Success && (
        <Redirect to={{ pathname: "/dashboard/menu" }} />
      )}
      <JustifyCenterContainer width="100%;">
        <TextField
          fullWidth
          label={"Email"}
          placeholder={"Email"}
          variant={"standard"}
          type={"email"}
          onChange={(event) => {
            handleEmailChange(event.target.value);
          }}
          onKeyPress={onKeyPress}
        />
      </JustifyCenterContainer>

      <JustifyCenterContainer width="100%;">
        <TextField
          fullWidth
          variant={"standard"}
          placeholder="Password"
          label={"Password"}
          type="password"
          onChange={(event) => {
            handlePasswordChange(event.target.value);
          }}
          onKeyPress={onKeyPress}
        />
      </JustifyCenterContainer>

      <Grid container justifyContent={"center"}>
        <Grid item xs={12} md={6}>
          <Button
            onClick={handleSubmit}
            aria-label="login-button"
            data-testid="test-id-login-button"
            variant={"contained"}
            fullWidth
            sx={{ paddingTop: "8px", paddingBottom: "8px" }}
          >
            Login
          </Button>
        </Grid>
      </Grid>

      <Grid container justifyContent={"center"}>
        <Grid item xs={12} md={6}>
          <Typography textAlign={"center"} sx={{ marginTop: "16px" }}>
            OR
          </Typography>
          <form action={"/accounts/google/login/"} method="post">
            <CSRFToken />
            <Button data-testid="test-id-google-login-button" type="submit">
              <img
                src={GoogleSignInImg}
                alt="Log in with google"
                style={{ width: "100%" }}
              />
            </Button>
          </form>
        </Grid>
      </Grid>

      <JustifyCenterContainer width="100%;">
        <p>
          <HyperLink
            onClick={() => {
              window.open("reset-password/", "_self");
            }}
          >
            Forgot Password?
          </HyperLink>
        </p>
      </JustifyCenterContainer>

      <JustifyCenterContainer width="100%;">
        <div>
          Don't have an Account?
          <HyperLink
            onClick={() => {
              window.open("signup/", "_self");
            }}
          >
            Sign Up
          </HyperLink>
        </div>
      </JustifyCenterContainer>
    </Container>
  );
};
