import { Cancel } from "@mui/icons-material";
import { Box, Dialog, IconButton } from "@mui/material";
import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components";

const ItemImage = styled(LazyLoadImage)`
  display: block;
  object-fit: cover;
`;

const ItemImageExpanded = styled.img`
  display: block;
  height: auto;
  width: 100%;
  object-fit: cover;
`;

export const ItemImageComponent = ({ imageUrl }: { imageUrl?: string }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      {!!imageUrl && (
        <>
          <Dialog open={open} fullScreen sx={{ border: "1px solid red" }}>
            <Box margin={"auto"} height={"auto"} position={"relative"}>
              <IconButton
                onClick={() => setOpen(false)}
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                }}
                data-testid="test-id-img-close-button"
              >
                <Cancel
                  fontSize="large"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "50%",
                    color: "black",
                  }}
                />
              </IconButton>
              <ItemImageExpanded src={imageUrl} alt="expanded item image" />
            </Box>
          </Dialog>

          <ItemImage
            onClick={() => setOpen(true)}
            src={imageUrl}
            height={60}
            width={90}
            alt="item_image"
          />
        </>
      )}
    </>
  );
};
