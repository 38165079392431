import { TimePassed } from "../../TimePassed";
import { OrderDetails } from "./OrderDetails";
import orderNotificationAudio from "../../audio/order-notification-sound.mp3";
import { SoundNotification } from "../../sound-notification/SoundNotification";
import { DashboardOrderColumn, OrderContainer } from "../../OrderDesign";
import { SendOrderToKitchen } from "./SendOrderToKitchen";
import styled from "styled-components";
import { EditOrder } from "./EditOrder";
import { Box, Icon, Paper, Typography } from "@mui/material";
import { CompleteOrderButton } from "./CompleteOrderButton";
import { useOrderDataManager } from "./orderDataManager";
import { OrderMarkReady } from "./OrderMarkReady";
import { Paid } from "@mui/icons-material";
import { OrderType } from "../../../types/OrderType";
import React, { useEffect, useRef } from "react";
import { useItemInventory } from "./ItemInventoryProvider";

export const ConfirmAlertArgument = {
  title: "Mark as completed?",
  showCancelButton: true,
  confirmButtonColor: "rgba(75, 181, 67, 0.72)",
  cancelButtonColor: "rgba(178, 38, 38, 0.72)",
  confirmButtonText: "YES",
  cancelButtonText: "NO",
};

const OrderHeader = styled.div`
  justify-content: space-between;
  background-color: #c0e9bc;
  padding: 8px;
`;

export const Orders = () => {
  const { orders, orderStatus } = useOrderDataManager();
  const { reloadInventory } = useItemInventory();
  const isFirstFetch = useRef<boolean>(true);

  useEffect(() => {
    if (orders === undefined) return;
    if (isFirstFetch.current) {
      isFirstFetch.current = false;
      return;
    }
    reloadInventory();
  }, [reloadInventory, orders]);

  return (
    <>
      <OrderContainer height={"calc(100vh - 160px);"}>
        {(orders == undefined || orders.length == 0) && (
          <Typography variant="h4" textAlign={"center"} width={"100%"}>
            No Orders
          </Typography>
        )}

        {orders?.map((order) => (
          <React.Fragment key={order.id}>
            <Order order={order} />
          </React.Fragment>
        ))}
      </OrderContainer>

      <SoundNotification
        data={orders}
        status={orderStatus}
        soundFile={orderNotificationAudio}
      />
    </>
  );
};

function Order({ order }: { order: OrderType }) {
  return (
    <Paper
      sx={{
        marginRight: 3,
      }}
      elevation={5}
    >
      <DashboardOrderColumn>
        <OrderHeader>
          <Box display="flex" justifyContent={"space-between"}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent={"space-between"}
              marginRight={"4px"}
            >
              <Typography fontSize={"18px"}>{order.tableName}</Typography>
              <Typography fontSize={"13px"} color="#555">
                #{order.orderNumber}
              </Typography>
            </Box>

            <OrderMarkReady orderId={order.id} isReady={!!order.isReady} />

            <SendOrderToKitchen
              orderId={order.id}
              isSentToKitchen={order.isSentToKitchen}
            />
            <CompleteOrderButton order={order} />
          </Box>

          <Box
            display={"flex"}
            justifyContent={"space-between"}
            marginTop={1}
            alignItems={"center"}
          >
            <Typography fontSize={"13px"} color="#555">
              {order.customerName}
            </Typography>
            <TimePassed dateTimeISO={order.createdAt} />
          </Box>
        </OrderHeader>

        <OrderDetails orderDetails={order.orderDetails} />
        <StickyFooter>
          <Box>
            {order.isPaid === true && (
              <Icon data-testid={"test-id-order-paid-sign"} fontSize="large">
                <Paid fontSize="large" color="success" />
              </Icon>
            )}
          </Box>

          <EditOrder order={order} />
        </StickyFooter>
      </DashboardOrderColumn>
    </Paper>
  );
}

const StickyFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;

  box-sizing: border-box;
`;
