import {
  Box,
  Container,
  Paper,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { useGetClientWithAuth } from "../../../client/getClientWithAuth";
import { useMenuIdFromURL } from "../../../hooks/menuIdFromURL";
import { OrderType } from "../../../types/OrderType";
import { useMenuData } from "../../../menu-provider/MenuDataProvider";
import { orderTotalWithTaxAndDiscount } from "./orderTotalUtils";
import { CANCEL_ORDER_PAYMENT_TYPE } from "./cancel_order_payment_type";
import cloneDeep from "lodash.clonedeep";
import { useCallback, useMemo, useState } from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { OrderHistoryTable } from "./OrderHistoryTable";

export const OrderHistory = () => {
  const menuId = useMenuIdFromURL();
  const { currency } = useMenuData();

  const { data: orders, reloadData } = useGetClientWithAuth<HistoryOrderType[]>(
    generateOrderHistoryUrl(menuId, new Date())
  );

  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

  const reloadOrders = useCallback(
    (newValue: Date | null) => {
      setSelectedDate(newValue);
      if (newValue == null || isNaN(newValue.valueOf())) return;
      reloadData({
        input: generateOrderHistoryUrl(menuId, newValue),
      });
    },
    [menuId, reloadData]
  );

  const reloadOrderWithSelectedDate = useCallback(() => {
    reloadOrders(selectedDate);
  }, [reloadOrders, selectedDate]);

  return (
    <>
      <Container maxWidth="md" sx={{ paddingTop: 2 }}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"flex-end"}
          marginLeft={"auto"}
        >
          <Paper sx={{ width: "140px", padding: 1 }}>
            <PaymentTotalTypography fontSize={15}>
              Total orders
            </PaymentTotalTypography>
            <PaymentTotalTypography
              data-testid="test-id-total-orders-count"
              fontSize={15}
            >
              {orders?.length}
            </PaymentTotalTypography>
          </Paper>

          <OrdersTotal orders={orders as OrderType[]} currency={currency} />

          <DateInput value={selectedDate} onChange={reloadOrders} />
        </Box>

        <OrderHistoryTable
          orders={orders}
          currency={currency}
          reloadOrder={reloadOrderWithSelectedDate}
        />
      </Container>
    </>
  );
};

const paperStyleToday = {
  width: 160,
  padding: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const DateInput = ({
  value,
  onChange,
}: {
  value: Date | null;
  onChange: (date: Date | null) => void;
}) => {
  return (
    <Paper sx={paperStyleToday}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          label="Select Date"
          inputFormat="MM/dd/yyyy"
          value={value}
          onChange={onChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </Paper>
  );
};

const OrdersTotal = ({
  orders,
  currency,
}: {
  orders: OrderType[] | undefined;
  currency: string;
}) => {
  const ordersTotalByPaymentType = useMemo(() => {
    const totalArray = orders?.reduce((result, order) => {
      const index = result.findIndex(
        (value) => value.paymentType === order.paymentType
      );

      if (index >= 0) {
        const newTotal =
          result[index].total + parseFloat(orderTotalWithTaxAndDiscount(order));
        result[index] = { paymentType: order.paymentType, total: newTotal };
      } else {
        result.push({
          paymentType: order.paymentType,
          total: parseFloat(orderTotalWithTaxAndDiscount(order)),
        });
      }

      return result;
    }, new Array<{ paymentType: string; total: number }>());

    return totalArray;
  }, [orders]);

  const dailyOrderTotal = ordersTotalWithTax(
    orders?.filter((value) => value.paymentType !== CANCEL_ORDER_PAYMENT_TYPE)
  );

  return (
    <>
      <Paper sx={{ padding: 1 }}>
        <PaymentTotalTypography>Daily Total</PaymentTotalTypography>
        <PaymentTotalTypography>{`${currency}${dailyOrderTotal}`}</PaymentTotalTypography>
      </Paper>

      {ordersTotalByPaymentType?.map((value, index) => (
        <Paper sx={{ padding: 1 }} key={index}>
          <PaymentTotalTypography>
            {capitalizeFirstLetter(value.paymentType)}
          </PaymentTotalTypography>
          <PaymentTotalTypography fontSize={15} textAlign="center">
            {`${currency}${value.total.toFixed(2)}`}
          </PaymentTotalTypography>
        </Paper>
      ))}
    </>
  );
};

const startAndEndOfDay = (date: Date) => {
  const start = cloneDeep(date);
  start.setHours(0, 0, 0, 0);

  const end = cloneDeep(date);
  end.setHours(23, 59, 59, 999);
  return { startOfDay: start.toISOString(), endOfDay: end.toISOString() };
};

const ordersTotalWithTax = (orders: OrderType[] | undefined) => {
  if (orders === undefined) return "0";
  return orders
    .reduce(
      (partialSum, value) =>
        partialSum + parseFloat(orderTotalWithTaxAndDiscount(value)),
      0
    )
    .toFixed(2);
};

function generateOrderHistoryUrl(menuId: string, newValue: Date) {
  return `/api/menu/${menuId}/orders/history?date_start=${
    startAndEndOfDay(newValue).startOfDay
  }&date_end=${startAndEndOfDay(newValue).endOfDay}`;
}

function capitalizeFirstLetter(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

const PaymentTotalTypography = styled(Typography)({
  textAlign: "center",
});

export type HistoryOrderType = {
  id: number;
  createdAt: string;
  tableName: string;
  customerName: string;
  customerPhone: string;
  orderDetails: HistoryOrderDetail[];
  paymentType: string;
  isSentToKitchen: boolean;
  taxPercentage: number;
  discount: number;
  bucket: number;
  customerAddress: string;
  isReady: false;
  orderNumber: number;
  isItemWiseTax?: boolean;
  isSoftDeleted: boolean;
  serviceCharge: number;
};

export type HistoryOrderDetail = {
  itemId: number;
  itemName: string;
  quantity: number;
  note: string;
  itemPrice: number;
  bucket: number;
  isPaid: boolean;
  tax: number;
};
