import { Route, Switch } from "react-router-dom";
import styled from "styled-components";
import { SignUpSuccess } from "../SignUpSuccess";
import { LoginForm } from "./authentication/LoginForm";
import { CreateMenu } from "./CreateMenu";
import { DashboardLanding } from "./DashboardLanding";
import { DashboardHeader } from "./Header";
import { AccessibleChildOrNotFound } from "./menuSpecificDashboard/AccessibleChildOrNotFound";
import { MenuListProvider } from "./menuSpecificDashboard/MenuListProvider";
import { MenuSpecificDashboardRoutes } from "./menuSpecificDashboard/MenuSpecificDashboardRoutes";
import { PrivateRoute } from "./PrivateRoute";
import { UserMenuList } from "./UserMenuList";

const Wrapper = styled.div`
  height: 100vh;
  background-color: rgba(206, 217, 216, 1);
`;

export const Dashboard = () => {
  return (
    <Wrapper>
      <Switch>
        <Route path="/dashboard/menu/:menuId">
          <MenuListProvider>
            <AccessibleChildOrNotFound>
              <MenuSpecificDashboardRoutes />
            </AccessibleChildOrNotFound>
          </MenuListProvider>
        </Route>

        <Route path="/login">
          <DashboardHeader />
          <LoginForm />
        </Route>

        <PrivateRoute path="/dashboard/menu">
          <DashboardHeader />
          <UserMenuList />
        </PrivateRoute>

        <PrivateRoute path="/dashboard/create-menu">
          <DashboardHeader />
          <CreateMenu />
        </PrivateRoute>

        <Route path={"/sign-up/success/:token"}>
          <SignUpSuccess />
        </Route>

        <Route path="/">
          <DashboardHeader />
          <DashboardLanding />
        </Route>
      </Switch>
    </Wrapper>
  );
};
