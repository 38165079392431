import React from "react";
import { Box, Typography } from "@mui/material";
import { PriceInput } from "../../PriceInput";

export const CashCalculator = ({
  orderTotal,
  currency,
  onPaidAmountChange,
}: {
  orderTotal: string;
  currency: string;
  onPaidAmountChange: (note: string, change: string | undefined) => void;
}) => {
  const [cashValue, setCashValue] = React.useState("");

  return (
    <Box data-testid="test-id-cash-calculator">
      <Box display="flex" justifyContent={"space-between"}>
        <Typography width="30%" fontSize={18}>
          Cash:
        </Typography>
        <PriceInput
          value={cashValue}
          onChange={(value) => {
            setCashValue(value);
            onPaidAmountChange(value, calculateChange(value, orderTotal));
          }}
          placeholder="0.0"
          style={{
            width: "68%",
            textAlign: "right",
            fontSize: "18px",
            outline: "none",
            border: "none",
            borderBottom: "1px solid rgba(0, 0, 0, 0.5)",
          }}
        />
      </Box>

      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography width="30%" fontSize={18}>
          Change:
        </Typography>

        <Typography
          data-testid="test-id-order-change"
          width="50%"
          textAlign={"right"}
          fontSize={18}
        >
          {currency}
          {calculateChange(cashValue, orderTotal)}
        </Typography>
      </Box>

      <Box display="flex" justifyContent={"space-between"}>
        <Typography width="30%" fontSize={18}>
          Due:
        </Typography>
        <Typography
          data-testid="test-id-order-due"
          width="50%"
          textAlign={"right"}
          fontSize={18}
        >
          {currency}
          {calculateDue(cashValue, orderTotal)}
        </Typography>
      </Box>
    </Box>
  );
};

const calculateChange = (cashStr: string, totalStr: string) => {
  const cash = parseFloat(cashStr);
  const total = parseFloat(totalStr);

  if (cash > total) {
    return (cash - total).toFixed(2);
  } else if (cash <= total || isNaN(cash)) return (0.0).toFixed(1);
};

const calculateDue = (cashStr: string, totalStr: string) => {
  const cash = parseFloat(cashStr);
  const total = parseFloat(totalStr);

  if (total > cash) return (total - cash).toFixed(2);
  else if (total <= cash) return (0.0).toFixed(1);
  else if (isNaN(cash)) return total.toFixed(2);
};
