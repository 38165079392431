import styled from "styled-components";

export const TableNameWithTimeRow = styled("div")<{ bgColor?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 0px;

  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : "rgba(251, 0, 0, 0.27);"};
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
  width: 100%;
  box-sizing: border-box;
`;
export const TableName = styled("div")<{ fontSize?: string }>`
  padding-right: 2px;
  margin: 8px 0;
  margin-left: 2px;
  color: rgba(55, 55, 55, 1);
  font-weight: 400;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "1.5em;")};
  font-family: Raleway;
  overflow-wrap: break-word;
  hyphens: auto;
  white-space: normal;
  height: auto;
  word-wrap: break-word;
`;

export const Title = styled.div`
  margin-bottom: 12px;
  font-size: 1.7em;
  align-content: center;
  margin-top: 12px;
  text-align: center;
  overflow-wrap: break-word;
  word-wrap: break-word;
`;

export const HyperLink = styled.span`
  margin-left: 4px;
  color: rgba(19, 63, 103, 1);
  &:hover {
    color: blue;
    cursor:pointer;
  },
  
`;

export const InlineInputWrapper = styled.div`
  display: flex;
  margin-bottom: 40px;
  justify-content: space-between;
`;
