import { useEffect, useRef, useState } from "react";
import { useAuthentication } from "../../../../../auth-provider/AuthProvider";
import { Status } from "../../../../../client/status";

export const useUploadImageClient = (itemId: number) => {
  const { token } = useAuthentication();
  const [status, setStatus] = useState<Status>(Status.NotStarted);
  const mounted = useRef(true);

  useEffect(() => {
    return function cleanup() {
      mounted.current = false;
    };
  }, []);

  return {
    uploadItemImage: async (imageFile: File) => {
      const data = new FormData();
      data.append("file", imageFile);

      setStatus(Status.Pending);

      const response = await fetch(`/api/item/${itemId}/upload-image/`, {
        method: "post",
        headers: {
          Authorization: "Token " + token,
        },
        body: data,
      });

      if (response.status == 200) {
        mounted.current && setStatus(Status.Success);
        return;
      }

      mounted.current && setStatus(Status.Error);
    },

    status: status,
  };
};
