import styled from "styled-components";
import Swal from "sweetalert2";
import { useKdsStations } from "./kdsStationsProvider";

const ConfirmAlertArgument = {
  title: "Confirm Delete Station?",
  showCancelButton: true,
  confirmButtonColor: "rgba(75, 181, 67, 0.72)",
  cancelButtonColor: "rgba(178, 38, 38, 0.72)",
  confirmButtonText: "YES",
  cancelButtonText: "NO",
};

const StationDeleteButton = styled.button`
  display: block;
  font-size: 22px;
  cursor: pointer;
  background-color: transparent;
  border: none;
`;

export const DeleteKdsStation = ({ stationId }: { stationId: number }) => {
  const { deleteStation } = useKdsStations();

  const deleteKdsStation = () => {
    Swal.fire(ConfirmAlertArgument).then((result) => {
      if (result.isConfirmed) {
        deleteStation(stationId);
      }
    });
  };

  return (
    <StationDeleteButton
      onClick={deleteKdsStation}
      data-testid="test-is-delete-station-btn"
    >
      &#x2715;
    </StationDeleteButton>
  );
};
