import { ReactNode, useEffect, useRef, useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
`;

export const DoubleClickComponent = ({
  children,
  onSingleClick,
  onDoubleClick,
  onDoubleClickTimeOut,
}: {
  children: ReactNode;
  onSingleClick: () => void;
  onDoubleClick: () => void;
  onDoubleClickTimeOut: () => void;
}) => {
  const [isClickedBefore, setIsClickedBefore] = useState<boolean>(false);
  const mounted = useRef(true);

  const doubleClickTimeOut = () => {
    setTimeout(() => {
      mounted.current && setIsClickedBefore(false);
      mounted.current && onDoubleClickTimeOut();
    }, 5000);
  };

  const handleComponentClick = () => {
    setIsClickedBefore(!isClickedBefore);

    if (isClickedBefore) {
      onDoubleClick();
      return;
    }

    onSingleClick();
    doubleClickTimeOut();
  };

  useEffect(() => {
    return function cleanup() {
      mounted.current = false;
    };
  }, []);

  return <Wrapper onClick={handleComponentClick}>{children}</Wrapper>;
};
