import { useEffect, useRef, useState } from "react";
import { useAuthentication } from "../../../auth-provider/AuthProvider";
import { Status } from "../../../client/status";
import { useMenuIdFromURL } from "../../../hooks/menuIdFromURL";

export const useAddCategoryClient = () => {
  const menuId = useMenuIdFromURL();
  const [status, setStatus] = useState<Status>(Status.NotStarted);
  const { token } = useAuthentication();
  const mounted = useRef(true);

  useEffect(() => {
    return function cleanup() {
      mounted.current = false;
    };
  }, []);

  return {
    status: status,
    saveNewCategory: async (aCategory: { categoryName: string }) => {
      setStatus(Status.Pending);
      const response = await fetch(`/api/menu/${menuId}/add-category/`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + token,
        },
        body: JSON.stringify(aCategory),
      });

      if (response.status == 201) {
        mounted.current && setStatus(Status.Success);
        return;
      }
      mounted.current && setStatus(Status.Error);
    },
  };
};
