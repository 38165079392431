import { Paper, Typography } from "@mui/material";
import { add, differenceInCalendarDays, format } from "date-fns";
import {
  ResponsiveContainer,
  AreaChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Area,
  Tooltip,
} from "recharts";
import { useMenuData } from "../../../menu-provider/MenuDataProvider";
import { DateRangeType } from "./Reports";

export const OrderTotalChart = ({
  orders,
  dateRange,
  total,
}: {
  orders: { createdAt: Date; orderTotal: number }[];
  dateRange: DateRangeType;
  total: number;
}) => {
  const { currency } = useMenuData();

  return (
    <Paper sx={{ padding: 1, marginBottom: 3 }}>
      <Typography
        sx={{ margin: 2 }}
        variant="h6"
      >{`Total Sales (${currency}${total.toFixed(2)})`}</Typography>

      <ResponsiveContainer width="100%" height={400}>
        <AreaChart
          height={400}
          data={orders}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey={"createdAt"}
            scale="time"
            type="number"
            tickFormatter={(val) => format(val, "MMM dd, yy")}
            domain={[
              dateRange.startDate.getTime(),
              dateRange.endDate.getTime(),
            ]}
            ticks={getTicks(dateRange.startDate, dateRange.endDate, 8)}
          />
          <YAxis
            tickFormatter={(val) => `${currency}${val}`}
            dataKey={"orderTotal"}
          />
          <Tooltip
            labelFormatter={(label) => format(label, "MMM dd, yyyy")}
            formatter={(value) => [
              `${currency}${(value as number).toFixed(2)}`,
              "Total sales",
            ]}
          />
          <Area
            type="linear"
            dataKey="orderTotal"
            stroke="#8884d8"
            fill="#8884d8"
            dot={true}
          />
        </AreaChart>
      </ResponsiveContainer>
    </Paper>
  );
};

const getTicks = (startDate: Date, endDate: Date, num: number) => {
  const diffDays = differenceInCalendarDays(endDate, startDate);

  const current = startDate,
    velocity = Math.round(diffDays / (num - 1));

  const ticks = [startDate.getTime()];

  for (let i = 1; i < num - 1; i++) {
    ticks.push(add(current, { days: i * velocity }).getTime());
  }

  ticks.push(endDate.getTime());
  return ticks;
};
