import React from "react";
import { Box, TextField } from "@mui/material";
import { NumericFormat } from "react-number-format";

export const DiscountInput = (props: {
  discount: string;
  isOrderPaid: boolean;
  onChange: (value: string) => void;
}) => {
  return (
    <Box marginLeft={"auto"} marginRight={"auto"} marginTop={"6px"}>
      <NumericFormat
        customInput={TextField}
        value={props.discount}
        onChange={(event) => props.onChange(event.target.value)}
        placeholder="Discount(%)"
        allowNegative={false}
        variant="standard"
        label="Discount(%)"
        size="small"
        disabled={props.isOrderPaid}
        inputProps={{ inputMode: "decimal" }}
      />
    </Box>
  );
};
