import { useEffect, useState } from "react";
import { usePlayAudio } from "../../sound-notification/playAudio";

export const HelpRequestNotificationSnooze = ({
  dataSize,
  audioFile,
}: {
  dataSize: number;
  audioFile: string;
}) => {
  const { playAudio } = usePlayAudio(audioFile);
  const [settings] = useState(() =>
    localStorage.getItem("help-request-sound-snooze")
  );

  useEffect(() => {
    const willSnooze = dataSize > 0 && settings === "ENABLE";

    if (willSnooze) {
      const intervalId = setInterval(playAudio, 30000);
      return function cleanup() {
        clearInterval(intervalId);
      };
    }
  }, [dataSize, playAudio, settings]);

  return <></>;
};
