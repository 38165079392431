import { Description } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { LAST_ORDER_MENU_ID, LAST_ORDER_ID_KEY } from "./LAST_ORDER_KEYS";

export const LastOrderButton = () => {
  const history = useHistory();
  const [menuId] = useState(() => localStorage.getItem(LAST_ORDER_MENU_ID));
  const [orderId] = useState(() => localStorage.getItem(LAST_ORDER_ID_KEY));

  return (
    <>
      {!!orderId && !!menuId && (
        <IconButton
          data-testid={"test-id-last-order-button"}
          onClick={() => history.push(`/menu/${menuId}/order/${orderId}`)}
          sx={{ color: "white", display: "flex", flexDirection: "column" }}
          size="large"
        >
          <Description fontSize="large" />
          <Typography>Last Order Status</Typography>
        </IconButton>
      )}
    </>
  );
};
