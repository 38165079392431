import { Box, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { TimePassed } from "../../TimePassed";

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  width: 100%;
  height: 100%;
  background-color: rgba(242, 241, 228, 1);
  box-sizing: border-box;
  cursor: pointer;
`;

const TableNameWrapper = styled.div`
  font-size: 24px;
  word-wrap: break-word;
  cursor: pointer;
`;
const TimeWrapper = styled.div`
  font-size: 16px;
  cursor: pointer;
`;

export const KdsOrderHeader = ({
  tableName,
  createdAt,
  orderId,
}: {
  tableName: string;
  createdAt: string;
  orderId: number;
}) => {
  return (
    <HeaderContainer>
      <TableNameWrapper>{tableName}</TableNameWrapper>

      <Box
        textAlign={"right"}
        display="flex"
        justifyContent={"space-between"}
        flexDirection="column"
      >
        <TimeWrapper>
          <TimePassed dateTimeISO={createdAt} />
        </TimeWrapper>
        <Typography fontSize={"13px"} color={"#555"}>
          #{orderId}
        </Typography>
      </Box>
    </HeaderContainer>
  );
};
