import React from "react";
import styled from "styled-components";
import {
  IconButton,
  Typography,
  styled as MStyled,
  Box,
  Chip,
} from "@mui/material";
import { AddCircle, RemoveCircle } from "@mui/icons-material";

const NoteInputField = styled.input`
  width: 100%;
  box-sizing: border-box;
  margin-top: 8px;
  background-color: rgba(0, 0, 0, 0.08);
  padding-left: 8px;
  border: none;
  height: 24px;
  margin-bottom: 8px;
  &:focus {
    outline: none;
  }
`;

const CartDescriptionLine = MStyled(Typography)({
  color: "#666",
  fontWeight: "300",
  fontSize: "13px",
  textAlign: "justify",
  width: "100%",
  boxSizing: "border-box",
  marginBottom: "8px",
});

export const CartItemEditForm = ({
  id,
  itemName,
  note,
  quantity,
  description,
  price,
  variation,
  onDecreaseItemQuantity,
  onIncreaseItemQuantity,
  currency,
  onUpdateNoteInCart,
}: CartItemEditFormProps) => {
  return (
    <Box paddingRight={"4px"} boxSizing={"border-box"} width={"100%"}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography>{itemName}</Typography>

        <Box display={"flex"} alignItems="center">
          <IconButton
            data-testid="test-id-decrease-item-quantity"
            aria-label="decrease-item-quantity"
            role="button"
            onClick={() => onDecreaseItemQuantity(id)}
          >
            <RemoveCircle />
          </IconButton>

          <Chip
            role="quantity-box"
            aria-label="quantity-box"
            variant="filled"
            label={quantity}
            size={"medium"}
          />

          <IconButton
            data-testid="test-id-increase-item-quantity"
            aria-label="increase-item-quantity"
            role="button"
            onClick={() => onIncreaseItemQuantity(id)}
          >
            <AddCircle />
          </IconButton>

          <Typography width={"60px"} align="right">
            {currency}
            {price}
          </Typography>
        </Box>
      </Box>

      {!!variation && (
        <Box display="flex">
          <Typography fontWeight={"bold"} sx={{ marginRight: "4px" }}>
            Variation:
          </Typography>
          <Typography>{variation}</Typography>
        </Box>
      )}
      <NoteInputField
        onChange={(event) => onUpdateNoteInCart(id, event.target.value)}
        value={note}
        placeholder="Add note"
      />

      <CartDescriptionLine>{description}</CartDescriptionLine>
    </Box>
  );
};

type CartItemEditFormProps = {
  id: number;
  itemName: string;
  note: string;
  quantity: number;
  description?: string;
  price: number;
  currency: string;
  variation?: string;
  onDecreaseItemQuantity: (id: number) => void;
  onIncreaseItemQuantity: (id: number) => void;
  onUpdateNoteInCart: (id: number, note: string) => void;
};
