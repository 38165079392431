import { Button, Grid, Paper } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";

export const MenuNameList = ({
  menus = [],
}: {
  menus: MenuForListType[] | undefined;
}) => {
  const history = useHistory();
  const handleMenuNameClick = (menu: MenuForListType) => {
    const url = `/dashboard/menu/${menu.id}/orders`;
    history.push({
      pathname: url,
      state: menu,
    });
  };

  return (
    <Grid container spacing={2} justifyContent={"space-evenly"}>
      {menus.map((menu: MenuForListType, index: number) => {
        return (
          <Grid key={index} item xs={12} md={6}>
            <Paper elevation={12}>
              <Button
                onClick={() => handleMenuNameClick(menu)}
                fullWidth
                size={"large"}
              >
                {menu.restaurantName}
              </Button>
            </Paper>
          </Grid>
        );
      })}
    </Grid>
  );
};

export type MenuForListType = { id: number; restaurantName: string };
