import { Button } from "@mui/material";
import { useMenuIdFromURL } from "../../../hooks/menuIdFromURL";

export const CustomerFacingMenuLink = () => {
  const menuId = useMenuIdFromURL();
  const menuUrl = `/menu/${menuId}`;
  return (
    <Button
      size="small"
      variant="contained"
      onClick={() => {
        window.open(menuUrl, "new");
      }}
    >
      Preview menu
    </Button>
  );
};
