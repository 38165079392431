import { DashboardCategories } from "./dashboardMenu/DashboardCategories";
import { AddItem } from "./AddItem";
import { AddCategory } from "./AddCategory";
import { CustomerFacingMenuLink } from "./CustomerFacingMenuLink";
import React, { useEffect, useMemo, useState } from "react";
import { UpdateCurrency } from "./dashboardMenu/UpdateCurrency";
import {
  PaperScroller,
  VerticalScrolls,
} from "../../../design-system/Container";
import { CategoryType } from "../../../client/menuClient";
import { useMenuData } from "../../../menu-provider/MenuDataProvider";
import { CategoryItems } from "./dashboardMenu/CategoryItems";
import { Box, Divider, Typography } from "@mui/material";
import { CategoryItemEnableDisable } from "./dashboardMenu/CategoryItemEnableDisable";

export const UpdateMenu = (): JSX.Element => {
  const { categories, restaurantName, currency, phone, isItemWiseTaxEnabled } =
    useMenuData();
  const [selectedCategory, setSelectedCategory] = useState<
    CategoryType | undefined
  >(categories[0]);

  const categoryItems = useMemo(() => {
    if (selectedCategory == undefined) return [];
    if (selectedCategory?.items == undefined) return [];

    return selectedCategory?.items;
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedCategory == undefined && categories.length >= 1) {
      setSelectedCategory(categories[0]);
      return;
    }

    const newCategory = categories.find(
      (category) => category.id === selectedCategory?.id
    );

    if (JSON.stringify(newCategory) !== JSON.stringify(selectedCategory)) {
      setSelectedCategory(newCategory);
    }
  }, [categories, selectedCategory]);

  return (
    <Box
      bgcolor={"rgb(245, 245, 245)"}
      height={"calc(100vh - 60px)"}
      padding={1}
      boxSizing={"border-box"}
      borderRadius={2}
    >
      <Box
        display={"flex"}
        justifyContent="space-between"
        alignItems={"center"}
        paddingBottom={1}
      >
        <Box
          display={"flex"}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Box display="flex" alignItems={"center"}>
            <Typography>{restaurantName}</Typography>
          </Box>
          <Typography sx={{ marginLeft: "8px", marginRight: "8px" }}>
            -
          </Typography>
          <Box display="flex" alignItems={"center"} marginRight={1}>
            <Typography>{phone}</Typography>
          </Box>
        </Box>
        <Box display={"flex"} justifyContent={"space-between"}>
          <UpdateCurrency />
          <CustomerFacingMenuLink />
        </Box>
      </Box>

      <Box display={"flex"} height={"calc(100% - 50px)"}>
        <Box width={"30%"}>
          <VerticalScrolls>
            <DashboardCategories
              onSelectedCategoryChange={setSelectedCategory}
              categories={categories}
              selectedCategory={selectedCategory}
            />
            <AddCategory />
          </VerticalScrolls>
        </Box>

        <Divider orientation="vertical" />

        <Box width={"70%"}>
          <PaperScroller variant={"outlined"}>
            <Box padding={1}>
              {selectedCategory !== undefined && (
                <CategoryItemEnableDisable category={selectedCategory} />
              )}
              <CategoryItems
                currency={currency}
                categoryItems={categoryItems}
                isItemWiseTaxEnabled={isItemWiseTaxEnabled}
              />
              <AddItem categoryId={selectedCategory?.id} />
            </Box>
          </PaperScroller>
        </Box>
      </Box>
    </Box>
  );
};
