import { Link } from "react-router-dom";
import styled from "styled-components";
import { Grid, Paper } from "@mui/material";

export const Container = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SingleColumnContainer = styled("div")<{
  width: string;
  height?: string;
  marginTop?: string;
  backgroundColor?: string;
}>`
  width: ${(props) => props.width};
  margin-left: auto;
  margin-right: auto;
  height: ${(props) => (props.height ? props.height : "400px;")}
  margin-top: ${(props) => props.marginTop};
  margin-bottom: 15px;
  background-color:${(props) =>
    props.backgroundColor ? props.backgroundColor : "transparent;"}
`;

export const JustifyCenterContainer = styled("div")<{ width: string }>`
  display: flex;
  justify-content: center;
  width: ${(props) => props.width};
  padding-bottom: 20px;
  margin-bottom: 0px;
`;

export const PlainWrapText = styled.div`
  overflow-wrap: break-word;
  hyphens: auto;
`;

export const VerticalScrolls = styled("div")<{
  width?: string;
  height?: string;
}>`
  box-sizing: border-box;
  width: ${(props) => (props.width ? props.width : "100%;")};
  height: ${(props) => (props.height ? props.height : "100%;")};
  overflow-y: scroll;
  & {
    scrollbar-width: thin;
    scrollbar-color: rgb(169, 169, 169) transparent;
  }

  &::-webkit-scrollbar {
    width: 2px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: rgb(169, 169, 169);
  }
`;

export const GridScroller = styled(Grid)<{
  width?: string;
  height?: string;
}>`
  height: 100%;
  overflow-y: scroll;
  & {
    scrollbar-width: thin;
    scrollbar-color: rgb(169, 169, 169) transparent;
  }

  &::-webkit-scrollbar {
    width: 2px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: rgb(169, 169, 169);
  }
`;

export const PaperScroller = styled(Paper)<{
  width?: string;
  height?: string;
}>`
  height: 100%;
  overflow-y: scroll;
  & {
    scrollbar-width: thin;
    scrollbar-color: rgb(169, 169, 169) transparent;
  }

  &::-webkit-scrollbar {
    width: 2px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: rgb(169, 169, 169);
  }
`;

export const drawerItemStyles = `
margin-bottom: 12px;
font-size: 24px;
display: block;
text-decoration: none;
color: black;
padding-left: 24px;
padding-top: 4px;
padding-bottom: 4px;
&:hover {
  background-color: rgb(208, 235, 235);
}
`;

export const DrawerItem = styled(Link)`
  ${drawerItemStyles}
`;

export const RowContainer = styled("div")<{
  width?: string;
  height?: string;
  alignItems?: string;
}>`
  display: flex;
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "100%")};
  align-items: ${(props) => (props.alignItems ? props.alignItems : "center")};
`;
