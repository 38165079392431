import { useContext, useEffect } from "react";
import { usePostClientWithAuth } from "../../../../client/postClientWithAuth";
import { ItemType } from "../../../../client/menuClient";
import { Status } from "../../../../client/status";
import { useMenuData } from "../../../../menu-provider/MenuDataProvider";
import { DashboardItemContext } from "./DashboardItem";
import { DISABLE_ITEM_BG, ENABLE_ITEM_BG } from "./DASHBOARD_ITEM_BG";

export const ItemEnableDisable = ({ item }: { item: ItemType }) => {
  const { postData: updateItemStatus, status } = usePostClientWithAuth(
    `/api/item/${item.id}/enable-disable/`
  );
  const { reloadMenuData } = useMenuData();

  const { setItemBackgroundColor } = useContext(DashboardItemContext);

  const updateItem = (event: React.ChangeEvent<HTMLInputElement>) => {
    const bgColor = event.target.checked ? ENABLE_ITEM_BG : DISABLE_ITEM_BG;
    setItemBackgroundColor(bgColor);
    updateItemStatus({ isEnabled: event.target.checked });
  };

  useEffect(() => {
    status == Status.Success && reloadMenuData();
  }, [reloadMenuData, status]);

  return (
    <div style={{ paddingTop: "4px" }}>
      <input
        type="checkbox"
        checked={item.isEnabled}
        data-testid="test-id-item-enable-checkbox"
        onChange={updateItem}
      />
    </div>
  );
};
