import {
  Box,
  Button,
  Paper,
  styled,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { QRCodeCanvas } from "qrcode.react";
import { useState } from "react";
import { useMenuIdFromURL } from "../../../hooks/menuIdFromURL";

const MenuTab = styled(Tab)({
  border: "1px solid #9b9b9b8c",
  marginRight: "8px",
});

export const QRGeneration = () => {
  const menuId = useMenuIdFromURL();
  const [selectedMenuOption, setSelectedMenuOption] = useState(QROptions[0]);
  const [tableNameInput, setTableNameInput] = useState("");

  const updateTableName = (newValue: string) => {
    const cleanedValue = newValue.split(" ").join("");
    setTableNameInput(cleanedValue);
  };

  const downloadQR = () => {
    const canvas = document.getElementById("qr-code-id") as HTMLCanvasElement;
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${tableNameInput}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <Paper
      sx={{
        height: "calc(100vh - (70px))",
        padding: "16px",
        margin: "8px 8px 8px 8px",
        boxSizing: "border-box",
      }}
    >
      <Box display={"flex"}>
        <Box width={"312px"}>
          <Box border={"1px solid #9b9b9b8c"} padding={2}>
            <QRCodeCanvas
              id="qr-code-id"
              value={generateMenuUrl(tableNameInput, menuId)}
              size={280}
              level={"H"}
              includeMargin={false}
              data-testid="test-id-qr-code"
            />
          </Box>
        </Box>

        <Box marginLeft={2} width={"calc(100% - 340px)"}>
          <Button
            onClick={() => {
              window.open(generateMenuUrl(tableNameInput, menuId), "new");
            }}
            sx={{ textTransform: "none", marginBottom: "8px" }}
          >
            {generateMenuUrl(tableNameInput, menuId)}
          </Button>
          <Typography sx={{ marginBottom: "8px", height: "60px" }}>
            {selectedMenuOption.text}
          </Typography>

          <Box height={"60px"} marginBottom="8px">
            {selectedMenuOption.label === QRLabel.Custom && (
              <TextField
                value={tableNameInput}
                onChange={(event) => updateTableName(event.target.value)}
                placeholder={"table name"}
                sx={{ width: "100%" }}
              />
            )}
          </Box>

          <Tabs
            value={selectedMenuOption}
            onChange={(_, newValue) => {
              setSelectedMenuOption(newValue);
              setTableNameInput(newValue.tableNameType);
            }}
            sx={{ marginBottom: "16px" }}
            variant="scrollable"
          >
            {QROptions.map((value, index) => (
              <MenuTab label={value.label} value={value} key={index} />
            ))}
          </Tabs>

          <Button variant="contained" onClick={downloadQR}>
            Download QR Code
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

const QROptions = [
  {
    label: "Base menu",
    text: "This will have base menu link which does not have any table args and customer will not be able to order or ask for host.",
    tableNameType: "",
  },
  {
    label: "Pick-up",
    text: "This will have 'Pick-up' table args and you will able to use it for taking pick up orders.",
    tableNameType: "Pick-up",
  },
  {
    label: "Delivery",
    text: "This will have 'Delivery' table args and you will able to use it for taking delivery orders.",
    tableNameType: "Delivery",
  },
  {
    label: "Custom",
    text: "This will have custom table args and you will able to use it to identify the tables of your restaurants.",
    tableNameType: "",
  },
];

const generateMenuUrl = (tableName: string, menuId: string) => {
  if (tableName === "") return `https://m.orderit.today/menu/${menuId}`;
  return `https://m.orderit.today/menu/${menuId}?t=${tableName}`;
};

enum QRLabel {
  Base = "Base menu",
  PickUp = "Pick-up",
  Delivery = "Delivery",
  Custom = "Custom",
}
