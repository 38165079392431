import React, { useState } from "react";

export const PriceInput = ({
  value,
  onChange,
  onKeyPress,
  style,
  disabled,
  placeholder,
}: PriceInputProps) => {
  const [price, setPrice] = useState<string>("");

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const inputValue = event.target.value;
    let numberOnly = "";

    for (const char of inputValue) {
      const isDigit = "0" <= char && char <= "9";
      const isDecimal = char == ".";
      const isFirstDecimal = numberOnly.indexOf(".") == -1;

      if (isDigit || (isDecimal && isFirstDecimal)) {
        numberOnly = numberOnly.concat(char);
      }
    }

    setPrice(numberOnly);
    onChange(numberOnly);
  };

  return (
    <>
      <input
        placeholder={placeholder ? placeholder : "Price"}
        value={value !== undefined ? value : price}
        onChange={handleChange}
        onKeyPress={(event) => {
          onKeyPress && onKeyPress(event);
        }}
        style={style}
        disabled={disabled}
        inputMode="decimal"
      />
    </>
  );
};

type PriceInputProps = {
  value?: string;
  onChange: (price: string) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  style?: React.CSSProperties;
  disabled?: boolean;
  placeholder?: string;
};
