import { useAuthentication } from "../../../auth-provider/AuthProvider";
import { useFetch } from "../../../client/useFetch";

export const usePostResolveOrderClient = () => {
  const { token } = useAuthentication();
  const { status, trigger } = useFetch(
    () => ({
      init: {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + token,
        },
      },
    }),
    [token],
    { autoTrigger: false, acceptedStatus: 200, parseJson: false }
  );
  return {
    resolveOrder: (
      orderId: number,
      data: { paymentType: string; discount: number; bucket?: string },
      lastPath?: string
    ) => {
      const uri = !lastPath ? "resolve-order" : lastPath;
      trigger({
        body: data,
        input: `/api/order/${orderId}/action/${uri}/`,
      });
    },
    requestStatus: status,
  };
};
