import { startOfDay } from "date-fns";
import { useMemo } from "react";
import { ReportOrderType } from "./Reports";

export function useProcessedOrderTotals(orders: ReportOrderType[] | undefined) {
  return useMemo(() => {
    if (orders == undefined || orders.length == 0)
      return {
        orders: [],
        total: 0,
        totalTax: 0,
        totalDiscount: 0,
        itemsTotal: 0,
      };

    let total = orders[0].orderTotal;
    let totalTax = orders[0].tax;
    let totalDiscount = orders[0].discount;
    let itemsTotal = orders[0].itemsTotal;

    const newChartList = [
      {
        createdAt: startOfDay(new Date(orders[0].createdAt)),
        orderTotal: orders[0].orderTotal,
      },
    ];
    let currentIndex = 0;

    for (let i = 1; i < orders.length; i++) {
      total = total + orders[i].orderTotal;
      totalTax = totalTax + orders[i].tax;
      totalDiscount = totalDiscount + orders[i].discount;
      itemsTotal = itemsTotal + orders[i].itemsTotal;

      const indexDate = startOfDay(new Date(orders[i].createdAt));
      if (
        indexDate.getTime() === newChartList[currentIndex].createdAt.getTime()
      ) {
        newChartList[currentIndex].orderTotal =
          newChartList[currentIndex].orderTotal + orders[i].orderTotal;
      } else {
        newChartList.push({
          createdAt: indexDate,
          orderTotal: orders[i].orderTotal,
        });
        currentIndex = currentIndex + 1;
      }
    }

    return {
      orders: newChartList,
      total: total,
      totalTax,
      totalDiscount,
      itemsTotal,
    };
  }, [orders]);
}
