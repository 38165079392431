import { useEffect, useRef, useState } from "react";
import { useAuthentication } from "../../../../../auth-provider/AuthProvider";
import { Status } from "../../../../../client/status";

export const useUpdateItemClient = (itemId: number) => {
  const [status, setStatus] = useState<Status>(Status.NotStarted);
  const { token } = useAuthentication();
  const mounted = useRef(true);

  useEffect(() => {
    return function cleanup() {
      mounted.current = false;
    };
  }, []);

  return {
    updateItem: async (itemDetails: ItemUpdateType) => {
      setStatus(Status.Pending);
      const response = await fetch(`/api/item/${itemId}/update-description/`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + token,
        },
        body: JSON.stringify(itemDetails),
      });

      if (response.status == 200) {
        mounted.current && setStatus(Status.Success);
        return;
      }
      mounted.current && setStatus(Status.Error);
    },
    status: status,
  };
};

type ItemUpdateType = {
  name: string;
  price: number | "" | ".";
  description: string;
  tax?: number;
};
