import { useCallback, useState } from "react";
import { OrderType } from "../../../types/OrderType";
import { useMenuData } from "../../../menu-provider/MenuDataProvider";
import cloneDeep from "lodash.clonedeep";
import { OrderDetail } from "../../../types/OrderDetail";
import { useItemInventory } from "./ItemInventoryProvider";
import { useAlert } from "react-alert";

export const useOrderEditManager = (order: OrderType) => {
  const alert = useAlert();
  const { categories } = useMenuData();
  const { decrementItemQuantity, incrementItemQuantity, getItemQuantity } =
    useItemInventory();

  const [orderData, setOrderData] = useState(cloneDeep(order));

  const updateNote = (itemId: number, note: string) => {
    const temOrderDetails = [...orderData.orderDetails];
    const updatedNoteIndex = temOrderDetails.findIndex(
      (value) => value.itemId === itemId
    );

    temOrderDetails[updatedNoteIndex].note = note;

    const updatedOrder = { ...orderData };
    updatedOrder.orderDetails = temOrderDetails;
    setOrderData(updatedOrder);
  };

  const increaseItemQuantity = (itemId: number) => {
    if (order.isPaid) return;

    if (getItemQuantity(itemId) <= 0 && getItemQuantity(itemId) !== false) {
      alert.error("Item out of stock.");
      return;
    }

    const temOrderDetails = [...orderData.orderDetails];

    const updatedItemIndex = temOrderDetails.findIndex(
      (value) => value.itemId === itemId
    );

    if (updatedItemIndex === -1) {
      const allItems = categories.flatMap((category) => category.items);
      const itemIndex = allItems.findIndex((item) => item.id === itemId);
      const item = allItems[itemIndex];
      const orderDetail = {
        itemId: item.id,
        itemName: item.name,
        quantity: 1,
        note: "",
        itemPrice: item.price,
        variation: item.variation,
      } as OrderDetail;

      temOrderDetails.push(orderDetail);
    } else temOrderDetails[updatedItemIndex].quantity += 1;

    const updatedOrder = { ...orderData };
    updatedOrder.orderDetails = temOrderDetails;

    setOrderData(updatedOrder);
    decrementItemQuantity(itemId);
  };

  const decreaseItemQuantity = (itemId: number) => {
    const updatedOrder = { ...orderData };

    const temOrderDetails = [...orderData.orderDetails];
    const updatedItemIndex = temOrderDetails.findIndex(
      (value) => value.itemId === itemId
    );
    if (temOrderDetails[updatedItemIndex].quantity === 1) {
      const filteredOrderDetails = temOrderDetails.filter(
        (value) => !(value.itemId === itemId)
      );

      updatedOrder.orderDetails = filteredOrderDetails;
      setOrderData(updatedOrder);
      return;
    }
    temOrderDetails[updatedItemIndex].quantity -= 1;

    updatedOrder.orderDetails = temOrderDetails;
    setOrderData(updatedOrder);
    incrementItemQuantity(itemId);
  };

  const resetOrder = useCallback(() => {
    setOrderData(cloneDeep(order));
  }, [order]);

  return {
    order: orderData,
    setOrder: (aOrder: OrderType) => {
      setOrderData(aOrder);
    },
    updateNote: updateNote,
    increaseItemQuantity: increaseItemQuantity,
    decreaseItemQuantity: decreaseItemQuantity,
    setTableName: (value: string) => {
      const updatedOrder = { ...orderData };
      updatedOrder.tableName = value;
      setOrderData(updatedOrder);
    },
    setCustomerName: (value: string) => {
      const updatedOrder = { ...orderData };
      updatedOrder.customerName = value;
      setOrderData(updatedOrder);
    },
    setCustomerPhone: (value: string) => {
      const updatedOrder = { ...orderData };
      updatedOrder.customerPhone = value;
      setOrderData(updatedOrder);
    },
    setAddress: (value: string) => {
      const updatedOrder = { ...orderData };
      updatedOrder.customerAddress = value;
      setOrderData(updatedOrder);
    },
    resetOrder,
  };
};

export const DEFAULT_BUCKET_VALUE = 1;
