import { useAuthentication } from "../../../auth-provider/AuthProvider";
import { useFetch } from "../../../client/useFetch";

export const useDeleteStationClient = () => {
  const { token } = useAuthentication();

  const { status, trigger } = useFetch(
    () => ({
      init: {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + token,
        },
      },
    }),
    [token],
    { autoTrigger: false, acceptedStatus: 200, parseJson: false }
  );
  return {
    deleteStation: (stationId: number) =>
      trigger({
        input: `/api/station/${stationId}/delete/`,
      }),
    status: status,
  };
};
