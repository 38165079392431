import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useSendOrderClient } from "./sendOrderClient";
import { Status } from "../client/status";
import { useTableNameFromURL } from "./tableNameFromURL";
import { Box, Tab, Tabs, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { PICK_UP_ORDER_TABLE_NAME } from "../ORDER_TABLE_NAME_TYPES";
import { isEmptyOrSpacesOnly } from "../dashbord/menuSpecificDashboard/stringUtils";
import { OrderType } from "../types/OrderType";
import AddressAutoComplete from "../AddressAutoComplete";
import { Link } from "react-router-dom";
import { useMenuIdFromURL } from "../hooks/menuIdFromURL";
import { useMenuData } from "../menu-provider/MenuDataProvider";

const DELIVERY_ORDER_TABLE_NAME = "Delivery";

export const OrderConfirm = ({
  onSuccess,
}: {
  onSuccess: (aOrder: OrderType) => void;
}) => {
  const { status, order, sendOrderRequest } = useSendOrderClient();

  const [customerName, setCustomerName] = useState<string>("");
  const [phoneNo, setPhoneNo] = useState<string>("");
  const [address, setAddress] = useState("");

  const { isDeliveryEnabled, isPickupEnabled, deliveryNote } = useMenuData();

  const tableName = useTableNameFromURL();
  const menuId = useMenuIdFromURL();

  const alert = useAlert();

  const confirmOrderAction = () => {
    sendOrderRequest(customerName, phoneNo, address);
  };

  useEffect(() => {
    if (status == Status.Success) {
      order === undefined || onSuccess(order);
      return;
    }
  }, [status, onSuccess, order]);

  useEffect(() => {
    status == Status.Error && alert.error("Request failed.");
  }, [alert, status]);

  const isPlaceOrderDisabled =
    tableName == DELIVERY_ORDER_TABLE_NAME
      ? isDeliveryEnabled
        ? isEmptyOrSpacesOnly(customerName) ||
          isEmptyOrSpacesOnly(phoneNo) ||
          isEmptyOrSpacesOnly(address)
        : true
      : tableName == PICK_UP_ORDER_TABLE_NAME
      ? isEmptyOrSpacesOnly(customerName) || isEmptyOrSpacesOnly(phoneNo)
      : false;

  const showDeliveryPickupTabs =
    (tableName == DELIVERY_ORDER_TABLE_NAME ||
      tableName == PICK_UP_ORDER_TABLE_NAME) &&
    isDeliveryEnabled &&
    isPickupEnabled;

  const isDeliveryOrPickUp =
    tableName == PICK_UP_ORDER_TABLE_NAME ||
    tableName == DELIVERY_ORDER_TABLE_NAME;

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingBottom: 0,
          paddingTop: "28px",
        }}
      >
        {showDeliveryPickupTabs && (
          <Tabs value={tableName} sx={{ marginBottom: "8px" }}>
            {isDeliveryEnabled && isPickupEnabled && (
              <Tab
                component={Link}
                label={DELIVERY_ORDER_TABLE_NAME}
                value={DELIVERY_ORDER_TABLE_NAME}
                to={`/menu/${menuId}?t=Delivery`}
                sx={{
                  backgroundColor: "rgba(0, 0, 0, 0.08)",
                  border: "1px solid #9b9b9b8c",
                }}
              />
            )}
            {isDeliveryEnabled && isPickupEnabled && (
              <Tab
                component={Link}
                label={PICK_UP_ORDER_TABLE_NAME}
                value={PICK_UP_ORDER_TABLE_NAME}
                to={`/menu/${menuId}?t=Pick-up`}
                sx={{
                  backgroundColor: "rgba(0, 0, 0, 0.08)",
                  border: "1px solid #9b9b9b8c",
                }}
              />
            )}
          </Tabs>
        )}

        {isDeliveryOrPickUp && (
          <Typography
            sx={{ marginTop: 1, marginBottom: 1, fontWeight: "bold" }}
          >
            {deliveryNote}
          </Typography>
        )}

        {tableName == PICK_UP_ORDER_TABLE_NAME && (
          <Typography variant="h5">Pick-up order</Typography>
        )}
        {tableName == DELIVERY_ORDER_TABLE_NAME && (
          <Typography variant="h5">Delivery order</Typography>
        )}

        <TextField
          variant="standard"
          placeholder="Your name"
          label={isDeliveryOrPickUp ? "Your name" : "Your name(optional)"}
          onChange={(event) => {
            setCustomerName(event.target.value);
          }}
          value={customerName}
          sx={{ marginBottom: 1 }}
        />
        <TextField
          variant="standard"
          placeholder="Your phone"
          label={
            tableName == PICK_UP_ORDER_TABLE_NAME ||
            tableName == DELIVERY_ORDER_TABLE_NAME
              ? "Your phone"
              : "Your phone(optional)"
          }
          onChange={(event) => {
            setPhoneNo(event.target.value);
          }}
          value={phoneNo}
          sx={{ marginBottom: 1 }}
        />

        {tableName == DELIVERY_ORDER_TABLE_NAME && (
          <AddressAutoComplete
            address={address}
            onAddressChange={setAddress}
            variant="standard"
          />
        )}
      </Box>

      <Box sx={orderConfirmFooterDesign}>
        <LoadingButton
          onClick={confirmOrderAction}
          variant="contained"
          disabled={isPlaceOrderDisabled}
          loading={status === Status.Pending}
          data-testid="test-id-confirm-order-button"
          sx={{ width: "140px" }}
        >
          Place Order
        </LoadingButton>
      </Box>
    </Box>
  );
};

const orderConfirmFooterDesign = {
  position: "absolute",
  bottom: "8px",
  left: 0,
  right: 0,
  marginLeft: "auto",
  marginRight: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};
