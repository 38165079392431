import { IconButton, Typography } from "@mui/material";
import { useEffect } from "react";
import { useAlert } from "react-alert";
import {
  RequestForHelpStatus,
  useRequestForHelpClient,
} from "./requestForHelpClient";
import { Status } from "../client/status";
import { HandPaperIcon } from "../design-system/Icons";

export function AskForHost() {
  const { status, sendRequest } = useRequestForHelpClient();
  const alert = useAlert();

  useEffect(() => {
    switch (status) {
      case Status.Success: {
        alert.success("Someone will be there with you shortly.");
        break;
      }
      case RequestForHelpStatus.Conflict: {
        alert.success("The host has been Notified!");
        break;
      }
      case Status.Error: {
        alert.error("Sorry something went wrong!");
        break;
      }
    }
  }, [alert, status]);

  return (
    <IconButton
      onClick={sendRequest}
      aria-label="ask-host-button"
      role="button"
      sx={{ color: "white", display: "flex", flexDirection: "column" }}
      size="large"
      data-testid="test-id-ask-for-host-button"
    >
      <HandPaperIcon />
      <Typography>Ask Host</Typography>
    </IconButton>
  );
}
