import React, { useEffect, useState } from "react";
import { ItemType } from "../../../../client/menuClient";
import { DashboardItem } from "./DashboardItem";
import { Box, Divider } from "@mui/material";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult,
} from "react-beautiful-dnd";
import { DragIndicator } from "@mui/icons-material";
import { reorder } from "./reorder";
import { usePostClientWithAuth } from "../../../../client/postClientWithAuth";
import { getListItemStyle, getListStyle } from "./getListStyle";

export const CategoryItems = ({
  categoryItems,
  currency,
  isItemWiseTaxEnabled,
}: {
  categoryItems: ItemType[] | undefined;
  currency: string;
  isItemWiseTaxEnabled: boolean;
}) => {
  const [items, setItems] = useState<ItemType[]>([]);
  const { postData: updateItemOrdering } = usePostClientWithAuth(
    "/api/action/items-reorder/"
  );

  const removeItemFromCategory = (itemId: number) => {
    const filteredItems = items.filter((value) => value.id !== itemId);
    setItems(filteredItems);
  };

  useEffect(() => {
    if (categoryItems === undefined) {
      setItems([]);
      return;
    }
    setItems(categoryItems);
  }, [categoryItems]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    if (result.type === "ITEMS") {
      const reorderedItems = reorder(
        [...items],
        result.source.index,
        result.destination.index
      ) as ItemType[];
      setItems(reorderedItems);
      const reOrderedItemsWithId = reorderedItems.map((category) => ({
        id: category.id,
      }));
      updateItemOrdering(reOrderedItemsWithId);
    }
  };

  return (
    <Box>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="item-droppable" type="ITEMS">
          {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {items.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={`category-${item.id}`}
                  index={index}
                >
                  {(
                    providedDraggable: DraggableProvided,
                    snapshotDraggable: DraggableStateSnapshot
                  ) => (
                    <div
                      ref={providedDraggable.innerRef}
                      {...providedDraggable.draggableProps}
                      style={getListItemStyle(
                        snapshotDraggable.isDragging,
                        providedDraggable.draggableProps.style
                      )}
                    >
                      <Box
                        display={"flex"}
                        boxSizing={"border-box"}
                        alignItems={"center"}
                        width={"100%"}
                      >
                        <Box
                          data-testid="test-id-item-ordering-drag-btn"
                          {...providedDraggable.dragHandleProps}
                          width={"30px"}
                          paddingTop={"2px"}
                        >
                          <DragIndicator />
                        </Box>

                        <DashboardItem
                          currency={currency}
                          item={item}
                          isItemWiseTaxEnabled={isItemWiseTaxEnabled}
                          removeItemFromCategory={removeItemFromCategory}
                        />
                      </Box>
                      <Divider light={true} />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
};
