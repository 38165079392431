import { useFetch } from "../client/useFetch";
import { useAuthentication } from "../auth-provider/AuthProvider";

export const useMenuPost = () => {
  const { token, isLoggedIn } = useAuthentication();
  const getHeader = () => {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Content-Type", "application/json");
    if (isLoggedIn) {
      requestHeaders.set("Authorization", "Token " + token);
    }
    return requestHeaders;
  };

  const { trigger, status, response } = useFetch<{ id: number }, MenuType>(
    () => ({
      input: "/api/menu",
      init: {
        method: "post",
        headers: getHeader(),
      },
    }),
    [token],
    { autoTrigger: false, acceptedStatus: 201 }
  );

  return {
    setMenu: (menu: MenuType) => trigger({ body: menu }),
    status: status,
    menuId: response?.id,
  };
};

type MenuType = { restaurantName: string };
