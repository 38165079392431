import { KdsOrderComplete } from "./KdsOrderComplete";
import { useOrdersFromKds } from "./ordersFromKds";
import { KdsOrderDetails } from "./KdsOrderDetails";
import { OrderContainer } from "../../OrderDesign";
import { useKdsWipManager } from "./kdsWipManager";
import { Box, Button, IconButton, Snackbar } from "@mui/material";
import React, { createContext, useEffect, useState } from "react";
import { Close } from "@mui/icons-material";
import {
  StatusCode,
  usePostClientWithAuth,
} from "../../../client/postClientWithAuth";
import { Status } from "../../../client/status";
import { useKdsStations } from "./kdsStationsProvider";

export const KdsWipContext = createContext<ReturnType<typeof useKdsWipManager>>(
  {
    orderDetailStatus: () => {},
    shiftKdsStatus: () => {},
    clearKdsStatus: () => {},
  }
);

export const KdsStationOrders = () => {
  const { orderData, setOrderData, reloadOrders } = useOrdersFromKds();
  const { clearKdsStatus, orderDetailStatus, shiftKdsStatus } =
    useKdsWipManager();

  const [lastCompletedOrder, setLastCompletedOrder] = useState<
    { id: number } | undefined
  >();
  const { selectedStationId } = useKdsStations();
  const { postData: undoComplete, status: undoCompleteStatus } =
    usePostClientWithAuth(
      lastCompletedOrder === undefined
        ? ""
        : `/api/kds/station/${selectedStationId}/order/${lastCompletedOrder.id}/undo-complete/`,
      StatusCode.Success
    );

  const removeOrderFromList = (orderId: number) => {
    const filteredOrders = orderData.filter((order) => order.id != orderId);
    setOrderData(filteredOrders);
  };

  useEffect(() => {
    if (undoCompleteStatus == Status.Success) {
      reloadOrders();
      setLastCompletedOrder(undefined);
    }
  }, [reloadOrders, undoCompleteStatus]);

  const completeOrder = (orderId: number) => {
    setLastCompletedOrder({ id: orderId });
    const unresolvedOrders = orderData.filter((value) => value.id != orderId);
    clearKdsStatus(
      unresolvedOrders
        .flatMap((value) => value.orderDetails)
        .flatMap((value) => value.id)
    );
    removeOrderFromList(orderId);
  };

  return (
    <KdsWipContext.Provider
      value={{ orderDetailStatus, shiftKdsStatus, clearKdsStatus }}
    >
      <OrderContainer data-testid="test-id-kds-order-column">
        {orderData.map((order) => (
          <Box
            maxWidth={300}
            minWidth={300}
            height={"100%"}
            marginRight={3}
            key={order.id}
            data-testid="test-id-kds-orders"
          >
            <KdsOrderComplete
              orderId={order.id}
              orderNumber={order.orderNumber}
              onCompleteSuccess={() => completeOrder(order.id)}
              createdAt={order.createdAt}
              tableName={order.tableName}
            />

            <KdsOrderDetails orderDetails={order.orderDetails} />
          </Box>
        ))}
      </OrderContainer>

      <UndoCompleteBar
        lastCompletedOrder={lastCompletedOrder}
        setLastCompletedOrder={setLastCompletedOrder}
        undoComplete={undoComplete}
      />
    </KdsWipContext.Provider>
  );
};

const UndoCompleteBar = ({
  undoComplete,
  lastCompletedOrder,
  setLastCompletedOrder,
}: {
  lastCompletedOrder: { id: number } | undefined;
  undoComplete: (data: undefined) => void;
  setLastCompletedOrder: (val: { id: number } | undefined) => void;
}) => {
  return (
    <Snackbar
      open={lastCompletedOrder !== undefined}
      onClose={() => setLastCompletedOrder(undefined)}
      message="Completed!"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      action={
        <React.Fragment>
          <Button
            onClick={() => undoComplete(undefined)}
            sx={{ marginRight: 1 }}
          >
            Undo
          </Button>
          <IconButton
            data-testid="test-id-complete-close-button"
            onClick={() => setLastCompletedOrder(undefined)}
            sx={{ color: "white" }}
          >
            <Close />
          </IconButton>
        </React.Fragment>
      }
    />
  );
};
