import { Box, Button, Divider, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { CategoryType } from "../../../client/menuClient";
import { useMenuData } from "../../../menu-provider/MenuDataProvider";
import { CategoryTabItems } from "./CategoryTabsItems";

export const EditOrderMenu = ({
  increaseItemQuantity,
}: {
  increaseItemQuantity: (itemId: number) => void;
}) => {
  const { categories, currency } = useMenuData();
  const [selectedCategory, setSelectedCategory] = useState<
    CategoryType | false
  >(false);

  useEffect(() => {
    setSelectedCategory(categories.length > 0 ? categories[0] : false);
  }, [categories]);

  const showSelectedCategory = (
    _event: React.SyntheticEvent<Element, Event>,
    newVal: number
  ) => {
    const category = categories.find((value) => value.id == newVal);
    setSelectedCategory(category == undefined ? false : category);
  };

  return (
    <Box display={"flex"} height={"100%"}>
      <Tabs
        value={selectedCategory && selectedCategory?.id}
        onChange={showSelectedCategory}
        orientation="vertical"
        variant="scrollable"
        scrollButtons={"auto"}
        data-testid="test-id-edit-order-category-tabs"
        sx={{
          width: "30%",
          "& button:hover": {
            backgroundColor: "#dfdfdf",
            border: "none",
            borderBottom: "1px solid #dfdfdf",
          },
          "& .Mui-selected": { backgroundColor: "#dfdfdf" },
        }}
      >
        {categories.map((category, index) => (
          <Tab
            component={Button}
            data-testid="test-id-edit-order-category"
            key={`${category.name}${index}`}
            value={category.id}
            label={category.name}
            wrapped={true}
            variant={"outlined"}
            sx={{
              width: "100%",
              borderBottom: "1px solid #dfdfdf",
              fontSize: "0.9em",
            }}
          />
        ))}
      </Tabs>

      <Divider orientation="vertical" flexItem />

      {selectedCategory !== false && (
        <CategoryTabItems
          items={selectedCategory.items}
          currency={currency}
          onIncreaseItemQuantity={increaseItemQuantity}
        ></CategoryTabItems>
      )}
    </Box>
  );
};
