import React from "react";
import { Link, Redirect } from "react-router-dom";
import { Button } from "../design-system/Button";
import { useAuthentication } from "../auth-provider/AuthProvider";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - (100px));
  align-items: center;
  justify-content: center;
`;

const HyperLink = styled.a`
  color: rgba(19, 63, 103, 1);
  &:hover {
    color: blue;
    cursor: pointer;
  },
`;

const LinkWrapper = styled.div`
  margin-top: 20px;
`;

export const DashboardLanding = () => {
  const { isLoggedIn } = useAuthentication();

  return (
    <>
      {isLoggedIn && <Redirect to={"/dashboard/menu"} />}
      <Container>
        <div>
          <Button
            variant="outlined"
            component={Link}
            to="/dashboard/create-menu"
          >
            Create New Menu
          </Button>
        </div>

        <LinkWrapper>
          Don't have an Account?&nbsp;
          <HyperLink
            onClick={() => {
              window.open("signup/", "_self");
            }}
          >
            Sign Up
          </HyperLink>
        </LinkWrapper>

        <LinkWrapper>
          <HyperLink href="https://orderit.today/" target="_new">
            Learn more about Orderit
          </HyperLink>
        </LinkWrapper>
      </Container>
    </>
  );
};
