import {
  Box,
  Container,
  IconButton,
  List,
  ListItem,
  SwipeableDrawer,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { InfoCircle } from "../design-system/Icons";
import { ContainerSizeImage } from "../design-system/Image";
import { useMenuIdFromURL } from "../hooks/menuIdFromURL";
import OrderItLogo from "./img/orderit-logo.png";
import { LoginLogoutButton } from "./LoginLogoutButton";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink } from "./NavLink";

const LearnMoreHyperlink = styled.a`
  font-size: 14px;
  position: absolute;
  right: 0;
  margin-right: 20px;
  margin-top: 30px;
  color: rgba(19, 63, 103, 1);
`;

export const NavigationDrawer = () => {
  const [isDrawerShowing, setIsDrawerShowing] = useState<boolean>(false);
  const menuId = useMenuIdFromURL();

  return (
    <Box>
      <IconButton
        edge="start"
        aria-label="drawer-open"
        data-testid="test-id-open-drawer"
        onClick={() => setIsDrawerShowing(true)}
        sx={{ color: "rgb(19, 63, 103)" }}
      >
        <MenuIcon fontSize="large" />
      </IconButton>

      <SwipeableDrawer
        open={isDrawerShowing}
        onClose={() => setIsDrawerShowing(false)}
        onOpen={() => setIsDrawerShowing(true)}
      >
        <Box width={250}>
          <Container sx={{ marginTop: 2, marginBottom: 1 }}>
            <Box component={Link} to="/" data-testid="logo">
              <ContainerSizeImage src={OrderItLogo} />
            </Box>
          </Container>

          <List>
            <NavLink
              to={`/menu`}
              title={"Menu List"}
              dataTestId={"test-id-menu-list"}
            />

            {menuId && (
              <>
                <NavLink
                  to={`/dashboard/menu/${menuId}/orders`}
                  title={"Orders"}
                  dataTestId={"test-id-order-nav-button"}
                />

                <NavLink
                  to={`/dashboard/menu/${menuId}/update`}
                  title={"Update Menu"}
                  dataTestId={"test-id-update-menu-nav-button"}
                />

                <NavLink
                  to={`/dashboard/menu/${menuId}/kds`}
                  title={"KDS"}
                  dataTestId={"test-id-kds-nav-button"}
                />

                <NavLink
                  to={`/dashboard/menu/${menuId}/reports`}
                  title={"Reports"}
                  dataTestId={"test-id-reports-nav-button"}
                />

                <NavLink
                  to={`/dashboard/menu/${menuId}/inventory`}
                  title={"Inventory"}
                  dataTestId={"test-id-inventory-nav-button"}
                />
                <NavLink
                  to={`/dashboard/menu/${menuId}/queueing-display`}
                  title={"Queueing Display"}
                  dataTestId={"test-id-queueing-display-nav-button"}
                />
                <NavLink
                  to={`/dashboard/menu/${menuId}/settings`}
                  title={"Settings"}
                  dataTestId={"test-id-settings-nav-button"}
                />
                <NavLink
                  to={`/dashboard/menu/${menuId}/qr-generation`}
                  title={"QR Generation"}
                  dataTestId={"test-id-qr-generation-nav-button"}
                />
              </>
            )}

            <ListItem disablePadding>
              <LoginLogoutButton />
            </ListItem>
            <LearnMoreHyperlink href="https://orderit.today/" target="_new">
              <InfoCircle />
              Learn more
            </LearnMoreHyperlink>
          </List>
        </Box>
      </SwipeableDrawer>
    </Box>
  );
};
