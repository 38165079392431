import React, { useEffect, useState } from "react";
import { OrderType } from "../../../types/OrderType";
import { Box, Button, DialogActions, Divider } from "@mui/material";
import { useMenuData } from "../../../menu-provider/MenuDataProvider";
import { CompleteOrderButtons } from "./CompleteOrderButtons";
import cloneDeep from "lodash.clonedeep";
import { OrderSummary } from "./OrderSummary";
import { orderTotalWithTaxAndDiscount } from "./orderTotalUtils";

import { CashCalculator } from "./CashCalculator";
import { DiscountInput } from "./DiscountInput";
import { PaymentTypeToggleButton } from "./PaymentTypeToggleButton";
import { PaymentType } from "./PaymentType";

export const DEFAULT_DISCOUNT = 0;

export const CompleteOrder = ({
  order,
  onGoBack,
  onCompleteOrder,
}: {
  order: OrderType;
  onGoBack: () => void;
  onCompleteOrder: () => void;
}) => {
  const { currency } = useMenuData();
  const [paymentType, setPaymentType] = React.useState(
    getPaymentTypeFromOrder(order)
  );

  const [paidAmount, setPaidAmount] = useState<string>("0.0");
  const [changeAmount, setChangeAmount] = useState<string>();

  const [discount, setDiscount] = useState(
    !order.discount ? "" : order.discount.toString()
  );
  const [updatedOrder, setUpdatedOrder] = useState(order);

  const onPaymentTypeChange = (newAlignment: string) => {
    newAlignment !== null && setPaymentType(newAlignment);
  };

  useEffect(() => {
    const tempOrder = cloneDeep(order);
    tempOrder.discount = isNaN(parseFloat(discount))
      ? DEFAULT_DISCOUNT
      : parseFloat(discount);
    setUpdatedOrder(tempOrder);
  }, [discount, order]);

  return (
    <>
      <Box display={"flex"} margin={1} justifyContent={"space-between"}>
        <OrderSummary currency={currency} order={updatedOrder} />

        <Divider flexItem orientation="vertical" variant="middle" />

        <Box width={"45%"} padding={1} boxSizing={"border-box"}>
          <>
            <DiscountInput
              discount={discount}
              onChange={setDiscount}
              isOrderPaid={!!order.isPaid}
            />

            <CashCalculatorWithToggleButton
              currency={currency}
              paymentType={paymentType}
              order={updatedOrder}
              onPaymentTypeChange={onPaymentTypeChange}
              onPaidAmountChange={(note, change) => {
                setPaidAmount(note);
                setChangeAmount(change);
              }}
            />
          </>
        </Box>
      </Box>

      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "16px 8px 8px 8px",
        }}
      >
        <Button
          onClick={onGoBack}
          variant="contained"
          color="error"
          sx={{ height: "50px" }}
        >
          Go Back
        </Button>
        <CompleteOrderButtons
          paymentType={paymentType}
          order={updatedOrder}
          isSelectedBucketPaid={false}
          onOrderComplete={onCompleteOrder}
          paidAmount={paidAmount}
          changeAmount={changeAmount}
        />
      </DialogActions>
    </>
  );
};
function getPaymentTypeFromOrder(order: OrderType): string {
  return !order.paymentType ? PaymentType.Cash : order.paymentType;
}

function CashCalculatorWithToggleButton(props: {
  paymentType: string;
  order: OrderType;
  onPaymentTypeChange: (value: string) => void;
  currency: string;
  onPaidAmountChange: (note: string, change: string | undefined) => void;
}) {
  return (
    <Box data-testid="test-id-cash-calculator-with-toggle">
      <PaymentTypeToggleButton
        paymentType={props.paymentType}
        onPaymentTypeChange={props.onPaymentTypeChange}
        isDisabled={!!props.order.isPaid}
      />

      <Box margin={"auto"} height={80}>
        {props.paymentType == PaymentType.Cash && (
          <CashCalculator
            orderTotal={orderTotalWithTaxAndDiscount(props.order)}
            currency={props.currency}
            onPaidAmountChange={props.onPaidAmountChange}
          />
        )}
      </Box>
    </Box>
  );
}
