import React, { useEffect, useState } from "react";
import { PopUpComponent } from "../shared-components/PopUpComponent";
import Files from "react-files";
import { useUploadMenuFileClient } from "./uploadMenuFileClient";
import { Status } from "../client/status";
import { useAlert } from "react-alert";
import styled from "styled-components";
import { SlimButton } from "../design-system/Button";
import { SpinnerIcon, UploadIcon } from "../design-system/Icons";
import { Button, Typography } from "@mui/material";

const FileWrapper = styled.div`
  margin-bottom: 4px;
`;

const UploadMenuFormButton = styled(SlimButton)`
  margin-top: 10px;
`;

const ClickableSpan = styled.span`
  margin-left: 4px;
  cursor: pointer;
`;
const CenterAlignedRowsContainer = styled.div`
  display: flex;
  width: 85%;
  flex-direction: column;
  align-items: center;
  margin: auto;
`;

const PlainInputField = styled.input`
  display: block;
  background-color: rgba(227, 227, 227, 1);
  color: rgba(70, 70, 70, 1);
  font-size: 16px;
  outline: none;
  outline-style: none;
  border: none;
  width: 60%;
  align-content: center;
  padding-left: 10px;
  padding-top: 4px;
  height: 40px;
  padding-bottom: 4px;
  margin-bottom: 20px;
`;

const ChooseButton = styled.button`
  background-color: rgba(227, 227, 227, 1);
  color: rgba(70, 70, 70, 1);
  border: none;
  outline: none;
  cursor: pointer;
  height: 40px;
  width: 150px;
  font-size: 22px;
  margin-bottom: 20px;
`;

export const UploadMenu = () => {
  const [open, setOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const { uploadMenuFile, status } = useUploadMenuFileClient();
  const alert = useAlert();

  const selectFile = (files: File[]) => {
    const newSelectedFiles = [...selectedFiles, files[0]];
    setSelectedFiles(newSelectedFiles);
  };

  const removeFromSelectedFiles = (fileIndex: number) => {
    const selectedFile = selectedFiles.filter(
      (value, index) => index !== fileIndex
    );
    setSelectedFiles(selectedFile);
  };

  useEffect(() => {
    status == Status.Success &&
      alert.success("Received! Orderit team will reach out within 72 hours");
    status == Status.Error && alert.error("Request failed");
  }, [alert, status]);

  return (
    <>
      <PopUpComponent
        setOpen={setOpen}
        open={open}
        isNotCloseable={status == Status.Pending}
      >
        <CenterAlignedRowsContainer>
          <Typography variant="h6" sx={{ marginTop: "20px" }}>
            Upload files
          </Typography>
          <Typography sx={{ marginBottom: "20px", textAlign: "center" }}>
            Upload pictures or digital files of your menu and Orderit team will
            create the menu for you!
          </Typography>
          <Files onChange={selectFile} clickable>
            <ChooseButton>Choose file </ChooseButton>
          </Files>

          <PlainInputField
            placeholder="Phone number (optional)"
            onChange={(event) => setPhoneNumber(event.target.value)}
          />

          {selectedFiles.map((file, index) => {
            return (
              <React.Fragment key={index}>
                <FileWrapper>
                  {file.name}
                  <ClickableSpan
                    data-testid="test-id-remove-file-button"
                    onClick={() => removeFromSelectedFiles(index)}
                  >
                    &#10060;
                  </ClickableSpan>
                </FileWrapper>
              </React.Fragment>
            );
          })}

          {status == Status.Pending ? (
            <UploadMenuFormButton disabled={true}>
              <SpinnerIcon />
              Uploading
            </UploadMenuFormButton>
          ) : (
            <UploadMenuFormButton
              onClick={() => uploadMenuFile(selectedFiles, phoneNumber)}
              data-testid="menu-upload-submit"
            >
              Submit
            </UploadMenuFormButton>
          )}
        </CenterAlignedRowsContainer>
      </PopUpComponent>

      <Button onClick={() => setOpen(!open)} variant="outlined" size="large">
        <UploadIcon />
        &nbsp;Upload
      </Button>
    </>
  );
};
