import { OrderType } from "../../../types/OrderType";

export const orderTotalWithTaxAndDiscount = (order: OrderType) => {
  let discount = order.discount;
  if (isNaN(discount)) discount = 0;

  let totalWithTaxAndDiscount = 0;

  if (!!order.isItemWiseTax) {
    const total = order.orderDetails.reduce((partialSum, value) => {
      const tax = value.tax == undefined ? 0 : value.tax;

      return partialSum + value.quantity * value.itemPrice * (1 + tax / 100);
    }, 0);

    totalWithTaxAndDiscount = total - (total * discount) / 100;

    if (!!order.serviceCharge)
      totalWithTaxAndDiscount =
        totalWithTaxAndDiscount +
        (totalWithTaxAndDiscount * order.serviceCharge) / 100;
  } else {
    const total = order.orderDetails.reduce(
      (partialSum, value) => partialSum + value.quantity * value.itemPrice,
      0
    );
    const orderTotalWithDiscount = total - (total * discount) / 100;
    let serviceCharge = 0;
    if (!!order.serviceCharge)
      serviceCharge = (orderTotalWithDiscount * order.serviceCharge) / 100;

    const taxPercentage =
      order.taxPercentage == undefined ? 0 : order.taxPercentage;
    totalWithTaxAndDiscount =
      orderTotalWithDiscount +
      (orderTotalWithDiscount * taxPercentage) / 100 +
      serviceCharge;
  }

  return totalWithTaxAndDiscount.toFixed(2);
};

export const orderTotal = (order: OrderType) => {
  return order.orderDetails
    .reduce(
      (partialSum, value) => partialSum + value.quantity * value.itemPrice,
      0
    )
    .toFixed(2);
};

export function orderTax(order: OrderType) {
  if (order.isItemWiseTax) {
    const totalTax = order.orderDetails.reduce(
      (partialSum, value) =>
        partialSum + (value.quantity * value.itemPrice * value.tax) / 100,
      0
    );

    return totalTax.toFixed(2);
  }

  const discount = order.discount ? order.discount : 0;
  const total = order.orderDetails.reduce(
    (partialSum, value) => partialSum + value.quantity * value.itemPrice,
    0
  );

  const discountedTotal = total * (1 - discount / 100);

  return ((discountedTotal * order.taxPercentage) / 100).toFixed(2);
}

export function orderDiscount(order: OrderType) {
  let total = 0;

  if (order.isItemWiseTax) {
    total = order.orderDetails.reduce(
      (partialSum, value) =>
        partialSum + value.quantity * value.itemPrice * (1 + value.tax / 100),
      0
    );
  } else {
    total = order.orderDetails.reduce(
      (partialSum, value) => partialSum + value.quantity * value.itemPrice,
      0
    );
  }

  if (order.discount) return ((total * order.discount) / 100).toFixed(2);

  return "0.00";
}
