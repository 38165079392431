import { Delete } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useEffect } from "react";
import { usePostClientWithAuth } from "../../../client/postClientWithAuth";
import { Status } from "../../../client/status";

export function SoftDeleteButton({
  orderId,
  onSuccess,
}: {
  orderId: number;
  onSuccess: () => void;
}) {
  const { postData: softDelete, status: softDeleteStatus } =
    usePostClientWithAuth(`/api/order/${orderId}/action/soft-delete/`, 200);

  useEffect(() => {
    softDeleteStatus === Status.Success && onSuccess();
  }, [onSuccess, softDeleteStatus]);

  return (
    <IconButton
      data-testid="test-id-soft-deleteButton"
      onClick={() => {
        softDelete(undefined);
      }}
      disabled={softDeleteStatus === Status.Pending}
      color="primary"
    >
      <Delete />
    </IconButton>
  );
}
