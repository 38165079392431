import styled from "styled-components";

export const OrderContainer = styled("div")<{ height?: string }>`
  width: 96%;
  margin-top: 5px;
  height:  ${(props) => (props.height ? props.height : "85vh;")}
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 16px;
  box-sizing: border-box;
  overflow-x: auto;
  overflow-y: hidden;
`;
export const OrderColumn = styled.div`
  min-width: 300px;
  margin-right: 20px;
  height: 100%;
  max-width: 290px;
`;

export const ScrollWrapper = styled.div`
  width: 100%;
  height: calc(100% - 50px);

  background-color: rgba(246, 246, 246, 1);
  overflow-y: scroll;
  & {
    scrollbar-width: thin;
    scrollbar-color: rgb(169, 169, 169) transparent;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    width: 5px;
    background-color: rgb(169, 169, 169);
  }
`;

export const OrderListContainer = styled.div<{ marginBottom?: string }>`
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  margin-bottom: ${(props) => props.marginBottom};
`;

export const DashboardOrderColumn = styled(OrderColumn)`
  position: relative;
  background-color: rgba(246, 246, 246, 1);
  margin-right: 0;
  border-radius: 4px;
`;
