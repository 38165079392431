import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Status } from "../../../client/status";
import { useAddItemToMenuClient } from "./addItemToCategoryClient";
import { useMenuData } from "../../../menu-provider/MenuDataProvider";
import { alertOptions } from "./alertOptions";
import { Box, Button, TextField } from "@mui/material";
import { NumericFormat } from "react-number-format";

const isEmptyOrSpacesOnly = (categoryName: string) => {
  return !categoryName.replace(/\s/g, "").length;
};

export const AddItem = ({ categoryId }: { categoryId?: number }) => {
  const [itemName, setItemName] = useState<string>("");
  const [price, setPrice] = useState<string>("");

  const alert = useAlert();
  const { status, addItemToMenu } = useAddItemToMenuClient();
  const { reloadMenuData } = useMenuData();

  const isAllInputsValid = () => {
    const isInputMissing =
      categoryId == undefined ||
      isEmptyOrSpacesOnly(itemName) ||
      !price ||
      price == ".";

    return isInputMissing;
  };

  const addItem = () => {
    const priceInNumber = parseFloat(price);

    if (
      categoryId == undefined ||
      isEmptyOrSpacesOnly(itemName) ||
      isNaN(priceInNumber)
    ) {
      return;
    }

    addItemToMenu({
      categoryId: categoryId,
      itemName: itemName,
      price: priceInNumber,
    });
  };

  useEffect(() => {
    if (status == Status.Success) {
      alert.success("Your menu has been updated!", alertOptions);
      reloadMenuData();
      setItemName("");
      setPrice("");
      return;
    }

    status == Status.Error && alert.error("Request failed", alertOptions);
  }, [status, alert, reloadMenuData]);

  return (
    <Box data-testid="test-id-category-select" marginTop={3} marginBottom={3}>
      <Box
        data-testid="test-id-category-select"
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        marginBottom={1}
      >
        <TextField
          variant="standard"
          type="text"
          placeholder="Item name"
          value={itemName}
          onChange={(event) => setItemName(event.target.value)}
          sx={{ marginRight: "8px" }}
          fullWidth
        />

        <NumericFormat
          customInput={TextField}
          value={price}
          onChange={(event) => setPrice(event.target.value)}
          placeholder="Price"
          allowNegative={false}
          variant="standard"
          size="small"
          inputProps={{ inputMode: "decimal" }}
          fullWidth
        />
      </Box>
      <Button
        onClick={addItem}
        disabled={isAllInputsValid()}
        variant="contained"
      >
        Add item
      </Button>
    </Box>
  );
};
