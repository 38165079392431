import {
  Collapse,
  IconButton,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Table from "@mui/material/Table";
import { orderTotalWithTaxAndDiscount } from "./orderTotalUtils";
import { OrderReceiptPrintButton } from "./OrderReceiptPrintButton";
import { OrderDetailsTable } from "./OrderDetailsTable";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import React, { useState } from "react";
import { HistoryOrderType } from "./OrderHistory";
import { SoftDeleteButton } from "./SoftDeleteButton";
import { SoftDeleteUndoButton } from "./SoftDeleteUndoButton";
import { OrderType } from "../../../types/OrderType";

export const OrderHistoryTable = ({
  orders,
  currency,
  reloadOrder,
}: {
  orders: HistoryOrderType[] | undefined;
  currency: string;
  reloadOrder: () => void;
}) => {
  return (
    <Paper sx={{ marginTop: 2 }} data-testid="test-id-order-history-button">
      <TableContainer sx={{ maxHeight: "calc( 100vh - 220px)" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center"></TableCell>
              <TableCell align="center">ID</TableCell>
              <TableCell align="center">Table Name</TableCell>
              <TableCell align="center">Time</TableCell>
              <TableCell align="center">Payment Type</TableCell>
              <TableCell align="center">Total</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {orders?.map((order, index) => (
              <React.Fragment key={`${order.id}${index}`}>
                <OrderHistoryTableBody
                  order={order}
                  currency={currency}
                  reloadOrder={reloadOrder}
                />
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

const OrderHistoryTableBody = ({
  order,
  currency,
  reloadOrder,
}: {
  order: HistoryOrderType;
  currency: string;
  reloadOrder: () => void;
}) => {
  const [openOrderDetails, setOpenOrderDetails] = useState(false);

  return (
    <>
      <TableRow
        sx={{
          bgcolor: getBgColor(order.isSoftDeleted),
          boxSizing: "border-box",
        }}
      >
        <TableCell>
          <IconButton
            onClick={() => setOpenOrderDetails(!openOrderDetails)}
            data-testid="test-id-order-expand-button"
          >
            {openOrderDetails ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell align="center">{order.orderNumber}</TableCell>
        <TableCell align="center">{order.tableName}</TableCell>
        <TableCell align="center">
          {new Date(order.createdAt).toLocaleTimeString("en-US", {
            timeStyle: "short",
          })}
        </TableCell>

        <TableCell align="center">{order.paymentType}</TableCell>

        <TableCell align="center" data-testid="test-id-order-total-value">
          {orderTotalWithTaxAndDiscount(order as OrderType)}
        </TableCell>

        <TableCell align="center">
          <OrderReceiptPrintButton order={order as OrderType} />
          <>
            {order.isSoftDeleted ? (
              <SoftDeleteUndoButton
                onSuccess={reloadOrder}
                orderId={order.id}
              />
            ) : (
              <SoftDeleteButton orderId={order.id} onSuccess={reloadOrder} />
            )}
          </>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ padding: "0 40px 0 40px" }} colSpan={7}>
          <Collapse in={openOrderDetails} unmountOnExit>
            <OrderDetailsTable order={order as OrderType} currency={currency} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const getBgColor = (bin: boolean) =>
  bin ? "rgba(196, 196, 196, 0.3)" : "transparent";
