import { Box, Checkbox, Divider, FormControlLabel } from "@mui/material";
import { useEffect } from "react";
import { CategoryType } from "../../../../client/menuClient";
import { usePostClientWithAuth } from "../../../../client/postClientWithAuth";
import { Status } from "../../../../client/status";
import { useMenuData } from "../../../../menu-provider/MenuDataProvider";

export const CategoryItemEnableDisable = ({
  category,
}: {
  category: CategoryType;
}) => {
  const { postData: updateItemsStatus, status } = usePostClientWithAuth(
    `/api/category/${category.id}/enable-disable/`
  );
  const { reloadMenuData } = useMenuData();

  useEffect(() => {
    status == Status.Success && reloadMenuData();
  }, [reloadMenuData, status]);

  return (
    <Box bgcolor={"rgba(196, 196, 196, 0.2)"} marginBottom={2}>
      <FormControlLabel
        control={
          <Checkbox
            onChange={(event) =>
              updateItemsStatus({ isEnabled: event.target.checked })
            }
            data-testid="test-id-category-items-enable-checkbox"
            checked={
              category.items.filter((item) => !item.isEnabled).length === 0
            }
            sx={{ marginLeft: "4px" }}
          />
        }
        label="Enable/Disable all items"
      />
      <Divider />
    </Box>
  );
};
