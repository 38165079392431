import { useEffect, useState } from "react";
import { ItemType } from "../client/menuClient";
import { CartItem, useCart } from "./cart-provider/CartProvider";
import { useMenuData } from "../menu-provider/MenuDataProvider";

const getItemFromItemListWithItemId = (
  itemList: ItemType[],
  itemId: number
) => {
  const itemIndex = itemList.findIndex((item) => item.id === itemId);
  return itemList[itemIndex];
};

export const useCombinedCartAndMenuData = () => {
  const {
    itemIdAndQuantity,
    clearCart,
    increaseItemQuantity,
    removeFromCart,
    updateNoteInCart,
  } = useCart();
  const { categories, currency, taxPercentage, isItemWiseTaxEnabled } =
    useMenuData();

  const [cartItemList, setCartItemList] = useState<CartItem[]>([]);

  useEffect(() => {
    if (categories.length === 0) return;

    const allItems = categories.flatMap((category) => category.items);

    const tempCartItemList = itemIdAndQuantity.map((cartItem) => {
      const item = getItemFromItemListWithItemId(allItems, cartItem.id);
      return { item: item, quantity: cartItem.quantity, note: cartItem.note };
    });

    const cartItemsPresentInMenu = tempCartItemList.filter(
      (value) => value.item !== undefined
    );

    setCartItemList(cartItemsPresentInMenu);
  }, [itemIdAndQuantity, categories]);

  return {
    cartItemList: cartItemList,
    clearCart: clearCart,
    increaseItemQuantity: increaseItemQuantity,
    removeFromCart: removeFromCart,
    updateNoteInCart: updateNoteInCart,
    currency: currency,
    taxPercentage: taxPercentage,
    numberOfItemsInCart: cartItemList.reduce(
      (accumulated, item) => accumulated + item.quantity,
      0
    ),
    isItemWiseTaxEnabled: isItemWiseTaxEnabled,
  };
};
