import React, { useContext, useMemo } from "react";
import styled from "styled-components";
import { NoteTextWrapper } from "../order/OrderDetails";
import { ScrollWrapper } from "../../OrderDesign";
import { Box, Divider, Typography } from "@mui/material";
import { KdsStatus } from "./kdsWipManager";
import { KdsWipContext } from "./KdsStationOrders";
import { groupBy } from "lodash";

export const HorLine = styled.hr`
  width: 100%;
  color: black;
`;

const BG_COOKING = "#fad264";
const BG_COOKED = "rgb(192, 233, 188)";
const BG_DEFAULT = "transparent";

const CookedTextStyle = {
  textDecoration: "line-through",
  textDecorationThickness: "1px",
  textDecorationColor: "#00000073",
};

const NotCookedTextStyle = {
  textDecoration: "none",
};

export const KdsOrderDetails = ({
  orderDetails,
}: {
  orderDetails: KdsOrderDetail[];
}) => {
  const categoriesWithOrderDetails = useMemo(() => {
    const orderDetailsByCategory = groupBy(orderDetails, "categoryId");

    const orderDetailsArray = [];
    for (const key in orderDetailsByCategory) {
      orderDetailsArray.push({
        key: key,
        orderDetails: orderDetailsByCategory[key],
      });
    }

    return orderDetailsArray;
  }, [orderDetails]);

  return (
    <ScrollWrapper>
      <Box padding={1}>
        {categoriesWithOrderDetails.map((categoryOrderDetails, index) => (
          <React.Fragment key={`${categoryOrderDetails.key}${index}`}>
            <CategoryOrderDetails
              orderDetails={categoryOrderDetails.orderDetails}
            />
          </React.Fragment>
        ))}
      </Box>
    </ScrollWrapper>
  );
};

const CategoryOrderDetails = ({
  orderDetails,
}: {
  orderDetails: KdsOrderDetail[];
}) => {
  const { orderDetailStatus, shiftKdsStatus } = useContext(KdsWipContext);

  const orderDetailRowBgColor = (orderDetailId: number) => {
    const kdsStatus = orderDetailStatus(orderDetailId);
    if (kdsStatus === KdsStatus.Cooking) return BG_COOKING;
    if (kdsStatus == KdsStatus.Cooked) return BG_COOKED;

    return BG_DEFAULT;
  };

  return (
    <>
      {orderDetails.map((detailedOrder) => (
        <React.Fragment key={detailedOrder.id}>
          <Box
            bgcolor={orderDetailRowBgColor(detailedOrder.id)}
            onClick={() => shiftKdsStatus(detailedOrder.id)}
            paddingLeft={1}
            paddingRight={1}
            paddingBottom={"4px"}
            sx={{ cursor: "pointer" }}
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              paddingTop={1}
              paddingBottom={1}
              sx={
                orderDetailStatus(detailedOrder.id) === KdsStatus.Cooked
                  ? CookedTextStyle
                  : NotCookedTextStyle
              }
            >
              <Typography data-testid="test-id-item-name-in-kds">
                {detailedOrder.itemName}
              </Typography>
              <Typography>{`x  ${detailedOrder.quantity}`}</Typography>
            </Box>
            {detailedOrder.note != "" && (
              <NoteTextWrapper>{`Note: ${detailedOrder.note}`}</NoteTextWrapper>
            )}

            {!!detailedOrder?.variation && (
              <NoteTextWrapper>{`Variation: ${detailedOrder.variation}`}</NoteTextWrapper>
            )}
          </Box>
          <Divider />
        </React.Fragment>
      ))}
      <Divider sx={{ borderColor: "rgb(0 0 0 / 29%)" }} />
    </>
  );
};

export type KdsOrderDetail = {
  id: number;
  itemId: number;
  itemName: string;
  quantity: number;
  note: string;
  variation?: string;
};
