import { cloneDeep } from "lodash";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { ItemType } from "../../../client/menuClient";
import { useMenuData } from "../../../menu-provider/MenuDataProvider";

const InventoryContext = createContext<{
  getItemQuantity: (id: number) => number | false;
  incrementItemQuantity: (id: number) => void;
  decrementItemQuantity: (id: number) => void;
  resetItemsQuantity: () => void;
  reloadInventory: () => void;
}>({
  getItemQuantity: () => false,
  incrementItemQuantity: () => {},
  decrementItemQuantity: () => {},
  resetItemsQuantity: () => {},
  reloadInventory: () => {},
});

export const ItemInventoryProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { categories, reloadMenuData } = useMenuData();
  const [items, setItems] = useState<ItemType[]>([]);

  useEffect(() => {
    if (categories === undefined) return;
    const allItems = cloneDeep(
      categories.flatMap((category) => category.items)
    );
    setItems(allItems);
  }, [categories]);

  const getItemQuantity = (id: number) => {
    const itm = items.find((item) => item.id == id);
    if (itm === undefined) return false;
    if (!itm.isInventoryEnabled) return false;
    if (itm.availableQuantity === undefined) return false;
    return itm.availableQuantity;
  };

  const incrementItemQuantity = (id: number) => {
    const updatingItemIndex = items.findIndex((value) => value.id === id);
    const tempItems = [...items];

    if (updatingItemIndex < 0) return;

    const itemQty = tempItems[updatingItemIndex].availableQuantity;
    if (itemQty === undefined || itemQty === null) return;
    tempItems[updatingItemIndex].availableQuantity = itemQty + 1;

    setItems(tempItems);
  };

  const decrementItemQuantity = (id: number) => {
    const updatingItemIndex = items.findIndex((value) => value.id === id);
    const tempItems = [...items];

    if (updatingItemIndex < 0) return;

    const itemQty = tempItems[updatingItemIndex].availableQuantity;
    if (itemQty === undefined || itemQty === null) return;

    tempItems[updatingItemIndex].availableQuantity = itemQty - 1;

    setItems(tempItems);
  };

  const resetItemsQuantity = () => {
    const allItems = cloneDeep(
      categories.flatMap((category) => category.items)
    );

    setItems(allItems);
  };

  return (
    <InventoryContext.Provider
      value={{
        getItemQuantity,
        incrementItemQuantity,
        decrementItemQuantity,
        resetItemsQuantity,
        reloadInventory: reloadMenuData,
      }}
    >
      {children}
    </InventoryContext.Provider>
  );
};

export const useItemInventory = () => {
  const {
    getItemQuantity,
    incrementItemQuantity,
    decrementItemQuantity,
    resetItemsQuantity,
    reloadInventory,
  } = useContext(InventoryContext);

  return {
    getItemQuantity,
    incrementItemQuantity,
    decrementItemQuantity,
    resetItemsQuantity,
    reloadInventory,
  };
};
