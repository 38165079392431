import { Box, TextField } from "@mui/material";
import { OrderType } from "../../../types/OrderType";

export const CustomerDetailsForm = ({
  order,
  setOrder,
}: {
  order: OrderType;
  setOrder: (value: OrderType) => void;
}) => {
  return (
    <Box>
      <HiddenFocusCapture />
      <TextField
        variant="standard"
        placeholder="Name"
        value={order.customerName}
        onChange={(event) =>
          setOrder({ ...order, customerName: event?.target.value })
        }
        label={"Name"}
        sx={{ width: "48%", marginRight: "2%" }}
      />
      <TextField
        variant="standard"
        placeholder="Phone"
        value={order.customerPhone}
        onChange={(event) =>
          setOrder({ ...order, customerPhone: event?.target.value })
        }
        label={"Phone"}
        sx={{ width: "48%" }}
      />
    </Box>
  );
};

const HiddenFocusCapture = () => (
  <input type="text" style={{ display: "none" }} />
);
