import Popup from "reactjs-popup";
import { ReactNode, useEffect, useRef } from "react";
import { TimesIcon } from "../design-system/Icons";
import styled from "styled-components";

const overlayStyle = {
  backgroundColor: "rgba(0,0,0,0.4)",
  backdropFilter: "blur(5px)",
};

const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 20px;
`;

export const PopUpComponent = ({
  setOpen,
  open,
  children,
  isNotCloseable,
  isNotCloseOnDocumentClick,
  contentStyle,
}: PopupPropsType) => {
  const defaultContentStyle = {
    maxWidth: "600px",
    width: "90%",
    height: "80%",
    overflow: "hidden",
    backgroundColor: "#f9f9f9",
    border: "1px solid black",
    paddingBottom: "40px",
  };

  const mounted = useRef(true);

  useEffect(() => {
    return function cleanup() {
      mounted.current = false;
    };
  }, []);

  return (
    <>
      <Popup
        open={open}
        modal
        contentStyle={
          contentStyle == undefined ? defaultContentStyle : contentStyle
        }
        overlayStyle={overlayStyle}
        closeOnDocumentClick={!isNotCloseable && !isNotCloseOnDocumentClick}
        onClose={() => mounted.current && setOpen(false)}
      >
        {!isNotCloseable && (
          <CloseButton
            role="close-button"
            aria-label="close-button"
            onClick={() => setOpen(false)}
          >
            <TimesIcon />
          </CloseButton>
        )}
        {children}
      </Popup>
    </>
  );
};

type PopupPropsType = {
  setOpen: (popUpFlag: boolean) => void;
  open: boolean;
  children: ReactNode;
  isNotCloseable?: boolean;
  isNotCloseOnDocumentClick?: boolean;
  contentStyle?: React.CSSProperties;
};
