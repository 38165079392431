import { IconButton } from "@mui/material";
import { usePostClientWithAuth } from "../../../client/postClientWithAuth";
import { Status } from "../../../client/status";
import { CashRegister } from "../../../design-system/Icons";
import { useMenuData } from "../../../menu-provider/MenuDataProvider";
import { OPEN_CASH_DRAWER } from "./usePrinter";

export const DrawerOpenButton = () => {
  const { deviceId } = useMenuData();
  const { postData: sendOpenDrawerJob, status } =
    usePostClientWithAuth("/api/job");
  return (
    <>
      {!!deviceId && (
        <IconButton
          data-testid="test-id-no-sell-drawer-open-button"
          color={"primary"}
          disabled={status === Status.Pending}
          onClick={() =>
            sendOpenDrawerJob({
              deviceId: deviceId,
              printData: OPEN_CASH_DRAWER,
            })
          }
          sx={{ marginRight: 1 }}
        >
          <CashRegister />
        </IconButton>
      )}
    </>
  );
};
