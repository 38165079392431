import { Box, Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Status } from "../../../client/status";

import { useMenuData } from "../../../menu-provider/MenuDataProvider";
import { useAddCategoryClient } from "./addCategoryClient";
import { alertOptions } from "./alertOptions";

const isEmptyOrSpacesOnly = (categoryName: string) => {
  return !categoryName.replace(/\s/g, "").length;
};

export const AddCategory = () => {
  const [categoryName, setCategoryName] = useState<string>("");
  const { status, saveNewCategory } = useAddCategoryClient();
  const { reloadMenuData } = useMenuData();
  const alert = useAlert();

  const addNewCategory = () => {
    saveNewCategory({ categoryName: categoryName });
  };

  useEffect(() => {
    if (status == Status.Success) {
      alert.success("Category added successfully!", alertOptions);
      reloadMenuData();
      setCategoryName("");
      return;
    }

    status == Status.Error && alert.error("Request failed!", alertOptions);
  }, [status, alert, reloadMenuData]);

  return (
    <Box marginBottom={3} marginTop={3}>
      <TextField
        variant="standard"
        size="small"
        placeholder="Category name"
        value={categoryName}
        onChange={(event) => setCategoryName(event.target.value)}
        fullWidth
      />

      <Button
        variant="contained"
        disabled={isEmptyOrSpacesOnly(categoryName)}
        onClick={addNewCategory}
        sx={{ marginTop: 1 }}
      >
        Add category
      </Button>
    </Box>
  );
};
