import styled from "styled-components";
import OrderItLogo from "./img/orderit-logo.png";
import { NavigationDrawer } from "./NavigationDrawer";
import { AppBar, Box, Toolbar } from "@mui/material";
import { ReactNode } from "react";

export const HeaderLogo = styled.div`
  height: 30px;
  background-color: transparent;
  color: #ffffff;
  font-size: 1.5em;
  text-align: center;
`;

export const DashboardHeader = ({ rightBar }: { rightBar?: ReactNode }) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        elevation={0}
        sx={{ backgroundColor: "rgba(83, 83, 83, 0.06)" }}
      >
        <Toolbar variant="dense">
          <NavigationDrawer />

          <Box
            component={"img"}
            src={OrderItLogo}
            data-testid="logo"
            sx={{
              width: 130,
            }}
          ></Box>

          <Box flexGrow={1} />
          {rightBar}
        </Toolbar>
      </AppBar>
    </Box>
  );
};
