import { AddCircle, RemoveCircle } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { ItemType } from "../../../client/menuClient";
import { usePostClientWithAuth } from "../../../client/postClientWithAuth";
import { Status } from "../../../client/status";
import { StackedIcon } from "../../../design-system/Icons";
import { useMenuData } from "../../../menu-provider/MenuDataProvider";

export function InventoryItem({ item }: { item: ItemType }) {
  const { postData: updateQuantity, status: updateQuantityStatus } =
    usePostClientWithAuth(`/api/item/${item.id}/stock-in/`);

  const { postData: enableInventory, status: enableInventoryStatus } =
    usePostClientWithAuth(`/api/item/${item.id}/update-inventory-status/`);

  const { reloadMenuData } = useMenuData();
  const [quantity, setQuantity] = useState("");

  useEffect(() => {
    if (enableInventoryStatus === Status.Success) {
      setQuantity("");
      reloadMenuData();
    }
  }, [enableInventoryStatus, reloadMenuData]);

  useEffect(() => {
    if (updateQuantityStatus === Status.Success) {
      setQuantity("");
      reloadMenuData();
    }
  }, [reloadMenuData, updateQuantityStatus]);

  const isAnyPendingRequests =
    updateQuantityStatus === Status.Pending ||
    enableInventoryStatus === Status.Pending;

  return (
    <Box
      key={item.id}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Box display={"flex"} alignItems={"center"}>
        <FormControlLabel
          control={
            <Checkbox
              onChange={(event) =>
                enableInventory({ isEnable: event.target.checked })
              }
              data-testid="test-id-inventory-enable-checkbox"
              checked={item.isInventoryEnabled}
              sx={{ marginLeft: "4px" }}
            />
          }
          label={undefined}
        />

        <Typography>{item.name}</Typography>
      </Box>
      <Box alignItems={"center"} display="flex">
        {item.isInventoryEnabled && (
          <Chip
            label={
              <Typography>
                <StackedIcon />
                {item.availableQuantity}
              </Typography>
            }
            sx={{ marginRight: 2 }}
          />
        )}

        <LoadingButton
          data-testid="test-id-item-stock-out-button"
          onClick={() =>
            updateQuantity({
              quantity: -1 * parseFloat(quantity),
            })
          }
          loading={updateQuantityStatus === Status.Pending}
          variant={"contained"}
          size={"small"}
          disabled={quantity === "" || isAnyPendingRequests}
          startIcon={<RemoveCircle />}
        >
          out
        </LoadingButton>
        <NumericFormat
          customInput={TextField}
          value={quantity}
          onChange={(event) => setQuantity(event.target.value)}
          inputProps={{ inputMode: "decimal" }}
          allowNegative={false}
          placeholder="Quantity"
          size="small"
          disabled={!item.isInventoryEnabled}
          sx={{ width: "100px", input: { textAlign: "center" } }}
        />

        <LoadingButton
          data-testid="test-id-item-stock-in-button"
          onClick={() =>
            updateQuantity({
              quantity: parseFloat(quantity),
            })
          }
          loading={updateQuantityStatus === Status.Pending}
          variant={"contained"}
          size={"small"}
          disabled={quantity === "" || isAnyPendingRequests}
          endIcon={<AddCircle />}
        >
          in
        </LoadingButton>
      </Box>
    </Box>
  );
}
