import { Status } from "../../client/status";
import { usePlayAudio } from "./playAudio";
import React, { useEffect, useState } from "react";

export const SoundNotification = ({
  data,
  status,
  soundFile,
}: {
  data: { id: number }[] | undefined;
  status: Status;
  soundFile: string;
}) => {
  const { playAudio } = usePlayAudio(soundFile);

  const [maxRequestId, setMaxRequestId] = useState<number | undefined>(
    undefined
  );

  const [isItTheFirst, setIsItTheFirst] = useState(true);

  useEffect(() => {
    if (data == undefined) return;
    const maxId = Math.max(...data.map((value) => value.id));
    const hasNewRequest = maxRequestId && maxId > maxRequestId;

    if (data.length != 0 && !isItTheFirst && hasNewRequest) {
      playAudio();
    }

    setMaxRequestId(maxId);
    if (status == Status.Success) {
      setIsItTheFirst(false);
    }
  }, [data, isItTheFirst, maxRequestId, playAudio, status]);

  return <></>;
};
