import { Download, EditCalendar } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { addDays, endOfDay, format, startOfDay } from "date-fns";
import { MouseEvent, useMemo, useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useGetClientWithAuth } from "../../../client/getClientWithAuth";
import { Status } from "../../../client/status";
import { VerticalScrolls } from "../../../design-system/Container";
import { useMenuIdFromURL } from "../../../hooks/menuIdFromURL";
import { DayOfWeekSales } from "./DayOfWeekSalesChart";
import { useProcessedOrderTotals } from "./useProcessedOrderTotals";
import { OrderTotalChart } from "./OrderTotalChart";
import { TimeOfDaySalesChart } from "./TimeOfDaySalesChart";
import { CSVLink } from "react-csv";

export const Reports = () => {
  const menuId = useMenuIdFromURL();

  const [dateRange, setDateRange] = useState({
    startDate: startOfDay(addDays(new Date(), -30)),
    endDate: endOfDay(new Date()),
    key: "selection",
  });

  const {
    data: summaryData,
    reloadData: reloadOrders,
    status,
  } = useGetClientWithAuth<{
    orders: ReportOrderType[];
    itemsSold: { name: string; nameCount: number }[];
  }>(
    generateUrl(menuId, {
      startDate: startOfDay(addDays(new Date(), -30)),
      endDate: endOfDay(new Date()),
    })
  );

  const {
    orders: ordersByDate,
    total,
    totalDiscount,
    totalTax,
    itemsTotal,
  } = useProcessedOrderTotals(summaryData?.orders);

  const updateDateRangeForOrders = (newRange: DateRangeType): void => {
    setDateRange(newRange);
    reloadOrders({ input: generateUrl(menuId, newRange) });
  };

  return (
    <Box
      overflow={"hidden"}
      height="calc(100vh - (60px))"
      paddingLeft={1}
      paddingRight={1}
    >
      <Box
        display={"flex"}
        marginTop={1}
        marginBottom={1}
        alignItems={"center"}
      >
        <SalesSummaryDownload
          itemsSold={summaryData?.itemsSold}
          total={total}
          discountTotal={totalDiscount}
          itemsTotal={itemsTotal}
          taxTotal={totalTax}
          filename={`reports-${dateRange.startDate.toLocaleDateString()}-to-${dateRange.endDate.toLocaleDateString()}`}
        />

        <Box display={"flex"} marginLeft="auto">
          {status === Status.Pending && (
            <Box data-testid="test-id-report-spinner" marginRight={2}>
              <CircularProgress />
            </Box>
          )}
          <SelectDateRange
            dateRange={dateRange}
            onDateRangeChange={updateDateRangeForOrders}
          />
        </Box>
      </Box>

      <VerticalScrolls height="calc(100% - 50px);">
        <Box
          display={"flex"}
          justifyContent={"space-evenly"}
          margin={"24px 8px 24px 8px"}
        >
          <SummaryCard label="Grand Total" value={total} bgColor={"#97ebc0"} />
          <SummaryCard label="Total Tax" value={totalTax} bgColor={"#ebd997"} />
          <SummaryCard
            label="Total Discount"
            value={totalDiscount}
            bgColor={"#97ebc0"}
          />
          <SummaryCard
            label="Items Total"
            value={itemsTotal}
            bgColor={"#ebd997"}
          />
        </Box>

        <OrderTotalChart
          orders={ordersByDate}
          dateRange={dateRange}
          total={total}
        />
        <DayOfWeekSales orders={ordersByDate} />
        <TimeOfDaySalesChart
          orders={summaryData?.orders === undefined ? [] : summaryData.orders}
        />
        <MostSoldItemsTable itemsSold={summaryData?.itemsSold} />
      </VerticalScrolls>
    </Box>
  );
};

function MostSoldItemsTable({
  itemsSold,
}: {
  itemsSold?: { name: string; nameCount: number }[];
}) {
  return (
    <Paper
      sx={{
        padding: 1,
        height: "600px",
        overflowY: "scroll",
        marginBottom: 1,
      }}
    >
      <Typography sx={{ margin: 2 }} variant="h6">
        Most Sold Items
      </Typography>

      <Table data-testid="test-id-most-sold-item-table">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Item name</TableCell>
            <TableCell>Number of sales</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {itemsSold?.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.nameCount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

function SalesSummaryDownload({
  itemsSold,
  total,
  filename,
  taxTotal,
  discountTotal,
  itemsTotal,
}: {
  itemsSold?: { name: string; nameCount: number }[];
  total: number;
  taxTotal: number;
  discountTotal: number;
  itemsTotal: number;
  filename: string;
}) {
  const csvSummary = useMemo(() => {
    const soldItems = itemsSold === undefined ? [] : itemsSold;
    return [
      ...soldItems,
      {},
      { name: "Sales summary" },
      {},
      { name: "Items sub-total", nameCount: itemsTotal },
      { name: "Total discount", nameCount: discountTotal },
      { name: "Total tax", nameCount: taxTotal },
      { name: "Grand Total", nameCount: total },
    ];
  }, [discountTotal, itemsSold, itemsTotal, taxTotal, total]);

  return (
    <Box>
      <CSVLink
        data={csvSummary}
        filename={filename}
        headers={[
          {
            label: "Item name",
            key: "name",
          },
          {
            label: "Quantity",
            key: "nameCount",
          },
        ]}
      >
        <Button variant="contained">
          Summary
          <Download />
        </Button>
      </CSVLink>
    </Box>
  );
}

function generateUrl(
  menuId: string,
  dateRange: { startDate: Date; endDate: Date }
): string {
  return `/api/menu/${menuId}/orders/summary-list?date_start=${dateRange.startDate.toISOString()}&date_end=${dateRange.endDate.toISOString()}`;
}

function SelectDateRange({
  dateRange,
  onDateRangeChange,
}: {
  dateRange: DateRangeType;
  onDateRangeChange: (value: DateRangeType) => void;
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [selectedRange, setSelectedRange] = useState(dateRange);

  const showDateRangePicker = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const changeDateRange = () => {
    setOpen(false);
    onDateRangeChange(selectedRange);
  };

  return (
    <>
      <Box padding={"4px"} sx={{ bgcolor: "background.paper" }}>
        <Button
          onClick={showDateRangePicker}
          variant="outlined"
          sx={{
            textTransform: "none",
          }}
        >
          <EditCalendar sx={{ marginRight: 1 }} />
          {format(dateRange.startDate, "MMM dd, yyyy")}&nbsp;&ndash;&nbsp;
          {format(dateRange.endDate, "MMM dd, yyyy")}
        </Button>
      </Box>

      <Popper open={open} anchorEl={anchorEl} placement={"bottom-end"}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          sx={{
            border: 1,
            p: 1,
            bgcolor: "background.paper",
          }}
        >
          <DateRangePicker
            ranges={[selectedRange]}
            onChange={(range) => {
              if (range.selection.startDate && range.selection.endDate) {
                setSelectedRange({
                  startDate: range.selection.startDate,
                  endDate: range.selection.endDate,
                  key: "selection",
                });
              }
            }}
            editableDateInputs={true}
            displayMode={"dateRange"}
          />

          <Button onClick={changeDateRange} variant="contained">
            Okay
          </Button>
        </Box>
      </Popper>
    </>
  );
}

function SummaryCard({
  label,
  value,
  bgColor,
}: {
  label: string;
  value: number;
  bgColor: string;
}) {
  return (
    <Paper sx={{ padding: 1, bgcolor: bgColor }}>
      <Typography>{label}</Typography>
      <Divider />
      <Typography variant="h3">{value.toFixed(2)}</Typography>
    </Paper>
  );
}

export type ReportOrderType = {
  id: number;
  createdAt: string;
  orderTotal: number;
  tax: number;
  discount: number;
  itemsTotal: number;
};

export type DateRangeType = {
  startDate: Date;
  endDate: Date;
  key: string;
};
