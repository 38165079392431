import { useMenuListProvider } from "./MenuListProvider";
import { useMenuIdFromURL } from "../../hooks/menuIdFromURL";
import { ReactNode } from "react";
import styled from "styled-components";

const NotFoundText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 4em;
  height: calc(100vh - (140px));
  @media (min-width: 395px) {
    height: calc(100vh - (100px));
  }
`;

const NotFound404 = () => {
  return <NotFoundText>404 Not Found</NotFoundText>;
};

export const AccessibleChildOrNotFound = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { isMenuIdInList } = useMenuListProvider();
  const menuId = useMenuIdFromURL();

  return <>{isMenuIdInList(Number(menuId)) ? children : <NotFound404 />}</>;
};
