import { useMemo } from "react";
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
} from "recharts";
import { mean } from "lodash";
import { useMenuData } from "../../../menu-provider/MenuDataProvider";
import { Paper, Typography } from "@mui/material";

export const DayOfWeekSales = ({
  orders,
}: {
  orders: { createdAt: Date; orderTotal: number }[];
}) => {
  const { currency } = useMenuData();

  const barChartData = useMemo(() => {
    const dayData = [];
    for (const day of DAYS) {
      const dayOrders = orders.filter(
        (value) => value.createdAt.getDay() == day.value
      );

      const dayTotals = dayOrders.map((value) => value.orderTotal);

      dayData.push({ name: day.name, orderTotals: dayTotals });
    }
    return dayData;
  }, [orders]);

  return (
    <Paper sx={{ padding: 1, marginBottom: 3 }}>
      <Typography sx={{ margin: 2 }} variant="h6">
        Average Sales by Day
      </Typography>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          width={500}
          height={300}
          data={barChartData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip
            formatter={(value) => {
              const returnValue = isNaN(value as number)
                ? 0
                : (value as number).toFixed(2);
              return [`${currency}${returnValue}`, "Average sales"];
            }}
          />
          <Legend />
          <Bar
            dataKey={(value) => mean(value.orderTotals)}
            fill="#8884d8"
            label="Average"
            name="Sales by Day"
          />
        </BarChart>
      </ResponsiveContainer>
    </Paper>
  );
};

const DAYS = [
  {
    name: "Sun",
    value: 0,
  },
  {
    name: "Mon",
    value: 1,
  },
  {
    name: "Tue",
    value: 2,
  },
  {
    name: "Wed",
    value: 3,
  },
  {
    name: "Thu",
    value: 4,
  },
  {
    name: "Fri",
    value: 5,
  },
  {
    name: "Sat",
    value: 6,
  },
];
