import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  Step,
  StepLabel,
  Stepper,
  styled,
  Typography,
} from "@mui/material";
import { toNumber } from "lodash";
import { useHistory, useParams } from "react-router-dom";
import { useDataPollingClient } from "../client/dataPollingClient";
import { orderTotalWithTaxAndDiscount } from "../dashbord/menuSpecificDashboard/order/orderTotalUtils";
import { useMenuData } from "../menu-provider/MenuDataProvider";
import { DELIVERY_ORDER_TABLE_NAME } from "../ORDER_TABLE_NAME_TYPES";
import { OrderType } from "../types/OrderType";

const ORDER_STATUS_POLLING_INTERVAL_MILLISECONDS = 30000;

export const OrderStatus = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const { data: order } = useDataPollingClient<OrderType>(
    `/api/order/${orderId}/`,
    ORDER_STATUS_POLLING_INTERVAL_MILLISECONDS
  );
  const { currency, bkashLink, deliveryCharge, note } = useMenuData();
  const history = useHistory();

  return (
    <Box
      maxWidth={"600px"}
      margin={"auto"}
      padding={2}
      boxSizing={"border-box"}
    >
      <Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <IconButton
            data-testid="test-id-back-button"
            onClick={() => history.goBack()}
          >
            <ArrowBack fontSize="large" />
          </IconButton>

          {order !== undefined && (
            <Typography>{getLocalTime(order.createdAt)}</Typography>
          )}
          <Typography>#{order?.orderNumber}</Typography>
        </Box>
        <Divider />
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          marginTop={4}
        >
          <Stepper
            activeStep={order?.isReady === true ? 3 : 1}
            orientation={"vertical"}
          >
            <Step>
              <StepLabel>
                <Typography variant="h6">Order Placed</Typography>
                <Typography>We have received your order.</Typography>
              </StepLabel>
            </Step>

            <Step>
              <StepLabel>
                <Typography variant="h6">Processing</Typography>
                <Typography>We are preparing your order.</Typography>
              </StepLabel>
            </Step>

            <Step>
              <StepLabel>
                <Typography variant="h6">Ready</Typography>
                <Typography>Your order is ready.</Typography>
              </StepLabel>
            </Step>
          </Stepper>
        </Box>
      </Box>

      <Divider sx={{ marginTop: 2, marginBottom: 2 }} />

      {order !== undefined && (
        <PaymentNote
          orderTotal={orderTotalWithTaxAndDiscount(order)}
          currency={currency}
          bkashLink={bkashLink}
          deliveryCharge={deliveryCharge}
          tableName={order.tableName}
          note={note}
        ></PaymentNote>
      )}
    </Box>
  );
};

const getLocalTime = (isoString: string) => {
  const time = new Date(isoString).toLocaleTimeString("en", {
    timeStyle: "short",
    hour12: true,
  });

  return time;
};

function PaymentNote({
  currency,
  bkashLink,
  orderTotal,
  deliveryCharge,
  tableName,
  note,
}: {
  currency: string;
  bkashLink?: string;
  note?: string;
  orderTotal: string;
  deliveryCharge: number;
  tableName: string;
}) {
  const orderGrandTotal = deliveryCharge + toNumber(orderTotal);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Box display={"flex"} justifyContent={"space-between"}>
        <AmountLabelTypography>Order Total:</AmountLabelTypography>
        <AmountTypography data-testid="test-id-order-total-in-status-page">{` ${currency}${orderTotal}`}</AmountTypography>
      </Box>
      {tableName === DELIVERY_ORDER_TABLE_NAME && (
        <Box>
          <Box display={"flex"}>
            <AmountLabelTypography>Delivery Charge:</AmountLabelTypography>

            <AmountTypography>{`${currency}${deliveryCharge.toFixed(
              2
            )}`}</AmountTypography>
          </Box>
          <Box display={"flex"}>
            <AmountLabelTypography>Grand Total:</AmountLabelTypography>
            <AmountTypography>{`${currency}${orderGrandTotal.toFixed(
              2
            )}`}</AmountTypography>
          </Box>
        </Box>
      )}
      {!!bkashLink && (
        <>
          <Link
            component={Button}
            variant="button"
            href={bkashLink}
            data-testid="test-id-bkash-payment-button"
            target={"_blank"}
            fontSize={"18px"}
            padding={"8px"}
          >
            Pay with bkash
          </Link>
          <Typography fontSize={"small"}>
            Use order-id or your name as reference.
          </Typography>
        </>
      )}
      <Typography>{note}</Typography>
    </Box>
  );
}

const AmountLabelTypography = styled(Typography)({
  textAlign: "right",
  width: "140px",
});

const AmountTypography = styled(Typography)({
  textAlign: "right",
  width: "90px",
});
