import styled from "styled-components";

export const HeaderButton = styled("button")<{ smaller?: boolean }>`
  font-size:12px;
  padding-left: ${(props) => (props.smaller ? "4px;" : "8px;")}
  padding-right: ${(props) => (props.smaller ? "4px;" : "8px;")}
  padding-top:8px;
  padding-bottom: 8px;
  text-align: center;
  margin-left: 8px;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: #d3f2ff;
  cursor: pointer;
  border: none;
`;

export const HeaderButtonText = styled.span`
  display: block;
  font-size: 10px;
  margin-top: 5px;
`;

export const DisabledHeaderButton = styled(HeaderButton)`
  box-shadow: none;
  background-color: transparent;
  cursor: default;
`;
