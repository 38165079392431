import React from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { PaymentType } from "./PaymentType";

export function PaymentTypeToggleButton(props: {
  paymentType: string;
  onPaymentTypeChange: (newAlignment: string) => void;
  isDisabled: boolean;
}) {
  return (
    <ToggleButtonGroup
      color="primary"
      value={props.paymentType}
      exclusive
      onChange={(_, newAlignment) => props.onPaymentTypeChange(newAlignment)}
      data-testid="test-id-toggle-button"
      sx={{
        margin: "auto",
        marginTop: 2,
        marginBottom: 2,
      }}
      disabled={props.isDisabled}
    >
      <ToggleButton value={PaymentType.Cash}>cash</ToggleButton>
      <ToggleButton value={PaymentType.Credit}>credit</ToggleButton>
      <ToggleButton value={PaymentType.Bkash}>bkash</ToggleButton>
      <ToggleButton value={PaymentType.Nogod}>nogod</ToggleButton>
    </ToggleButtonGroup>
  );
}
