import React, { useState } from "react";
import { useCombinedCartAndMenuData } from "./combinedCartAndMenuData";
import { CartItemEditForm } from "./CartItemEditForm";
import { OrderConfirm } from "./OrderConfirm";
import {
  Box,
  Button,
  Divider,
  Step,
  StepButton,
  Stepper,
  Typography,
} from "@mui/material";
import { CartItem } from "./cart-provider/CartProvider";
import { VerticalScrolls } from "../design-system/Container";
import { OrderType } from "../types/OrderType";

export const cartButtonDesign = {
  position: "absolute",
  bottom: "8px",
  left: 0,
  right: 0,
  width: "140px",
  marginLeft: "auto",
  marginRight: "auto",
};

const CART_TAB_VALUE = 0;
const CONFIRM_TAB_VALUE = 1;

export const Cart = ({
  onOrderPlaced,
}: {
  onOrderPlaced: (order: OrderType) => void;
}) => {
  const [activeStep, setActiveStep] = useState(CART_TAB_VALUE);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: 2,
        boxSizing: "border-box",
      }}
    >
      <Stepper
        activeStep={activeStep}
        nonLinear
        sx={{ marginRight: "50px", marginBottom: "8px" }}
      >
        <Step key={"Cart"}>
          <StepButton
            color="inherit"
            onClick={() => setActiveStep(CART_TAB_VALUE)}
            data-testid="test-id-cart-title"
          >
            Cart
          </StepButton>
        </Step>

        <Step key={"Confirm"}>
          <StepButton
            color="inherit"
            onClick={() => setActiveStep(CONFIRM_TAB_VALUE)}
          >
            Confirm
          </StepButton>
        </Step>
      </Stepper>

      {activeStep == CART_TAB_VALUE && (
        <CartItems onProceed={() => setActiveStep(CONFIRM_TAB_VALUE)} />
      )}
      {activeStep == CONFIRM_TAB_VALUE && (
        <OrderConfirm onSuccess={onOrderPlaced} />
      )}
    </Box>
  );
};

const OrderSummary = ({
  currency,
  taxPercentage,
  cartItemList,
  isItemWiseTaxEnabled,
}: {
  currency: string;
  taxPercentage: number;
  cartItemList: CartItem[];
  isItemWiseTaxEnabled: boolean;
}) => {
  return (
    <Box
      display="flex"
      flexDirection={"column"}
      marginLeft="auto"
      marginBottom={2}
      marginRight={1}
      marginTop={2}
      data-testid="test-id-customer-order-summary"
      width={"240px"}
    >
      <Box display="flex" justifyContent={"space-between"}>
        <Typography textAlign={"right"}>Subtotal:</Typography>
        <Typography textAlign={"right"} width="120px">
          {`${currency}${calculateSubTotal(cartItemList).toFixed(2)}`}
        </Typography>
      </Box>

      <Box display="flex" justifyContent={"space-between"}>
        <Typography textAlign={"right"}>
          {isItemWiseTaxEnabled ? "Tax:" : `Tax (${taxPercentage}%):`}
        </Typography>

        <Typography textAlign={"right"} width="120px">
          {`${currency}${calculateTax(
            cartItemList,
            taxPercentage,
            isItemWiseTaxEnabled
          ).toFixed(2)}`}
        </Typography>
      </Box>

      <Box display="flex" justifyContent={"space-between"}>
        <Typography textAlign={"right"}>Grand Total:</Typography>
        <Typography
          textAlign={"right"}
          width="120px"
        >{`${currency}${calculateGrandTotal(
          cartItemList,
          taxPercentage,
          isItemWiseTaxEnabled
        ).toFixed(2)}`}</Typography>
      </Box>
    </Box>
  );
};

const CartItems = ({ onProceed }: { onProceed: () => void }) => {
  const {
    cartItemList,
    increaseItemQuantity,
    removeFromCart,
    updateNoteInCart,
    currency,
    taxPercentage,
    isItemWiseTaxEnabled,
  } = useCombinedCartAndMenuData();

  return (
    <Box height={"calc(100% - 90px)"} marginTop={"8px"}>
      <VerticalScrolls>
        {cartItemList.map((value, index) => {
          return (
            <div key={index} data-testid="cart-item-row">
              <CartItemEditForm
                id={value.item.id}
                itemName={value.item.name}
                note={value.note}
                quantity={value.quantity}
                description={value.item.description}
                price={value.item.price}
                currency={currency}
                onDecreaseItemQuantity={removeFromCart}
                onIncreaseItemQuantity={increaseItemQuantity}
                onUpdateNoteInCart={updateNoteInCart}
              ></CartItemEditForm>

              <Divider />
            </div>
          );
        })}
        <OrderSummary
          currency={currency}
          taxPercentage={taxPercentage}
          cartItemList={cartItemList}
          isItemWiseTaxEnabled={isItemWiseTaxEnabled}
        />

        <Button
          onClick={onProceed}
          variant="contained"
          size="medium"
          sx={cartButtonDesign}
        >
          Next
        </Button>
      </VerticalScrolls>
    </Box>
  );
};

const calculateSubTotal = (cartItemList: CartItem[]) => {
  let subTotal = 0.0;
  for (let i = 0; i < cartItemList.length; i++) {
    subTotal += cartItemList[i].item.price * cartItemList[i].quantity;
  }
  return subTotal;
};

const calculateTax = (
  cartItemList: CartItem[],
  taxPercentage: number,
  isItemWiseTaxEnabled: boolean
) => {
  if (!isItemWiseTaxEnabled) {
    const subTotal = calculateSubTotal(cartItemList);
    const tax = (subTotal * taxPercentage) / 100;
    return tax;
  }

  let taxTotal = 0.0;
  for (let i = 0; i < cartItemList.length; i++) {
    const itemTotal = cartItemList[i].item.price * cartItemList[i].quantity;
    const itemTax = cartItemList[i].item.tax;
    if (itemTax !== undefined) taxTotal += (itemTotal * itemTax) / 100;
  }

  return taxTotal;
};

const calculateGrandTotal = (
  cartItemList: CartItem[],
  taxPercentage: number,
  isItemWiseTaxEnabled: boolean
) => {
  const subTotal = calculateSubTotal(cartItemList);
  const tax = calculateTax(cartItemList, taxPercentage, isItemWiseTaxEnabled);
  return subTotal + tax;
};
