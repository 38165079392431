import { useCallback, useEffect, useRef, useState } from "react";
import { useAuthentication } from "../auth-provider/AuthProvider";
import { Status } from "./status";

export const useDataPollingClient = <DataType>(
  link: string,
  period?: number
) => {
  const [data, setData] = useState<DataType | undefined>(undefined);
  const [status, setStatus] = useState(Status.NotStarted);
  const { token } = useAuthentication();
  const mounted = useRef(true);
  const newData = useRef<string>("");

  const trigger = useCallback(async () => {
    mounted.current && setStatus(Status.Pending);

    const response = await fetch(link, {
      headers: {
        Authorization: "Token " + token,
      },
    });

    if (response.status == 200) {
      const parsedJsonResponse = await response.json();

      if (JSON.stringify(parsedJsonResponse) !== newData.current) {
        mounted.current && setStatus(Status.Success);
        mounted.current && setData(parsedJsonResponse);

        newData.current = JSON.stringify(parsedJsonResponse);
      }
      return;
    }
    setStatus(Status.Error);
  }, [link, token]);

  useEffect(() => {
    trigger();
    const interval = period === undefined ? 3000 : period;
    const intervalId = setInterval(trigger, interval);
    return function cleanup() {
      clearInterval(intervalId);
    };
  }, [period, trigger]);

  useEffect(() => {
    return function cleanup() {
      mounted.current = false;
    };
  }, []);

  return { data: data, status: status, reloadData: trigger };
};
