import { MenuDataProvider } from "../../menu-provider/MenuDataProvider";
import { Kds } from "./kds/Kds";
import { DashboardOrders } from "./order/DashboardOrders";
import { UpdateMenu } from "./update/UpdateMenu";
import { PrivateRoute } from "../PrivateRoute";
import { KdsStationProvider } from "./kds/kdsStationsProvider";
import { Route, useRouteMatch } from "react-router-dom";
import { OrderHistory } from "./order/OrderHistory";
import { DashboardHeader } from "../Header";
import { OrderDataProvider } from "./order/orderDataManager";
import { OrderPageRightBar } from "./order/OrderPageRightBar";
import { Reports } from "./reports/Reports";
import { QRGeneration } from "./qr-generation/QRGeneration";
import { Settings } from "./settings/Settings";
import { QueueingDisplay } from "./queueing-display/QueueingDisplay";
import { Inventory } from "./inventory/Inventory";
import { ItemInventoryProvider } from "./order/ItemInventoryProvider";
import { OrderHistoryRightBar } from "./OrderHistoryRightBar";

export const MenuSpecificDashboardRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <PrivateRoute path={`${path}/kds`}>
        <DashboardHeader />
        <KdsStationProvider>
          <Kds />
        </KdsStationProvider>
      </PrivateRoute>

      <PrivateRoute path={`${path}/update`}>
        <MenuDataProvider authenticated>
          <DashboardHeader />
          <UpdateMenu />
        </MenuDataProvider>
      </PrivateRoute>

      <PrivateRoute path={`${path}/orders/history`}>
        <MenuDataProvider authenticated>
          <DashboardHeader rightBar={<OrderHistoryRightBar />} />
          <OrderHistory />
        </MenuDataProvider>
      </PrivateRoute>

      <PrivateRoute path={`${path}/orders`}>
        <MenuDataProvider authenticated>
          <ItemInventoryProvider>
            <OrderDataProvider>
              <DashboardHeader rightBar={<OrderPageRightBar />} />
              <DashboardOrders />
            </OrderDataProvider>
          </ItemInventoryProvider>
        </MenuDataProvider>
      </PrivateRoute>

      <PrivateRoute path={`${path}/reports`}>
        <MenuDataProvider authenticated>
          <DashboardHeader />
          <Reports />
        </MenuDataProvider>
      </PrivateRoute>

      <PrivateRoute path={`${path}/inventory`}>
        <MenuDataProvider authenticated>
          <DashboardHeader />
          <Inventory />
        </MenuDataProvider>
      </PrivateRoute>

      <Route path={`${path}/queueing-display`}>
        <DashboardHeader />
        <QueueingDisplay />
      </Route>

      <Route path={`${path}/qr-generation`}>
        <DashboardHeader />
        <QRGeneration />
      </Route>

      <PrivateRoute path={`${path}/settings`}>
        <MenuDataProvider authenticated>
          <DashboardHeader />
          <Settings />
        </MenuDataProvider>
      </PrivateRoute>
    </>
  );
};
