import { Divider } from "@mui/material";
import { createContext, useEffect, useState } from "react";
import styled from "styled-components";
import { ItemType } from "../../../../client/menuClient";
import { ENABLE_ITEM_BG, DISABLE_ITEM_BG } from "./DASHBOARD_ITEM_BG";
import { ItemEnableDisable } from "./ItemEnableDisable";
import { RemoveItem } from "./RemoveItem";
import { UpdateItem } from "./UpdateItem";
import { UploadItemImage } from "./UpdateItemImage";

const ItemDetailsRow = styled.div`
  width: calc(100% - 44px);
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`;

export const ItemRow = styled.div`
  padding: 0;
  margin-left: 38px;
  margin-right: 38px;
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;

const DashBoardItemRow = styled(ItemRow)<{ backgroundColor: string }>`
  display: flex;
  position: relative;
  margin-left: 0px;
  margin-right: 0px;
  background-color: ${(props) => props.backgroundColor};
  padding-top: 4px;
  padding-right: 8px;
  width: 100%;
`;

export const DashboardItemContext = createContext<{
  setItemBackgroundColor: (color: string) => void;
}>({
  setItemBackgroundColor: () => {},
});

export const DashboardItem = ({
  item,
  currency,
  isItemWiseTaxEnabled,
  removeItemFromCategory,
}: DashboardItemProps) => {
  const [itemBackgroundColor, setItemBackgroundColor] = useState<string>(
    getItemRowBg(item.isEnabled)
  );

  useEffect(() => {
    setItemBackgroundColor(getItemRowBg(item.isEnabled));
  }, [item.isEnabled]);

  return (
    <DashboardItemContext.Provider value={{ setItemBackgroundColor }}>
      <DashBoardItemRow
        backgroundColor={itemBackgroundColor}
        data-testid="menu-item"
      >
        <ItemEnableDisable item={item} />

        <ItemDetailsRow>
          <UpdateItem
            item={item}
            currency={currency}
            isItemWiseTaxEnabled={isItemWiseTaxEnabled}
          />
          <UploadItemImage src={item.imageUrl} itemId={item.id} />
        </ItemDetailsRow>
        <RemoveItem
          itemId={item.id}
          removeItemFromCategory={removeItemFromCategory}
        />
      </DashBoardItemRow>

      <Divider sx={{ marginTop: "4px", marginBottom: "4px" }} />
    </DashboardItemContext.Provider>
  );
};

export const getItemRowBg = (isEnabled: boolean) => {
  return isEnabled ? ENABLE_ITEM_BG : DISABLE_ITEM_BG;
};

type DashboardItemProps = {
  item: ItemType;
  currency: string;
  removeItemFromCategory: (itemId: number) => void;
  isItemWiseTaxEnabled: boolean;
};
