import { Route, Switch, useRouteMatch } from "react-router-dom";
import { CustomerFacingMenu } from "./menu/CustomerFacingMenu";
import { Dashboard } from "./dashbord/Dashboard";
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { AuthProvider } from "./auth-provider/AuthProvider";
import React from "react";
import { CartProvider } from "./menu/cart-provider/CartProvider";
import { MenuDataProvider } from "./menu-provider/MenuDataProvider";
import { HelmetProvider } from "react-helmet-async";
import { OrderStatus } from "./menu/OrderStatus";

const options = {
  timeout: 5000,
  position: positions.TOP_CENTER,
  containerStyle: {
    zIndex: 99999,
  },
};

export default () => {
  return (
    <AuthProvider>
      <Provider template={AlertTemplate} {...options}>
        <Switch>
          <Route path={"/menu/:menuId"}>
            <CustomerSide />
          </Route>

          <Route>
            <Dashboard />
          </Route>
        </Switch>
      </Provider>
    </AuthProvider>
  );
};

const CustomerSide = () => {
  const { path } = useRouteMatch();

  return (
    <MenuDataProvider>
      <Switch>
        <Route path={`${path}/order/:orderId`}>
          <OrderStatus />
        </Route>

        <Route path={`${path}`}>
          <CartProvider>
            <HelmetProvider>
              <CustomerFacingMenu />
            </HelmetProvider>
          </CartProvider>
        </Route>
      </Switch>
    </MenuDataProvider>
  );
};
