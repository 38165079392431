import { useAuthentication } from "../../../auth-provider/AuthProvider";
import { useFetch } from "../../../client/useFetch";
import { useKdsStations } from "./kdsStationsProvider";

export const useCompleteOrderClient = () => {
  const { token } = useAuthentication();
  const { selectedStationId: stationId } = useKdsStations();

  const { status, trigger } = useFetch(
    () => ({
      init: {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + token,
        },
      },
    }),
    [token],
    { autoTrigger: false, acceptedStatus: 200, parseJson: false }
  );

  return {
    completeOrder: (orderId: number) => {
      trigger({
        input: `/api/kds/station/${stationId}/order/${orderId}/complete/`,
      });
    },
    status,
  };
};
