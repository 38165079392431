import { FilterAlt } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import React, { useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { TransparentButton } from "../../../design-system/Button";
import { VerticalScrolls } from "../../../design-system/Container";
import { PopUpComponent } from "../../../shared-components/PopUpComponent";
import { StationCategoriesType, useKdsStations } from "./kdsStationsProvider";

const FilterCategoryContentStyle = {
  maxWidth: "600px",
  width: "600px",
  height: "370px",
  overflow: "hidden",
  backgroundColor: "#f9f9f9",
  border: "1px solid black",
};

const Header = styled.div`
  font-size: 26px;
  text-align: center;
  margin: 8px auto 16px;
`;

const CheckBoxInput = styled.input`
  height: 14px;
  width: 14px;
`;

const FilterCategoryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 20px;
  box-sizing: border-box;
  margin-top: 4px;
`;

const FilteredCategoryInput = styled.div`
  width: 50%;
  margin-bottom: 8px;
`;

export const KdsFilterCategory = () => {
  const [open, setOpen] = useState<boolean>(false);

  const { stations, selectedStationId, updateStation } = useKdsStations();

  const [stationCategories, setStationCategories] = useState<
    StationCategoriesType | undefined
  >(undefined);

  const selectedStation = useMemo(
    () => stations.find((station) => station.id == selectedStationId),
    [selectedStationId, stations]
  );

  useEffect(() => {
    setStationCategories(
      selectedStation == undefined ? undefined : selectedStation.categories
    );
  }, [selectedStation]);

  const changeIsInThisStation = (
    event: React.ChangeEvent<HTMLInputElement>,
    categoryIndex: number
  ) => {
    if (stationCategories === undefined) return;

    const tempStationCategories = [...stationCategories];
    tempStationCategories[categoryIndex].isInThisStation = event.target.checked;
    setStationCategories(tempStationCategories);
  };

  const updateStationCategories = () => {
    setOpen(false);
    !!stationCategories && updateStation(stationCategories);
  };

  return (
    <>
      <IconButton
        data-testid="test-id-filter-icon"
        onClick={() => setOpen(true)}
        sx={{
          position: "fixed",
          top: 0,
          right: 30,
          display: "flex",
          flexDirection: "column",
          padding: "2px",
          color: "black",
        }}
      >
        <FilterAlt fontSize="medium" sx={{ padding: 0 }} />
        <Typography> Filter </Typography>
      </IconButton>

      <PopUpComponent
        open={open}
        setOpen={setOpen}
        contentStyle={FilterCategoryContentStyle}
      >
        <div data-testid="test-id-filter-category-popup">
          <Header> {selectedStation?.name}</Header>
          <VerticalScrolls height="230px;">
            <FilterCategoryContainer>
              {stationCategories?.map((category, index) => {
                return (
                  <FilteredCategoryInput key={index}>
                    <CheckBoxInput
                      type="checkbox"
                      defaultChecked={category.isInThisStation}
                      data-testid="test-id-stations-category-enable-checkbox"
                      onChange={(event) => changeIsInThisStation(event, index)}
                      id={`filter-checkbox${index}`}
                    />
                    <label
                      htmlFor={`filter-checkbox${index}`}
                      style={{ fontSize: "18px" }}
                    >
                      {category.name}
                    </label>
                  </FilteredCategoryInput>
                );
              })}
            </FilterCategoryContainer>
          </VerticalScrolls>
          <TransparentButton onClick={updateStationCategories}>
            Done
          </TransparentButton>
        </div>
      </PopUpComponent>
    </>
  );
};
