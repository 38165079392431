import { Check } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { usePostClientWithAuth } from "../../../client/postClientWithAuth";
import { Status } from "../../../client/status";
import {
  DisabledHeaderButton,
  HeaderButton,
  HeaderButtonText,
} from "./HeaderButtonDesign";
import { useOrderDataManager } from "./orderDataManager";

export const OrderMarkReady = ({
  orderId,
  isReady,
}: {
  orderId: number;
  isReady: boolean;
}) => {
  const { status, postData: markOrderReady } = usePostClientWithAuth(
    `/api/order/${orderId}/mark-ready/`
  );
  const { reloadOrder } = useOrderDataManager();

  useEffect(() => {
    status === Status.Success && reloadOrder();
  }, [reloadOrder, status]);

  return (
    <Box>
      <>
        {isReady && (
          <DisabledHeaderButton data-testid="test-id-order-ready-status">
            <Check color="primary" />
            <HeaderButtonText>Ready</HeaderButtonText>
          </DisabledHeaderButton>
        )}
        {isReady || (
          <HeaderButton
            data-testid="test-id-mark-order-ready-button"
            onClick={() => markOrderReady(undefined)}
          >
            <Check />
            <HeaderButtonText>Mark Ready</HeaderButtonText>
          </HeaderButton>
        )}
      </>
    </Box>
  );
};
