import { DraggingStyle, NotDraggingStyle } from "react-beautiful-dnd";

export const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? "lightblue" : "transparent",
  width: "100%",
});

export const getListItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined
) => {
  return {
    background: isDragging ? "#ffffffa3" : "rgb(245, 245, 245)",
    ...draggableStyle,
  };
};
