import { IconButton } from "@mui/material";
import React, { useEffect } from "react";
import { useAlert } from "react-alert";
import styled from "styled-components";
import Swal from "sweetalert2";
import { Status } from "../../../../client/status";
import { TrashIcon } from "../../../../design-system/Icons";
import { useRemoveItemClient } from "./client/removeItemClient";
import { ConfirmAlertArgument } from "./ConfirmAlertArgument";

const RemoveButtonWrapper = styled.div`
  position: absolute;
  top: 25px;
  right: 0px;
`;

export const RemoveItem = ({
  itemId,
  removeItemFromCategory,
}: {
  itemId: number;
  removeItemFromCategory: (itemId: number) => void;
}) => {
  const { status, removeItem } = useRemoveItemClient();
  const alert = useAlert();

  const removeItemAction = (id: number) => {
    Swal.fire(ConfirmAlertArgument).then((result) => {
      if (result.isConfirmed) {
        removeItem(id);
      }
    });
  };

  useEffect(() => {
    if (status == Status.Success) {
      alert.success("Item removed");
      removeItemFromCategory(itemId);
      return;
    }

    status == Status.Error && alert.error("Request failed");
  }, [alert, itemId, removeItemFromCategory, status]);

  return (
    <RemoveButtonWrapper>
      <IconButton
        size="small"
        onClick={() => removeItemAction(itemId)}
        data-testid="test-id-trash-button"
      >
        <TrashIcon />
      </IconButton>
    </RemoveButtonWrapper>
  );
};
