import { useParams } from "react-router-dom";
import { HelpRequests } from "../help-request/HelpRequests";
import { Orders } from "./Orders";
import { useMenuData } from "../../../menu-provider/MenuDataProvider";
import { FeatureDisabled } from "../FeatureDisabled";
import styled from "styled-components";
import { Box, Paper, Skeleton } from "@mui/material";
import { DashboardOrderColumn, OrderContainer } from "../../OrderDesign";
import {
  HelpRequestContent,
  HelpRequestRow,
} from "../help-request/HelpRequestDesigns";
import _ from "lodash";
import React from "react";

export const MainSection = styled.div`
  width: 100%;
  background-color: rgba(206, 217, 216, 1);
`;

function HelpRequestSkeleton() {
  return (
    <HelpRequestRow>
      {_.times(5, (index) => {
        return (
          <React.Fragment key={index}>
            <HelpRequestContent>
              <Skeleton
                variant="rectangular"
                height={48}
                width={"100%"}
                animation="wave"
              />
            </HelpRequestContent>
          </React.Fragment>
        );
      })}
    </HelpRequestRow>
  );
}

function OrderSkeleton() {
  return (
    <OrderContainer height="70vh;">
      {_.times(5, (index) => {
        return (
          <Paper
            key={index}
            sx={{
              marginRight: 3,
            }}
            elevation={5}
          >
            <DashboardOrderColumn>
              <Skeleton
                variant="rectangular"
                height={"100%"}
                animation="wave"
              />
            </DashboardOrderColumn>
          </Paper>
        );
      })}
    </OrderContainer>
  );
}

export const DashboardOrders = () => {
  const { menuId } = useParams<{ menuId: string }>();

  const { isRequestForHelpEnabled, isOrderingEnabled, restaurantName } =
    useMenuData();

  return (
    <MainSection>
      {restaurantName === "" && (
        <>
          <Box data-testid="order-loading-skeleton">
            <OrderSkeleton />
          </Box>
          <Box data-testid="help-request-loading-skeleton">
            <HelpRequestSkeleton />
          </Box>
        </>
      )}

      {restaurantName !== "" && (
        <>
          {isOrderingEnabled ? (
            <Orders />
          ) : (
            <FeatureDisabled featureName="Orders" />
          )}

          {isRequestForHelpEnabled ? (
            <HelpRequests menuId={menuId} />
          ) : (
            <FeatureDisabled featureName="Requests" />
          )}
        </>
      )}
    </MainSection>
  );
};
