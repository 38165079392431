import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faHandPaper,
  faPlus,
  faMinus,
  faTimes,
  faList,
  faReceipt,
  faTrashAlt,
  faMinusCircle,
  faPlusCircle,
  faCheckCircle,
  faCheck,
  faUpload,
  faSpinner,
  faBars,
  faCaretSquareLeft,
  faInfoCircle,
  faPenToSquare,
  faAngleLeft,
  faGripVertical,
  faFilter,
  faFireBurner,
  faPen,
  faBoxesStacked,
  faClipboardList,
  faCashRegister,
} from "@fortawesome/free-solid-svg-icons";

library.add(faHandPaper);
library.add(faPlus);
library.add(faMinus);
library.add(faTimes);
library.add(faList);
library.add(faReceipt);
library.add(faTrashAlt);
library.add(faMinusCircle);
library.add(faCheckCircle);
library.add(faBars);
library.add(faCaretSquareLeft);
library.add(faInfoCircle);
library.add(faFilter);

export const HandPaperIcon = () => (
  <FontAwesomeIcon icon={["fas", "hand-paper"]} size="1x" />
);

export const HandPaperIconBlack = () => (
  <FontAwesomeIcon icon={["fas", "hand-paper"]} size="1x" />
);

export const PlusIcon = () => (
  <FontAwesomeIcon icon={["fas", "plus"]} size="1x" />
);

export const CirclePlusIcon = () => (
  <FontAwesomeIcon
    icon={faPlusCircle}
    size="1x"
    style={{
      backgroundColor: "white",
      border: "1px solid white",
      borderRadius: "50%",
    }}
  />
);

export const MinusIcon = () => (
  <FontAwesomeIcon icon={["fas", "minus"]} size="1x" />
);

export const UploadIcon = () => <FontAwesomeIcon icon={faUpload} size="1x" />;

export const TimesIcon = () => (
  <FontAwesomeIcon icon={["fas", "times"]} size="2x" />
);

export const ReceiptIcon = () => (
  <FontAwesomeIcon icon={["fas", "receipt"]} size="1x" />
);

export const TrashIcon = () => (
  <FontAwesomeIcon
    icon={["fas", "trash-alt"]}
    color="rgba(192, 87, 87, 1)"
    size="1x"
  />
);

export const SmallCheckCircle = () => (
  <FontAwesomeIcon
    icon={["fas", "check-circle"]}
    color="rgb(0,128,0)"
    size="1x"
    filter="drop-shadow(0px 4px 4px rgba(0,0,0,0.25))"
  />
);

export const SpinnerIcon = () => (
  <FontAwesomeIcon icon={faSpinner} size="lg" className="fa-spin" />
);

export const BarsIcon = () => (
  <FontAwesomeIcon
    icon={["fas", "bars"]}
    size="2x"
    color="rgba(19, 63, 103, 1)"
  />
);

export const NavigationCloseIcon = () => (
  <>
    <FontAwesomeIcon
      icon={faAngleLeft}
      size="2x"
      color="rgba(19, 63, 103, 1)"
    />
    <FontAwesomeIcon
      icon={["fas", "bars"]}
      size="2x"
      color="rgba(19, 63, 103, 1)"
    />
  </>
);

export const InfoCircle = () => (
  <FontAwesomeIcon
    icon={["fas", "info-circle"]}
    size="1x"
    style={{ marginRight: "4px" }}
  />
);

export const EditIcon = () => (
  <FontAwesomeIcon icon={faPenToSquare} size="1x" color="rgba(86, 86 86, 1)" />
);

export const EditIconPen = () => (
  <FontAwesomeIcon
    icon={faPen}
    size="2x"
    filter="drop-shadow(0px 4px 4px rgba(0,0,0,0.25))"
  />
);

export const GripVerticalIcon = () => (
  <FontAwesomeIcon
    icon={faGripVertical}
    size="1x"
    color="rgba(0, 0, 0, 0.39)
  "
  />
);

export const CheckIcon = () => (
  <FontAwesomeIcon icon={faCheck} size="2x" color="#26D137" />
);

export const FireBurnerIcon = ({ color }: { color?: string }) => (
  <FontAwesomeIcon
    icon={faFireBurner}
    size="2x"
    color={!color ? "black" : color}
  />
);

export const StackedIcon = () => (
  <FontAwesomeIcon icon={faBoxesStacked} style={{ marginRight: "8px" }} />
);

export const ActiveOrdersIcon = () => (
  <FontAwesomeIcon icon={faClipboardList} />
);

export const CashRegister = () => <FontAwesomeIcon icon={faCashRegister} />;
