import React, { useEffect } from "react";
import { useAlert } from "react-alert";
import styled from "styled-components";
import { Status } from "../../../client/status";
import { ConfirmByDoubleClickButton } from "../../ConfirmByDoubleClickButton";
import { useCompleteOrderClient } from "./kdsCompleteOrderClient";
import { KdsOrderHeader } from "./KdsOrderHeader";

const OrderHeaderRow = styled.div`
  width: 100%;
  height: 50px;
  margin: auto;
`;

export const KdsOrderComplete = ({
  orderId,
  orderNumber,
  onCompleteSuccess,
  createdAt,
  tableName,
}: {
  orderId: number;
  orderNumber: number;
  onCompleteSuccess: (orderId: number) => void;
  createdAt: string;
  tableName: string;
}) => {
  const { status, completeOrder } = useCompleteOrderClient();

  const alert = useAlert();

  useEffect(() => {
    if (status == Status.Success) {
      onCompleteSuccess(orderId);
      return;
    }
  }, [alert, onCompleteSuccess, orderId, status]);

  useEffect(() => {
    status == Status.Error && alert.error("Request failed!");
  }, [alert, status]);

  return (
    <ConfirmByDoubleClickButton onConfirmAction={() => completeOrder(orderId)}>
      <OrderHeaderRow data-testid="test-id-kds-order-header">
        <KdsOrderHeader
          tableName={tableName}
          createdAt={createdAt}
          orderId={orderNumber}
        />
      </OrderHeaderRow>
    </ConfirmByDoubleClickButton>
  );
};
