import { Redirect, Route } from "react-router-dom";
import { useAuthentication } from "../auth-provider/AuthProvider";
import { ReactNode } from "react";

export const PrivateRoute = ({ children, path }: PrivateRoutePropType) => {
  const { isLoggedIn } = useAuthentication();

  if (isLoggedIn) {
    return (
      <Route exact path={path}>
        {children}
      </Route>
    );
  }

  return <Redirect to={{ pathname: "/login" }} />;
};

type PrivateRoutePropType = { children: ReactNode; path: string };
