import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { VerticalScrolls } from "../../../design-system/Container";
import { OrderListContainer } from "../../OrderDesign";
import { OrderDetail } from "../../../types/OrderDetail";

const OrderDetailRow = styled(Box)`
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const NoteTextWrapper = styled(Typography)`
  color: rgba(47, 46, 101, 1);
`;

export const OrderDetails = ({
  orderDetails,
}: {
  orderDetails: OrderDetail[];
}) => {
  return (
    <VerticalScrolls height="calc(100% - 100px);">
      <OrderListContainer marginBottom="40px">
        {orderDetails.map((detailedOrder, index) => (
          <React.Fragment key={index}>
            <Box padding={"8px 0px 8px 0px"}>
              <OrderDetailRow>
                <Typography> {detailedOrder.itemName} </Typography>
                <Typography> {`x  ${detailedOrder.quantity}`} </Typography>
              </OrderDetailRow>

              {detailedOrder.note != "" && (
                <NoteTextWrapper
                  fontSize={"13px"}
                >{`Note: ${detailedOrder.note}`}</NoteTextWrapper>
              )}

              {!!detailedOrder.variation && (
                <NoteTextWrapper
                  fontSize={"13px"}
                >{`Variation: ${detailedOrder.variation}`}</NoteTextWrapper>
              )}
            </Box>
            <Divider />
          </React.Fragment>
        ))}
      </OrderListContainer>
    </VerticalScrolls>
  );
};
