import { FileUpload } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import ImageUploading, { ImageListType } from "react-images-uploading";
import styled from "styled-components";
import { Status } from "../../../../client/status";
import { SpinnerIcon } from "../../../../design-system/Icons";
import { useUploadImageClient } from "./client/uploadImageClient";

const UploadImageContainer = styled.div`
  position: relative;
  width: 90px;
  height: 60px;
  background-color: rgba(196, 196, 196, 0.6);
  margin-left: 6px;
  order: 2;
  flex-shrink: 0;
`;

const ItemImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const UploadButtonSX = {
  position: "absolute",
  top: 0,
  right: 0,
  color: "black",
};

const Spinner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #ffffff40;
  justify-content: center;
  align-items: center;
`;

const MAX_IMAGE_SIZE = 26214400;

export const UploadItemImage = ({
  src,
  itemId,
}: {
  src: string | undefined;
  itemId: number;
}) => {
  const [images, setImages] = useState<ImageListType>([]);
  const { uploadItemImage, status } = useUploadImageClient(itemId);
  const alert = useAlert();

  const previewImageSrc = images.length == 0 ? src : images[0].dataURL;

  const onImageSelect = (imageList: ImageListType) => {
    const isImageSizeExceeded =
      imageList[0].file && imageList[0].file?.size > MAX_IMAGE_SIZE;
    if (isImageSizeExceeded) {
      alert.error("Max file size 25mb");
      return;
    }

    setImages(imageList);
    imageList[0].file && uploadItemImage(imageList[0].file);
  };

  useEffect(() => {
    status == Status.Error && alert.error("Upload failed, please try again");
  }, [alert, status]);

  return (
    <UploadImageContainer>
      {previewImageSrc && <ItemImage alt="item image" src={previewImageSrc} />}

      {status == Status.Pending ? (
        <UploadSpinner />
      ) : (
        <ImageUpload images={images} onImageSelect={onImageSelect} />
      )}
    </UploadImageContainer>
  );
};

const UploadSpinner = () => (
  <Spinner data-testid="test-id-upload-spinner">
    <SpinnerIcon />
  </Spinner>
);

const ImageUpload = ({
  images,
  onImageSelect,
}: {
  images: ImageListType;
  onImageSelect: (imageList: ImageListType) => void;
}) => (
  <ImageUploading
    value={images}
    onChange={onImageSelect}
    data-testid="test-id-image-input"
  >
    {({ onImageUpload }) => (
      <>
        <IconButton
          onClick={onImageUpload}
          data-testid="test-id-upload-button"
          sx={UploadButtonSX}
          size="small"
        >
          <FileUpload />
        </IconButton>
      </>
    )}
  </ImageUploading>
);
