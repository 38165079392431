import { Button as MuiButton } from "@mui/material";
import styled from "styled-components";

export const Button = MuiButton;

export const TextOnlyButton = styled.button`
  border: none;
  background-color: transparent;
  font-size: 1.2em;
  color: #293047;
  margin-left: 1.7%;
  cursor: pointer;
`;

export const SquareButton = styled.button`
  margin-left: 5px;
  width: 32px;
  height: 32px;
  background-color: transparent;
  border: none;
  padding: 0 0 0 0;
  cursor: pointer;
`;

export const SlimButton = styled.button<{
  bgColor?: string;
  marginLeft?: string;
}>`
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : "rgba(19, 63, 103, 0.78);"}
  margin-left: ${(props) => props.marginLeft};
  border-radius: 4px;
  color: white;
  width: 160px;
  border: none;
  font-size: 1.3em;
  cursor: pointer;
  padding: 10px;
`;

export const AddButton = styled.button<{
  width: string;
}>`
  display: block;
  width: ${(props) => props.width};
  height: 32px;
  background: #133f67bf;
  margin: 16px auto auto;
  border: none;
  font-size: 16px;
  border-radius: 2px;
  color: #ffffff;
  cursor: pointer;
  &:disabled {
    opacity: 0.6;
  }
`;

export const TransparentButton = styled.button`
  width: 148px;
  height: 38px;
  color: rgba(42, 67, 154, 1);
  background-color: transparent;
  border: none;
  font-size: 20px;
  font-weight: 700;
  display: block;
  margin: 24px auto auto;
  cursor: pointer;
  &:disabled {
    color: rgba(40, 66, 156, 0.5);
  }
`;
