import { StarBorderOutlined } from "@mui/icons-material";
import { Box, Divider, Typography } from "@mui/material";
import { ItemType } from "../../../client/menuClient";
import { StackedIcon } from "../../../design-system/Icons";
import { AddToCartButton } from "../../../menu/AddToCartButton";
import { itemElementStyle } from "../../../menu/menuStyle";
import { useItemInventory } from "./ItemInventoryProvider";

export const ItemWithoutDetails = ({
  item,
  currency,
  onIncreaseItemQuantity,
}: {
  item: ItemType;
  currency: string;
  onIncreaseItemQuantity: (itemId: number) => void;
}) => {
  const { getItemQuantity } = useItemInventory();

  return (
    <>
      <Box
        data-testid="menu-item"
        marginLeft={1}
        marginRight={1}
        marginTop={1}
        marginBottom={1}
        display="flex"
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box>
          <Typography>{item.name}</Typography>
          {!!item.variation && (
            <Box display="flex" alignItems={"center"}>
              <StarBorderOutlined fontSize="small" />
              <Typography
                data-testid="test-id-item-variation-in-dashboard"
                textAlign={"center"}
                alignItems="center"
              >
                {item.variation}
              </Typography>
            </Box>
          )}

          <Box display={"flex"} alignItems="center">
            <Typography sx={itemElementStyle}>
              {currency}
              {parseFloat(item.price.toString()).toFixed(2)}
            </Typography>

            {getItemQuantity(item.id) !== false && (
              <>
                <Divider
                  light={false}
                  orientation="vertical"
                  sx={{
                    height: "12px",
                    marginLeft: "12px",
                    marginRight: "12px",
                  }}
                />
                <Typography
                  data-testid="test-id-available-quantity"
                  sx={itemElementStyle}
                >
                  <StackedIcon />
                  {getItemQuantity(item.id)}
                </Typography>
              </>
            )}
          </Box>
        </Box>

        <Box>
          <AddToCartButton
            onIncreaseItemQuantity={(itemId) => {
              onIncreaseItemQuantity(itemId);
            }}
            itemId={item.id}
          />
        </Box>
      </Box>
      <Divider sx={{ marginLeft: 1, marginRight: 1 }} />
    </>
  );
};
