import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useAuthentication } from "../auth-provider/AuthProvider";
import { Status } from "../client/status";

export const useUploadMenuFileClient = () => {
  const [status, setStatus] = useState<Status>(Status.NotStarted);
  const { token } = useAuthentication();

  const mounted = useRef(true);

  useEffect(() => {
    return function cleanup() {
      mounted.current = false;
    };
  }, []);

  return {
    uploadMenuFile: async (files: File[], phone: string) => {
      const data = new FormData();

      for (const file of files) {
        data.append("file", file);
      }
      data.append("phone", phone);

      setStatus(Status.Pending);

      const response = await fetch(`/api/menu/upload-menu-files/`, {
        method: "post",
        headers: {
          Authorization: "Token " + token,
        },
        body: data,
      });

      if (response.status == 200) {
        mounted.current && setStatus(Status.Success);
        return;
      }

      mounted.current && setStatus(Status.Error);
    },
    status: status,
  };
};
