import { Autocomplete, TextField } from "@mui/material";
import { useMemo, useState } from "react";
import { ItemType } from "../../../client/menuClient";
import { useMenuData } from "../../../menu-provider/MenuDataProvider";

export const AddItemToOrderSearch = ({
  addItemToOrder,
}: {
  addItemToOrder: (itemId: number) => void;
}) => {
  const { categories } = useMenuData();

  const allItems = useMemo(
    () =>
      categories
        .flatMap((category) => category.items)
        .filter((item) => item.isEnabled),
    [categories]
  );
  const [selectedOption, setSelectedOption] = useState<ItemType | null>(null);
  const [inputValue, setInputValue] = useState<string>("");

  return (
    <Autocomplete
      data-testid="test-id-search-item"
      options={allItems}
      getOptionLabel={(option) =>
        !!option.variation
          ? `${option.name} (*${option.variation})`
          : option.name
      }
      renderInput={(params) => (
        <TextField {...params} label="Search item" variant="standard" />
      )}
      disableCloseOnSelect
      onChange={(_, value) => {
        !!value && addItemToOrder(value?.id);
        setSelectedOption(null);
        setInputValue("");
      }}
      sx={{ marginBottom: 2 }}
      size="small"
      value={selectedOption}
      inputValue={inputValue}
      onInputChange={(_, value) => setInputValue(value)}
    />
  );
};
