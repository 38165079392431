import React from "react";
import { OrderType } from "../../../types/OrderType";
import { Box, Divider, styled, Typography } from "@mui/material";
import {
  orderDiscount,
  orderTax,
  orderTotal,
  orderTotalWithTaxAndDiscount,
} from "./orderTotalUtils";

export const ElementBox = styled(Box)({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
});

const ElementTypography = styled(Typography)({
  fontSize: "18px",
  marginTop: 1,
  marginBottom: 1,
});

export const GrandTotalText = styled(Typography)({
  fontSize: "18px",
  fontWeight: "bold",
});

export function OrderSummary({
  currency,
  order,
}: {
  currency: string;
  order: OrderType;
}) {
  const orderServiceCharge =
    (order.serviceCharge / 100) *
    (parseFloat(orderTotal(order)) - parseFloat(orderDiscount(order)));

  return (
    <Box width={"53%"} padding={1} boxSizing="border-box">
      <ElementBox>
        <GrandTotalText>Grand Total:</GrandTotalText>

        <GrandTotalText data-testid="test-id-order-total">
          {currency}
          {orderTotalWithTaxAndDiscount(order)}
        </GrandTotalText>
      </ElementBox>
      <Divider sx={{ borderStyle: "dashed" }} />
      <ElementBox>
        <ElementTypography>Subtotal:</ElementTypography>
        <ElementTypography data-testid="test-id-order-sub-total">
          {currency}
          {orderTotal(order)}
        </ElementTypography>
      </ElementBox>
      <ElementBox>
        <ElementTypography data-testid="test-id-discount-percentage">{`Discount(${order.discount}%)`}</ElementTypography>
        <ElementTypography data-testid="test-id-total-discount">
          {currency}
          {orderDiscount(order)}
        </ElementTypography>
      </ElementBox>

      <ElementBox>
        <ElementTypography>{`Service charge(${order.serviceCharge}%):`}</ElementTypography>
        <ElementTypography data-testid="test-id-service-charge-in-order-summary">
          {`${currency}${orderServiceCharge.toFixed(2)}`}
        </ElementTypography>
      </ElementBox>

      <Divider sx={{ borderStyle: "dashed" }} />

      <ElementBox>
        <ElementTypography>Total before tax:</ElementTypography>
        <ElementTypography data-testid="test-id-total-before-tax">
          {currency}
          {(
            parseFloat(orderTotal(order)) - parseFloat(orderDiscount(order))
          ).toFixed(2)}
        </ElementTypography>
      </ElementBox>

      <ElementBox>
        <ElementTypography>
          {order.isItemWiseTax ? "Tax:" : `Tax(${order.taxPercentage}%):`}
        </ElementTypography>
        <ElementTypography data-testid="test-id-total-tax">
          {currency}
          {orderTax(order)}
        </ElementTypography>
      </ElementBox>
      <Divider sx={{ borderStyle: "dashed" }} />
    </Box>
  );
}
