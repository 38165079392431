import { IconButton } from "@mui/material";
import { useEffect } from "react";
import { useAlert } from "react-alert";
import Swal from "sweetalert2";
import { Status } from "../../../../client/status";
import { TrashIcon } from "../../../../design-system/Icons";
import { useMenuData } from "../../../../menu-provider/MenuDataProvider";
import { useRemoveCategoryClient } from "./client/removeCategoryClient";

const CannotRemoveAlertArgument = {
  title: "Category <u>with items</u> cannot be removed!",
  confirmButtonColor: "#ed6c02",
  confirmButtonText: "CLOSE",
};

const ConfirmAlertArgument = {
  title: "Are you sure to remove the category?",
  showCancelButton: true,
  confirmButtonColor: "rgba(75, 181, 67, 0.72)",
  cancelButtonColor: "rgba(178, 38, 38, 0.72)",
  confirmButtonText: "YES",
  cancelButtonText: "NO",
};

export const RemoveCategory = ({
  numberOfItems,
  categoryId,
}: {
  numberOfItems: number;
  categoryId: number;
}) => {
  const { status, removeCategory: removeCategoryFromMenu } =
    useRemoveCategoryClient();
  const { reloadMenuData } = useMenuData();
  const alert = useAlert();

  const removeCategory = () => {
    if (numberOfItems == 0) {
      Swal.fire(ConfirmAlertArgument).then((result) => {
        if (result.isConfirmed) {
          removeCategoryFromMenu(categoryId);
        }
      });
      return;
    }
    Swal.fire(CannotRemoveAlertArgument);
  };

  useEffect(() => {
    if (status == Status.Success) {
      alert.success("Category removed");
      reloadMenuData();
      return;
    }

    status == Status.Error && alert.error("Request failed");
  }, [alert, reloadMenuData, status]);

  return (
    <IconButton
      onClick={removeCategory}
      data-testid="test-id-minus-button"
      size="small"
      sx={{ width: "40px" }}
    >
      <TrashIcon />
    </IconButton>
  );
};
