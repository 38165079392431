import { OrderType } from "../../../types/OrderType";
import { ConfirmEdit } from "./ConfirmEdit";
import { useCallback, useEffect, useState } from "react";
import { QuickOrder } from "./QuickOrder";
import { Dialog } from "@mui/material";
import { Payments } from "@mui/icons-material";

export const QuickOrderButton = ({
  isConfirmDisable,
  onClose,
  order,
  isNewOrder,
  onCompleteOrder,
}: {
  isConfirmDisable: boolean;
  onClose: (anOrder: OrderType) => void;
  order: OrderType;
  isNewOrder?: boolean;
  onCompleteOrder: () => void;
}) => {
  const [isCompleteShowing, setIsCompleteShowing] = useState(false);
  const [completingOrder, setCompletingOrder] = useState<OrderType>();

  useEffect(() => {
    if (completingOrder === undefined) return;
    setIsCompleteShowing(true);
  }, [completingOrder]);

  const closeQuickOrderPopup = useCallback(
    (currentOrder: OrderType) => {
      setIsCompleteShowing(false);
      onClose(currentOrder);
    },
    [onClose]
  );

  const completeAndCloseQuickOrderPopup = useCallback(() => {
    setIsCompleteShowing(false);
    onCompleteOrder();
  }, [onCompleteOrder]);

  return (
    <>
      <ConfirmEdit
        isConfirmDisable={isConfirmDisable}
        order={order}
        onUpdateSuccess={(theUpdatedOrder) => {
          setCompletingOrder(theUpdatedOrder);
        }}
        buttonText={!!isNewOrder ? "CREATE & PAY" : "UPDATE & PAY"}
        startIcon={<Payments />}
        isNewOrder={isNewOrder}
      />

      <Dialog open={isCompleteShowing} fullWidth maxWidth="md">
        {completingOrder !== undefined && (
          <QuickOrder
            order={completingOrder}
            onClose={closeQuickOrderPopup}
            onCompleteOrder={completeAndCloseQuickOrderPopup}
          />
        )}
      </Dialog>
    </>
  );
};
