import { SaveRounded } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import styled from "styled-components";
import { ItemType } from "../../../../client/menuClient";
import { Status } from "../../../../client/status";
import { EditIcon } from "../../../../design-system/Icons";
import { PriceInput } from "../../../PriceInput";
import { useUpdateItemClient } from "./client/updateItemClient";

const inputStyles = `
  display: block;
  width: 100%;
  background-color: transparent;
  border: none;
  padding-left: 8px;

  &:enabled {
    background-color: transparent;
    outline: 1px solid #9ecaed;
  }
  &:focus {
    outline: 1px solid #9ecaed;
  }
`;

const DescriptionInput = styled.input`
  ${inputStyles}
  order: 4;
  height: 24px;
  margin-top: 12px;
`;

const ItemNameInput = styled.input`
  ${inputStyles}
  box-sizing:border-box;
  font-size: 18px;
  order: 1;
  padding-top: 4px;
  padding-bottom: 4px;
`;

const priceInputStyle = {
  width: "calc(50% - 25px)",
  fontSize: "1em",
  backgroundColor: "transparent",
  outline: "none",
  border: "none",
  paddingTop: "2px",
  paddingBottom: "2px",
  marginRight: "8px",
};

const UpdateButtonWrapper = styled.div`
  position: absolute;
  right: 0px;
  top: 50%;
`;

const Container = styled.div`
  width: calc(100% - 102px);
`;

const SHOW_OUTLINE = "1px solid #9ecaed;";
const NO_OUTLINE = "none";

const ItemPriceContainer = styled("div")<{ outline: string }>`
  order: 3;
  flex-shrink: 0;
  width: 100%;
  margin-top: 12px;
  background-color: transparent;
  font-size: 13px;
  padding-left: 8px;
  box-sizing: border-box;
  outline: ${(props) => props.outline};
`;

export const UpdateItem = ({
  item,
  currency,
  isItemWiseTaxEnabled,
}: {
  item: ItemType;
  currency: string;
  isItemWiseTaxEnabled: boolean;
}) => {
  const alert = useAlert();

  const [isItemInputsDisabled, setIsItemInputsDisabled] =
    useState<boolean>(true);

  const [descriptionValue, setDescriptionValue] = useState<string>(
    item.description ? item.description : ""
  );

  const [itemName, setItemName] = useState<string>(item.name);
  const [itemPrice, setItemPrice] = useState<string>(item.price.toString());

  const [itemTax, setItemTax] = useState<string>(
    item.tax === undefined ? "0" : item.tax.toString()
  );

  const { status, updateItem } = useUpdateItemClient(item.id);

  const updateItemDetails = () => {
    updateItem({
      name: itemName,
      price: parseFloat(itemPrice),
      description: descriptionValue,
      tax: parseFloat(itemTax),
    });
  };

  useEffect(() => {
    if (status == Status.Success) {
      setIsItemInputsDisabled(true);
    }

    status == Status.Error && alert.error("Request failed. Please try again.");
  }, [alert, status]);

  return (
    <>
      <Container>
        <ItemNameInput
          type="text"
          placeholder="add item name"
          disabled={isItemInputsDisabled}
          onChange={(event) => setItemName(event.target.value)}
          value={itemName}
        />

        <ItemPriceContainer
          outline={isItemInputsDisabled ? NO_OUTLINE : SHOW_OUTLINE}
        >
          <span>{currency}</span>

          <PriceInput
            disabled={isItemInputsDisabled}
            onChange={setItemPrice}
            value={itemPrice}
            placeholder="add item price"
            style={priceInputStyle}
          />

          {isItemWiseTaxEnabled && (
            <>
              <span>%</span>
              <PriceInput
                disabled={isItemInputsDisabled}
                onChange={setItemTax}
                value={itemTax}
                placeholder="add item tax"
                style={priceInputStyle}
              />
            </>
          )}
        </ItemPriceContainer>
      </Container>

      <DescriptionInput
        type="text"
        placeholder="Add Description"
        value={descriptionValue}
        onChange={(event) => setDescriptionValue(event.target.value)}
        disabled={isItemInputsDisabled}
      />

      <UpdateButtonWrapper>
        {isItemInputsDisabled && (
          <IconButton
            onClick={() => setIsItemInputsDisabled(false)}
            data-testid="test-id-edit-item"
            size="small"
          >
            <EditIcon />
          </IconButton>
        )}
        {!isItemInputsDisabled && (
          <IconButton
            onClick={updateItemDetails}
            data-testid="test-id-save-description"
            size="small"
          >
            <SaveRounded color="primary" />
          </IconButton>
        )}
      </UpdateButtonWrapper>
    </>
  );
};
