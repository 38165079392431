import React, { useEffect, useState } from "react";
import { OrderType } from "../../../types/OrderType";
import { IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { EditOrderPopup } from "./EditOrderPopup";
import { useOrderEditManager } from "./orderEditManager";
import { useItemInventory } from "./ItemInventoryProvider";

function EditButton(props: { onClick: () => void }) {
  return (
    <IconButton
      data-testid="test-id-order-edit-button"
      onClick={props.onClick}
      size="large"
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.9)",
        border: "#999 1px solid",
      }}
    >
      <Edit />
    </IconButton>
  );
}

type EditOrderProps = {
  order: OrderType;
};

export const EditOrder = ({ order }: EditOrderProps) => {
  const [open, setOpen] = useState(false);
  const {
    order: editingOrder,
    resetOrder,
    setOrder,
    increaseItemQuantity,
    decreaseItemQuantity,
    updateNote,
  } = useOrderEditManager(order);
  const { resetItemsQuantity } = useItemInventory();

  useEffect(() => {
    resetOrder();
  }, [resetOrder]);

  return (
    <>
      <EditButton onClick={() => setOpen(true)} />

      <EditOrderPopup
        order={editingOrder}
        open={open}
        onCompleteUpdate={() => {
          setOpen(false);
        }}
        onCompleteOrder={() => {}}
        onCancelEdit={() => {
          resetItemsQuantity();
          resetOrder();
          setOpen(false);
        }}
        onCancelOrder={() => {}}
        onCloseCompleteOrderDialog={(latestOrder) => setOrder(latestOrder)}
        setOrder={setOrder}
        increaseItemQuantity={increaseItemQuantity}
        decreaseItemQuantity={decreaseItemQuantity}
        updateNote={updateNote}
      />
    </>
  );
};
