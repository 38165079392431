import { useEffect, useState } from "react";
import { useFetch } from "./useFetch";
import { useAuthentication } from "../auth-provider/AuthProvider";

export const useMenuClient = (menuId: string, authenticated?: boolean) => {
  const [menu, setMenu] = useState<ViewMenuType | undefined>(undefined);
  const { token } = useAuthentication();

  const { response, trigger } = useFetch<ViewMenuType>(
    () => {
      const authHeader: Record<string, string> = authenticated
        ? { Authorization: "Token " + token }
        : {};

      const headers: Record<string, string> = {
        "Content-Type": "application/json",
        ...authHeader,
      };

      return {
        input: `/api/menu/${menuId}`,
        init: { headers },
      };
    },
    [menuId],
    {
      acceptedStatus: 200,
    }
  );

  useEffect(() => {
    setMenu(response);
  }, [response]);

  return { menu: menu, reloadMenu: trigger };
};

export type ItemType = {
  id: number;
  price: number;
  name: string;
  description?: string;
  imageUrl?: string;
  isEnabled: boolean;
  availableQuantity?: number;
  tax?: number;
  variation?: string;
  isInventoryEnabled?: boolean;
};

export type CategoryType = {
  id: number;
  name: string;
  description: string;
  items: ItemType[];
};

export type ViewMenuType = {
  restaurantName: string;
  currency: string;
  phone: string;
  address?: string;
  isRequestForHelpEnabled: boolean;
  isOrderingEnabled: boolean;
  taxPercentage: number;
  categories: CategoryType[];
  deviceId?: string;
  isDeliveryEnabled: boolean;
  isPickupEnabled: boolean;
  bkashLink?: string;
  deliveryCharge: number;
  deliveryNote: string;
  isItemWiseTaxEnabled: boolean;
  bin?: string;
  receiptNote?: string;
  taxLabel: string;
  note?: string;
  isAllTextBold?: boolean;
  thanksNote?: string;
  themeColor: string;
  logo?: string;
  coverImage?: string;
  externalLink?: { label: string; link: string };
};
