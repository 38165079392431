import React from "react";
import styled from "styled-components";
import { ContactUsHyperlink } from "./ContactUsHyperlink";
import { Title } from "./DashboardDesign";

const FeatureDisabledText = styled.div`
  display: block;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
  background-color: rgba(251, 0, 0, 0.27);
  width: 100%;
  padding: 12px;
  font-family: Raleway;
  overflow-wrap: break-word;
  hyphens: auto;
  white-space: normal;
  font-size: 2em;
  text-align: center;
`;

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 36px;
  width: 500px;
`;

export const FeatureDisabled = ({ featureName }: { featureName: string }) => {
  return (
    <Container>
      <Title>{featureName}</Title>

      <FeatureDisabledText>
        This features is not enabled.
        <br />
        <ContactUsHyperlink linkText="Contact us to enable the features" />
      </FeatureDisabledText>
    </Container>
  );
};
