import { useState } from "react";
import { useMenuIdFromURL } from "../hooks/menuIdFromURL";
import { useTableNameFromURL } from "./tableNameFromURL";
import { Status } from "../client/status";

export const useRequestForHelpClient = () => {
  const tableName = useTableNameFromURL();
  const menuId = useMenuIdFromURL();

  const [status, setStatus] = useState<Status | RequestForHelpStatus>(
    Status.NotStarted
  );

  return {
    sendRequest: async () => {
      setStatus(Status.Pending);
      const response = await fetch(`/api/menu/${menuId}/help-request/`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ tableName: tableName }),
      });

      if (response.status == 201) {
        setStatus(Status.Success);
        return;
      }
      if (response.status == 409) {
        setStatus(RequestForHelpStatus.Conflict);
        return;
      }
      setStatus(Status.Error);
    },
    status: status,
  };
};

export enum RequestForHelpStatus {
  Conflict = "conflict",
}
