import { useEffect, useRef, useState } from "react";
import { useCombinedCartAndMenuData } from "./combinedCartAndMenuData";
import { useMenuIdFromURL } from "../hooks/menuIdFromURL";
import { useTableNameFromURL } from "./tableNameFromURL";
import { Status } from "../client/status";
import { OrderType } from "../types/OrderType";

export const useSendOrderClient = () => {
  const [order, setOrder] = useState<OrderType | undefined>();
  const tableName = useTableNameFromURL();
  const menuId = useMenuIdFromURL();
  const { cartItemList } = useCombinedCartAndMenuData();

  const [status, setStatus] = useState<Status>(Status.NotStarted);
  const mounted = useRef(true);

  useEffect(() => {
    return function cleanup() {
      mounted.current = false;
    };
  }, []);

  return {
    sendOrderRequest: async (
      customerName: string,
      customerPhone: string,
      address: string
    ) => {
      setStatus(Status.Pending);
      const response = await fetch(`/api/menu/${menuId}/order/`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tableName: tableName,
          orderDetails: cartItemList.map((value) => ({
            itemId: value.item.id,
            quantity: value.quantity,
            note: value.note,
          })),
          customerName: customerName,
          customerPhone: customerPhone,
          createdBy: "customer",
          customerAddress: address,
        }),
      });

      if (response.status == 201) {
        const responseOrder = await response.json();

        mounted.current && setStatus(Status.Success);
        mounted.current && setOrder(responseOrder);
        return;
      }
      mounted.current && setStatus(Status.Error);
    },
    status: status,
    order: order,
  };
};
