import { useEffect, useState } from "react";
import { useDataPollingClient } from "../../../client/dataPollingClient";
import { useMenuIdFromURL } from "../../../hooks/menuIdFromURL";
import { useKdsStations } from "./kdsStationsProvider";
import { KDSOrderType } from "./Kds";
import { Status } from "../../../client/status";

export const useOrdersFromKds = () => {
  const menuId = useMenuIdFromURL();
  const { selectedStationId, updateStatus } = useKdsStations();

  const { data: orderData, reloadData } = useDataPollingClient<KDSOrderType[]>(
    `/api/menu/${menuId}/kds/station/${selectedStationId}/orders/`
  );
  const [updatedOrders, setUpdatedOrders] = useState<KDSOrderType[]>([]);

  useEffect(() => {
    if (orderData == undefined) return;
    setUpdatedOrders(orderData);
  }, [orderData]);

  useEffect(() => {
    if (updateStatus == Status.Success) {
      reloadData();
    }
  }, [reloadData, updateStatus]);

  return {
    orderData: updatedOrders,
    setOrderData: setUpdatedOrders,
    reloadOrders: reloadData,
  };
};
