import { useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import { useAuthentication } from "./auth-provider/AuthProvider";

export const SignUpSuccess = () => {
  const { setToken } = useAuthentication();
  const { token } = useParams<{ token: string }>();

  useEffect(() => {
    setToken(token);
  }, [setToken, token]);

  return <Redirect to={{ pathname: "/dashboard/menu" }} />;
};
