import { Container, Typography } from "@mui/material";
import { useMenuData } from "../../../menu-provider/MenuDataProvider";
import { OrderDetailsTable } from "./OrderDetailsTable";
import { OrderType } from "../../../types/OrderType";

export const Receipt = ({ order }: { order: OrderType }) => {
  const { restaurantName, currency } = useMenuData();
  return (
    <Container maxWidth="md" sx={{ paddingTop: "8px" }}>
      <Typography variant="h5" textAlign="center" mb={1}>
        {restaurantName}
      </Typography>
      <OrderDetailsTable order={order} currency={currency} />
    </Container>
  );
};
