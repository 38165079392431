import { ReactNode, useEffect, useMemo, useState } from "react";
import Select, { CSSObjectWithLabel } from "react-select";
import { useKdsStations } from "./kdsStationsProvider";
import styled from "styled-components";
import { DeleteKdsStation } from "./DeleteKdsStation";

const StationSelectWrapper = styled.div`
  position: fixed;
  top: 2px;
  left: 300px;
  width: 220px;
`;

const SELECTED_OPTION_BG = "#2684FF";
const FOCUSED_OPTION_BG = "#DEEBFF";
const SELECTED_OPTION_COLOR = "hsl(0, 0%, 100%)";

const getOptionStyle = (isFocused: boolean, isSelected: boolean) => {
  return {
    cursor: "pointer",
    width: "100%",
    fontSize: "inherit",
    WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
    paddingTop: "8px",
    paddingBottom: "8px",
    paddingRight: "4px",
    paddingLeft: "12px",
    boxSizing: "border-box" as const,
    borderRadius: "4px",
    backgroundColor: isSelected
      ? SELECTED_OPTION_BG
      : isFocused
      ? FOCUSED_OPTION_BG
      : "transparent",

    color: isSelected ? SELECTED_OPTION_COLOR : "inherit",
  };
};

const selectStyles = {
  control: (provided: CSSObjectWithLabel) => ({
    ...provided,
    background: "rgba(226, 226, 226, 1)",
    border: "none",
    boxShadow: "none",
    height: 35,
    minHeight: 35,
    marginLeft: "0px",
    marginRight: "0px",
    fontFamily: "Roboto",
    fontSize: "16px",
    cursor: "pointer",
  }),
};

const SelectOptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ADD_KDS_STATION_SELECT_OPTION = -1;

const KdsStationOption = ({
  innerProps,
  children,
  data,
  isFocused,
  isSelected,
}: {
  innerProps: JSX.IntrinsicElements["div"];
  children: ReactNode;
  data: SelectInputType;
  isFocused: boolean;
  isSelected: boolean;
}) => {
  return (
    <SelectOptionContainer>
      <div style={getOptionStyle(isFocused, isSelected)} {...innerProps}>
        {children}
      </div>

      {data.value === ADD_KDS_STATION_SELECT_OPTION || (
        <DeleteKdsStation stationId={data.value} />
      )}
    </SelectOptionContainer>
  );
};

export const KdsSelect = ({
  onChange,
}: {
  onChange: (value: SelectInputType | null) => void;
}) => {
  const { stations, setSelectedStationId, selectedStationId } =
    useKdsStations();

  const [stationOptions, setStationOptions] = useState<
    { value: number; label: string }[]
  >([]);

  const getSelectedStation = useMemo(() => {
    const station = stationOptions.find(
      (value) => value.value === selectedStationId
    );
    return station === undefined ? null : station;
  }, [selectedStationId, stationOptions]);

  const handleStationChange = (selectedOption: SelectInputType | null) => {
    if (
      selectedOption == null ||
      selectedOption.value == ADD_KDS_STATION_SELECT_OPTION
    ) {
      setSelectedStationId(null);
    } else {
      setSelectedStationId(selectedOption.value);
    }

    onChange(selectedOption);
  };

  useEffect(() => {
    const tempStationOptions = stations.map((value) => ({
      value: value.id,
      label: value.name,
    }));

    tempStationOptions.push({
      value: ADD_KDS_STATION_SELECT_OPTION,
      label: "+ Add a station",
    });

    setStationOptions(tempStationOptions);
  }, [stations]);

  return (
    <StationSelectWrapper data-testid="test-id-station-select">
      <Select
        options={stationOptions}
        placeholder={"Select station"}
        value={getSelectedStation}
        styles={selectStyles}
        onChange={handleStationChange}
        components={{ Option: KdsStationOption }}
        isSearchable={false}
      />
    </StationSelectWrapper>
  );
};

export type SelectInputType = {
  value: number;
  label: string;
};
