import { useAuthentication } from "../../../auth-provider/AuthProvider";
import { useFetch } from "../../../client/useFetch";

export const useSendOrderToKitchenClient = (url: string) => {
  const { token } = useAuthentication();

  const { status, trigger } = useFetch(
    () => ({
      input: url,
      init: {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + token,
        },
      },
    }),
    [token, url],
    { autoTrigger: false, acceptedStatus: 200, parseJson: false }
  );
  return {
    status: status,
    trigger: trigger,
  };
};
