import { useEffect } from "react";
import { useAlert } from "react-alert";
import { useSendOrderToKitchenClient } from "./sendOrderToKitchenClient";
import { Status } from "../../../client/status";
import { FireBurnerIcon } from "../../../design-system/Icons";
import {
  DisabledHeaderButton,
  HeaderButton,
  HeaderButtonText,
} from "./HeaderButtonDesign";
import { useOrderDataManager } from "./orderDataManager";

export const SendOrderToKitchen = ({
  orderId,
  isSentToKitchen,
}: {
  orderId: number;
  isSentToKitchen: boolean;
}) => {
  const { status, trigger: sendOrderToKitchen } = useSendOrderToKitchenClient(
    `/api/order/${orderId}/send-to-kitchen/`
  );
  const { reloadOrder } = useOrderDataManager();
  const alert = useAlert();

  useEffect(() => {
    status === Status.Success && reloadOrder();

    status === Status.Error && alert.error("Request Failed");
  }, [alert, reloadOrder, status]);

  return (
    <>
      {isSentToKitchen ? (
        <DisabledHeaderButton data-testid="test-id-in-kitchen-status">
          <FireBurnerIcon color="#f85431" />
          <HeaderButtonText>In Kitchen</HeaderButtonText>
        </DisabledHeaderButton>
      ) : (
        <HeaderButton
          onClick={() => sendOrderToKitchen()}
          data-testid="test-id-send-to-kitchen-button"
        >
          <FireBurnerIcon />
          <HeaderButtonText>To Kitchen</HeaderButtonText>
        </HeaderButton>
      )}
    </>
  );
};
