import { Notifications, NotificationsOff } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import useSound from "use-sound";
import helpRequestNotificationAudio from "../../audio/help-request-notification-sound.mp3";

export function NotificationSoundCheck() {
  const [isUserClickedOnDocument, setIsUserClickedOnDocument] = useState(false);
  const [playAudio] = useSound(helpRequestNotificationAudio);
  const mounted = useRef(true);

  const setDocumentClickedFlag = useCallback(() => {
    mounted.current && setIsUserClickedOnDocument(true);
  }, []);

  useEffect(() => {
    document.body.addEventListener("click", setDocumentClickedFlag);

    return () => {
      mounted.current = false;
      document.body.removeEventListener("click", setDocumentClickedFlag);
    };
  }, [setDocumentClickedFlag]);

  useEffect(() => {
    isUserClickedOnDocument &&
      document.body.removeEventListener("click", setDocumentClickedFlag);
  }, [isUserClickedOnDocument, setDocumentClickedFlag]);

  return (
    <IconButton
      data-testid="test-id-sound-check-button"
      onClick={() => playAudio()}
    >
      {isUserClickedOnDocument ? (
        <Notifications data-testid="test-id-sound-on-icon" color={"primary"} />
      ) : (
        <NotificationsOff
          data-testid="test-id-sound-off-icon"
          color={"error"}
        />
      )}
    </IconButton>
  );
}
