import React from "react";
import { OrderType } from "../../../types/OrderType";

import Dialog from "@mui/material/Dialog";
import { CompleteOrder } from "./CompleteOrder";
import { useOrderDataManager } from "./orderDataManager";
import { HeaderButton, HeaderButtonText } from "./HeaderButtonDesign";
import { DoneAll } from "@mui/icons-material";

export const CompleteOrderButton = ({ order }: { order: OrderType }) => {
  const { reloadOrder } = useOrderDataManager();

  const [open, setOpen] = React.useState(false);

  return (
    <>
      <HeaderButton
        smaller={!order.isSentToKitchen}
        data-testid="test-id-order-resolve-button"
        onClick={() => setOpen(true)}
      >
        <DoneAll />
        <HeaderButtonText>Complete</HeaderButtonText>
      </HeaderButton>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <CompleteOrder
          order={order}
          onGoBack={() => setOpen(false)}
          onCompleteOrder={reloadOrder}
        />
      </Dialog>
    </>
  );
};
