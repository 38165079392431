import { SaveRounded } from "@mui/icons-material";
import { Box, Button, IconButton, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { usePostClientWithAuth } from "../../../../client/postClientWithAuth";
import { Status } from "../../../../client/status";
import { EditIcon } from "../../../../design-system/Icons";
import { isEmptyOrSpacesOnly } from "../../stringUtils";

export const EditCategory = ({
  categoryName,
  categoryId,
  onCategoryNameClick,
}: {
  categoryName: string;
  categoryId: number;
  onCategoryNameClick: () => void;
}) => {
  const [showInputFiled, setShowInputField] = useState(false);
  const [category, setCategory] = useState(categoryName);
  const { postData: updateCategory, status } = usePostClientWithAuth(
    `/api/category/${categoryId}/action/update/`
  );

  useEffect(() => {
    status === Status.Success && setShowInputField(false);
  }, [status]);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      width={"100%"}
      boxSizing={"border-box"}
    >
      <>
        {showInputFiled && (
          <>
            <IconButton
              data-testid="test-id-save-category-button"
              onClick={() => updateCategory({ categoryName: category })}
              disabled={isEmptyOrSpacesOnly(category)}
              color="success"
              size="small"
              sx={{ width: "40px" }}
            >
              <SaveRounded />
            </IconButton>

            <TextField
              variant="standard"
              placeholder="Category"
              value={category}
              onChange={(event) => setCategory(event.target.value)}
              fullWidth
              multiline
              maxRows={5}
              inputProps={{ style: { fontSize: 14 } }}
              sx={{ fontSize: "9px" }}
            />
          </>
        )}

        {showInputFiled || (
          <>
            <IconButton
              data-testid="test-id-edit-category-button"
              onClick={() => setShowInputField(true)}
              size="small"
              sx={{ width: "40px" }}
            >
              <EditIcon />
            </IconButton>

            <Button
              onClick={onCategoryNameClick}
              data-testid="test-id-category-display-btn"
              sx={{
                display: "block",
                textAlign: "left",
                justifyContent: "flex-start",
                textTransform: "none",
                color: "rgba(0, 0, 0, 0.6)",
                fontSize: "1.1em",
              }}
              variant="text"
              fullWidth
            >
              {category}
            </Button>
          </>
        )}
      </>
    </Box>
  );
};
