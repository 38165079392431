import { FormControlLabel, Switch } from "@mui/material";
import { useState } from "react";

const SNOOZE_SETTING_KEY = "help-request-sound-snooze";
const SNOOZE_SETTING_VALUE = "ENABLE";

export const HelpRequestSnoozeSwitch = () => {
  const [isSnooze, setIsSnooze] = useState(
    () => localStorage.getItem(SNOOZE_SETTING_KEY) === SNOOZE_SETTING_VALUE
  );

  const changeSetting = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked === true) {
      localStorage.setItem(SNOOZE_SETTING_KEY, SNOOZE_SETTING_VALUE);
      setIsSnooze(true);
    } else {
      localStorage.removeItem(SNOOZE_SETTING_KEY);
      setIsSnooze(false);
    }
  };

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            data-testid="test-id-help-request-snooze-switch"
            checked={isSnooze}
            onChange={changeSetting}
          />
        }
        label="Ask for host notification sound snooze"
      />
    </>
  );
};
