import { ReactNode, createContext, useContext } from "react";
import { useMenuListGet } from "../../dashbord/menuListClient";

const Context = createContext<MenuListState>({
  isMenuIdInList: () => {
    return true;
  },
});

function containsObject(
  menuId: number,
  list: { id: number; restaurantName: string }[]
) {
  let i;
  for (i = 0; i < list.length; i++) {
    if (list[i].id === menuId) {
      return true;
    }
  }

  return false;
}

export const MenuListProvider = ({ children }: { children: ReactNode }) => {
  const { menuList } = useMenuListGet();
  const isMenuIdInList = (menuId: number) => {
    if (menuList == undefined) {
      return true;
    }
    return containsObject(menuId, menuList);
  };

  return (
    <Context.Provider value={{ isMenuIdInList }}>{children}</Context.Provider>
  );
};

export const useMenuListProvider = () => {
  const { isMenuIdInList } = useContext(Context);
  return {
    isMenuIdInList: isMenuIdInList,
  };
};

export type MenuListState = {
  isMenuIdInList: (menuId: number) => boolean;
};
